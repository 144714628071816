import type { DB } from 'lib/database';
import type { Whoami } from 'models/auth';
import type { Client } from 'models/client';
import type { ClientFilters } from 'models/client/filters';
import type { Segment } from 'models/client/segment';
import type { Contract } from 'models/contract';
import type { Employee, ServerSideOrganizationOption } from 'models/employee';
import type { FilterTemplate } from 'models/filter-template';
import type { OfflineProductCategoryNode } from 'models/offline';
import type { Order, OrderFilters } from 'models/order';
import type { ProductBrand } from 'models/product/brand';
import type { CatalogueProduct } from 'models/product/catalogue-product';
import type { CatalogueService } from 'models/service';
import type { Stock } from 'models/stock';

import { transformClientToOfflineClientModel } from '../../clients/utils/transform';
import { transformEmployeeToOfflineEmployeeModel } from '../../employees/utils/transform';
import { transformOrderToOfflineOrderModel, transformProductToOfflineProductModel } from '../../orders/utils/transform';
import type { SwitchModeServiceRepository } from '../usecase/switch-mode.usecase';

export class DexieSwitchModeRepositoryAdapter implements SwitchModeServiceRepository {
	constructor(private db: DB) {}

	async writeOrdersFiltersData(filters: OrderFilters): Promise<void> {
		const filtersData: OrderFilters = { ...filters, clients: [] };

		await this.db.ordersFiltersData.bulkPut([filtersData]);
	}
	async writeClientsFiltersData(filters: ClientFilters): Promise<void> {
		const filtersData: ClientFilters = { ...filters, clients: [] };

		await this.db.clientsFiltersData.bulkPut([filtersData]);
	}
	async writeOrderFilterTemplates(templates: FilterTemplate[]): Promise<void> {
		await this.db.filterTemplate.bulkPut(templates);
	}
	async writeClientFilterTemplates(templates: FilterTemplate[]): Promise<void> {
		await this.db.filterTemplate.bulkPut(templates);
	}
	async writeServices(services: Record<string, CatalogueService>): Promise<void> {
		await this.db.services.put(services);
	}
	async writeWhoAmI(me: Whoami): Promise<void> {
		await this.db.auth.clear();
		await this.db.auth.put(me);
	}
	async writeCreateClientColloquialData(data: AnyArg): Promise<void> {
		await this.db.createClientColloquialData.put(data);
	}
	async writeSegments(segments: Segment[]): Promise<void> {
		await this.db.segments.bulkPut(segments);
	}
	async writeContracts(contracts: Contract[]): Promise<void> {
		const normalizedContracts = contracts.map((c) => ({
			expiredAt: c.expiredAt,
			id: c.id,
			organization: c.organization,
			title: c.title,
		}));

		await this.db.contracts.bulkPut(normalizedContracts);
	}
	async writeStocks(stocks: Stock[]): Promise<void> {
		await this.db.stocks.bulkPut(stocks);
	}
	async writeProductBrands(brands: ProductBrand[]): Promise<void> {
		await this.db.productBrands.bulkPut(brands);
	}
	async writeProductCategories(categories: OfflineProductCategoryNode[]): Promise<void> {
		await this.db.productCategories.bulkPut(categories);
	}
	async writeOrganizations(organizations: ServerSideOrganizationOption[]): Promise<void> {
		await this.db.organizations.bulkPut(organizations);
	}

	async writeProducts(products: CatalogueProduct[]): Promise<void> {
		const offlineProductModels = products.map(transformProductToOfflineProductModel);

		await this.db.products.bulkPut(offlineProductModels);
	}
	async writeOrders(orders: Order[]): Promise<void> {
		const offlineOrderModels = orders.map(transformOrderToOfflineOrderModel);

		await this.db.orders.bulkPut(offlineOrderModels);
	}

	async writeClients(clients: Client[]): Promise<void> {
		if (Array.isArray(clients)) {
			const offlineClientModels = clients.map(transformClientToOfflineClientModel);

			await this.db.clients.bulkPut(offlineClientModels);
		}
	}

	async writeEmployees(employees: Employee[]): Promise<void> {
		if (Array.isArray(employees)) {
			const employeesModels = employees.map(transformEmployeeToOfflineEmployeeModel);

			await this.db.employees.bulkPut(employeesModels);
		}
	}
	async writeLastOfflineOrderAppNumber(): Promise<void> {
		const numbersCount = await this.db.offlineOrderAppNumber.count();

		if (numbersCount > 0) return;

		await this.db.offlineOrderAppNumber.add({ value: 1, id: '0' });
	}
	async clear(): Promise<void> {
		return await this.db.delete({ disableAutoOpen: false });
	}
}
