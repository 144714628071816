export const FilterSearchParamsKey = {
	Brand: 'brand',
	Category: 'category',
	Search: 'search',
	ReHydrateCat: 're-hydrate',
	ProductId: 'productId',
	InStock: 'inStock',
	Client: 'client',
	Stock: 'stock',
	SortBy: 'sortBy',
	SortDirection: 'sortDirection',
};
