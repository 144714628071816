import clsx from 'clsx';
import SpinnerV2 from 'components/Spinner-v2';
import { useClickOutside } from 'hooks/useClickOutside';
import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as InfoIcon } from 'static/images/info-circle.svg';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';

import styles from './styles.module.css';
import type { NotificationModalProps } from './types';

const NotificationModal: React.FC<NotificationModalProps> = ({
	onClose,
	onSubmit,
	isLoading,
	className,
	icon,
	headerTitle,
	submitButtonText,
	notificationMessage,
	top,
	left,
	right,
	bottom,
	closeWhenOutsideClick = false,
}) => {
	const handleCloseCallback = useCallback(() => (closeWhenOutsideClick ? onClose() : undefined), [closeWhenOutsideClick]);

	const ref = useClickOutside<HTMLDivElement>(handleCloseCallback);

	const handleCloseButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		onClose();
	};

	const style = {
		...(left && { '--notification-popup-position-left': left }),
		...(bottom && { '--notification-popup-position-bottom': bottom }),
		...(right && { '--notification-popup-position-right': right }),
		...(top && { '--notification-popup-position-top': top }),
	} as React.CSSProperties;

	return (
		<>
			{createPortal(
				<div ref={ref} className={clsx(styles.notificationPopup, { [styles.isLoading]: isLoading }, className)} style={style}>
					<div className={styles.wrapper}>
						{!!icon ? icon : <InfoIcon className={styles.icon} />}

						<header className={styles.header}>
							<p>{headerTitle}</p>

							<button onClick={handleCloseButtonClick} className={styles.closeButton} type="button">
								<XCloseIcon />
							</button>
						</header>

						<div className={styles.content}>{notificationMessage}</div>

						<footer className={styles.footer}>
							<button onClick={onSubmit} className={styles.controlButton} type="button">
								{submitButtonText}
							</button>
						</footer>
					</div>

					{isLoading && (
						<div className={styles.spinner}>
							<SpinnerV2 />
						</div>
					)}
				</div>,
				document.querySelector('#modal-root'),
			)}
		</>
	);
};

export default NotificationModal;
