import BaseError, { BaseErrorParams } from '../base.error';

type TypeErrorParams = Pick<BaseErrorParams, 'message' | 'cause'>;

export class TypeError extends BaseError {
	constructor(params: TypeErrorParams) {
		super({
			cause: 'TYPE_ERROR',
			type: 'CODE',
			...params,
		});
	}
}
