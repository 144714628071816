import { AbilityProvider } from 'contexts/AbilityContext';
import PageLayoutSkeleton from 'layouts/PageLayout/PageLayoutSkeleton';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useGetMeQuery } from 'store/reducers/auth/authSliceApi';

const UserPermissionsLoader: React.FC = () => {
	const { isLoading } = useGetMeQuery();

	if (isLoading) {
		return <PageLayoutSkeleton />;
	}

	return (
		<AbilityProvider>
			<Outlet />
		</AbilityProvider>
	);
};

export default UserPermissionsLoader;
