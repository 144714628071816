import Button from 'components/Button';
import Textarea from 'components/Textarea';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import OrderCommentAlertDialogue from 'pages/Order/components/OrderCommentAlertDialogue';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import React, { useState } from 'react';
import { ReactComponent as Email } from 'static/images/email2.svg';
import { ReactComponent as File } from 'static/images/file.svg';
import { ReactComponent as Telegram } from 'static/images/telegram.svg';
// import Trash from 'static/images/trash-bold.svg';
// import { ReactComponent as Truck } from 'static/images/truck.svg';
import { ReactComponent as Viber } from 'static/images/viber.svg';
import { ReactComponent as WhatsApp } from 'static/images/whatsapp.svg';

import Print from '../StructureDrawerComponent/Print';
import styles from './style.module.css';

const ROOT_SUBORDER_INDEX = 0;
const commentAccessKey = `suborders.${ROOT_SUBORDER_INDEX}.data.note` as `suborders.${number}.data.note`;

const OrderDrawerContent: React.FC = () => {
	const { setDirtyValue, getValues } = useOrderOperationMethods();
	const [comment, setComment] = useState(() => getValues(commentAccessKey));
	const commentInterface = useBoolean();

	const commentInterfaceClose = useStopPropagationCallback<HTMLButtonElement>(commentInterface.close);

	// TODO temporally hidden
	// const handleDeleteButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
	// 	event.stopPropagation();
	// 	setIsOpenDrawer(true);
	// };

	const onAddComment = useStopPropagationCallback(() => {
		setDirtyValue(commentAccessKey, comment);
		commentInterface.open();
	});

	return (
		<div>
			<Textarea label={'Додати коментар'} className={styles.textArea} value={comment} setValue={setComment} placeholder="Почніть писати..." />

			{/* //TODO temporally hidden */}
			{/* <div className={styles.typicalWrapper}>
				<p className="text-md-medium">Роздрукувати</p>
				<Print title="Документ на проїзд" icon={<Truck />} checked={false} className={styles.printWrapper} />

				<Print title="Лист видачі" icon={<File />} checked={false} className={styles.printWrapper} />
			</div> */}

			<div className={styles.typicalWrapper}>
				<p className="text-md-medium">Надіслати</p>
				<Print title="Viber" icon={<Viber />} checked={false} className={styles.printWrapper} />
				<Print title="WhatsApp" icon={<WhatsApp />} checked={false} className={styles.printWrapper} />
				<Print title="Telegram" icon={<Telegram />} checked={false} className={styles.printWrapper} />
				<Print title="E-mail" icon={<Email />} checked={false} className={styles.printWrapper} />
			</div>

			{/* //TODO temporally hidden */}
			<Button variant="default" onClick={onAddComment} text="Додати коментар" icon={<File />} className={styles.submitBtn} />

			{commentInterface.isOn && <OrderCommentAlertDialogue onClose={commentInterfaceClose} />}
		</div>
	);
};

export default OrderDrawerContent;
