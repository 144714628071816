import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { numberify } from 'utils/shared';
import { isString } from 'utils/type-guards';

import type { ICreateOrderSliceState, Product } from './types';

const initialState = {
	productBeingPicked: null,
	mode: 'create',
	view: 'popup',
	cart: {},
	comment: '',
} as ICreateOrderSliceState;

const createOrderSlice = createSlice({
	name: 'createOrder',
	initialState,
	reducers: {
		setProductBeingPicked: (
			state,
			action: PayloadAction<{
				product: ICreateOrderSliceState['productBeingPicked'];
				mode: ICreateOrderSliceState['mode'];
				view?: ICreateOrderSliceState['view'];
			}>,
		) => {
			state.productBeingPicked = action.payload.product;
			state.mode = action.payload.mode;
			state.view = action.payload.view ?? 'popup';
		},
		clearProductBeingPicked: (state) => {
			state.productBeingPicked = null;
			state.mode = 'create';
			state.view = 'popup';
		},
		toggleProductInCart: (state, action: PayloadAction<{ product: Product; isSelected: boolean }>) => {
			const { product, isSelected } = action.payload;
			const key = String(product.id);

			if (isSelected) {
				state.cart[key] = product;
			} else {
				delete state.cart[key];
			}
		},
		deleteProductFromCart: (state, action: PayloadAction<{ id: string }>) => {
			const { id } = action.payload;
			const key = String(id);

			delete state.cart[key];
		},
		clearProductCart: (state) => {
			state.cart = {};
		},
		updateProductQuantityInCart: (state, action: PayloadAction<{ id: string; quantity: number | string }>) => {
			const { id, quantity } = action.payload;
			const key = String(id);

			const product = state.cart[key];
			const newProduct = {
				...product,
				pickedAmount: isString(quantity) ? numberify(quantity) : quantity,
			};

			state.cart = { ...state.cart, [key]: newProduct };
		},
		bulkProductInsertionToCart: (state, action: PayloadAction<{ products: Product[] }>) => {
			const { products } = action.payload;

			products.forEach((product) => {
				const { id } = product;
				const key = String(id);

				state.cart[key] = product;
			});
		},
		setComment: (state, action: PayloadAction<string>) => {
			state.comment = action.payload;
		},
		clearComment: (state) => {
			state.comment = '';
		},
	},
});

export default createOrderSlice.reducer;
