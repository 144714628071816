import OrderAlertDialoguesProvider from 'contexts/OrderAlertDialoguesProvider';
import { useAppSelector } from 'hooks/redux';
import { useAuth } from 'hooks/useAuth';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import type { Whoami } from 'models/auth';
import type { CatalogueService } from 'models/service';
import React from 'react';
import { orderQueryKeys } from 'services/queryKeys';
import { ordersSliceApi, useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';

import { createOrderLikeEntity } from './lib/entity-creators';

type OrderLikeEntity = ReturnType<typeof createOrderLikeEntity>;

type AwaitedNewOrderRouteProps = {
	children: (props: { data: [OrderLikeEntity, Record<string, CatalogueService>, Whoami] }) => JSX.Element;
};

interface UseGetServicesQueryReturn {
	data: Record<string, CatalogueService>;
	isFetching: boolean;
}

const orderSelector = ordersSliceApi.endpoints.getOrderById.select(orderQueryKeys.createOrderEntity());

const AwaitedNewOrderRoute: React.FC<AwaitedNewOrderRouteProps> = ({ children }) => {
	const orderRequest = useAppSelector(orderSelector);
	const { data: services, ...serviceReq } = useGetServicesQuery<UseGetServicesQueryReturn>();
	const { user } = useAuth();

	let order: OrderLikeEntity = { ...(orderRequest.data ?? {}) };

	if (!orderRequest.data) {
		order = createOrderLikeEntity({
			client: {},
			manager: user,
			contract: null,
			organization: null,
			services: [],
			products: [],
		});
	}

	if (serviceReq.isFetching) return <PageContentSkeleton />;

	const payload = {
		data: [order, services, user] as [OrderLikeEntity, Record<string, CatalogueService>, Whoami],
	};

	return <OrderAlertDialoguesProvider>{children(payload)}</OrderAlertDialoguesProvider>;
};

export default AwaitedNewOrderRoute;
