import clsx from 'clsx';
import Button from 'components/Button';
import Drawer from 'components/Drawer';
import CancelReservationErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/CancelReservationErrorAlertDialogue';
import ReservationErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/ReservationErrorAlertDialogue';
import SaveErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/SaveErrorAlertDialogue';
import OrderDrawerContent from 'components/OrderPageComponents/OrderDrawerContent';
import Spinner from 'components/Spinner';
import { CanUser } from 'contexts/AbilityContext';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { isAfter, startOfDay } from 'date-fns';
import { useBoolean } from 'hooks/useBoolean';
import { useOrderRouter } from 'hooks/useOrderRouter';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { Order } from 'models/order';
import { CatalogueService } from 'models/service';
import ReadonlyStatusLabel from 'pages/Order/components/ReadonlyStatusLabel';
import { useOrderNotifications } from 'pages/Order/hooks/useOrderNotifications';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import { Can } from 'pages/Order/OrderAbility/provider';
import { OrderControllerState, SuborderTab } from 'pages/Order/OrderController';
import { transformOrderToCreateOrderDTO, transformOrderToUpdateOrderDTO } from 'pages/Order/OrderController/lib/dto';
import { transformOrderToRootOrderTab } from 'pages/Order/OrderController/lib/transform';
import React, { lazy, Suspense } from 'react';
import { ReactComponent as Save } from 'static/images/checkWhite.svg';
import { ReactComponent as File } from 'static/images/file-attachment-02.svg';
import { ReactComponent as Lock } from 'static/images/lock.svg';
import { ReactComponent as Plus } from 'static/images/plusBig.svg';
import { ReactComponent as Truck } from 'static/images/truck-white.svg';
import {
	useCreateOrderMutation,
	useGetServicesQuery,
	useSetOrderFromReserveMutation,
	useSetOrderOnReserveMutation,
	useUpdateOrderMutation,
} from 'store/reducers/orders/ordersSliceApi';

import styles from './style.module.css';
import { IProps } from './types';

const BusinessOffer = lazy(() => import('components/BusinessOffer'));
const Modal = lazy(() => import('components/Modal'));
const ModalHeader = lazy(() => import('components/Modal/ModalHeader'));

const OrderTopBar: React.FC<IProps> = ({ onRequestLogistics, className }) => {
	const { getValues, handleSubmit, setValue, getIsOrderDirty, resetState } = useOrderOperationMethods();

	const [updateOrderAsync, updateOrderRequest] = useUpdateOrderMutation();
	const [createOrderAsync, createOrderRequest] = useCreateOrderMutation();
	const [setOnReserveAsync, onReserveRequest] = useSetOrderOnReserveMutation();
	const [setFromReserveAsync, fromReserveRequest] = useSetOrderFromReserveMutation();
	const { data: services } = useGetServicesQuery<{ data: Record<string, CatalogueService> }>();

	const dialogue = useOrderAlertDialogue();
	const drawerInterface = useBoolean();
	const businessOfferInterface = useBoolean();
	const notify = useOrderNotifications();
	const router = useOrderRouter();

	const drawerInterfaceToggle = useStopPropagationCallback(drawerInterface.toggle);
	const businessOfferInterfaceToggle = useStopPropagationCallback(businessOfferInterface.toggle);

	const rootOrder = getValues(`suborders.${0}.data`);
	const orderId = rootOrder.id;
	const isReservedOrder = rootOrder.isReserved;
	const isOrderReadonly = rootOrder.access === 'readonly';

	const handleMakeBusinessOfferSafe = (can: boolean) => (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();

		if (!can) {
			dialogue.open('businessOfferIsNotAvailable');
		} else {
			businessOfferInterface.toggle();
		}
	};

	const handleOrderSave = handleSubmit(async (values: OrderControllerState) => {
		const order = values?.suborders?.[0]?.data ?? {};
		const isNewOrder = !order.id;
		const modalKey = isNewOrder ? 'createNewOrder' : 'saveMainOrder';
		const hasEmptyRoot = Object.values(order.products).length === 0;

		if (hasEmptyRoot) {
			dialogue.open('emptySave', {
				onSubmit: dialogue.close,
				data: { isSplitting: false },
			});
			return;
		}

		dialogue.open(modalKey, {
			onSubmit: async () => {
				if (isNewOrder) {
					const createOrderDto = transformOrderToCreateOrderDTO(order);
					const response = await createOrderAsync(createOrderDto).unwrap();

					router.toCertainOrder(response.id, { replace: true });
				} else {
					const updateOrderDTO = transformOrderToUpdateOrderDTO(order);
					const response = await updateOrderAsync(updateOrderDTO).unwrap();
					const orderModel = transformOrderToRootOrderTab({ order: response, services });
					const suborders = getValues('suborders');

					const initialValues = [orderModel, ...(suborders.slice(1) ?? [])];

					resetState(initialValues);
				}

				notify.successOrderSave();
			},
		});
	});

	const handleOrderReservation = handleSubmit(async (values: OrderControllerState) => {
		const isReserved = values.suborders?.[0].data.isReserved;

		if (isReserved) {
			dialogue.open('mainOrderCancelReservation', {
				onSubmit: async () => {
					const suborders = values?.suborders ?? [];
					const order = suborders?.[0] ?? {};

					const response = (await setFromReserveAsync({ id: order.data.id }).unwrap()) as { data: Order };
					const orderModel = transformOrderToRootOrderTab({ order: response.data, services });

					const newRootOrder = {
						...order,
						data: {
							...orderModel.data,
							...order.data,
							status: orderModel.data.status,
							isReserved: orderModel.data.isReserved,
						},
					} as SuborderTab;

					setValue('suborders', [newRootOrder, ...(suborders.slice(1) ?? [])]);

					notify.successOrderCancelReservation();
				},
				onCancel: () => dialogue.close(),
			});
		} else {
			dialogue.open('mainOrderReservation', {
				onSubmit: async () => {
					const order = values?.suborders?.[0]?.data ?? {};

					const response = (await setOnReserveAsync({ id: order.id }).unwrap()) as { data: Order };
					const orderModel = transformOrderToRootOrderTab({ order: response.data, services });
					const suborders = getValues('suborders');
					setValue('suborders', [orderModel, ...(suborders.slice(1) ?? [])]);

					notify.successOrderReservation();
				},
				onCancel: () => dialogue.close(),
			});
		}
	});

	return (
		<>
			<div className={clsx(styles.topBarWrapper, className)}>
				<Can passThrough I="click" on={`order.${0}.request-logistic`}>
					{(can) => (
						<Button
							onClick={onRequestLogistics}
							icon={<Truck />}
							variant="rounded"
							background="var(--primary-500)"
							hoverBg="#000"
							className={clsx(styles.rounded, { ['disabled-button']: !can })}
							disabled={!can}
						/>
					)}
				</Can>
				<Can passThrough I="click" on={`order.${0}.save`}>
					{(can) => {
						const isDirty = getIsOrderDirty();

						return (
							<Button
								onClick={(e) => {
									e.stopPropagation();
									handleOrderSave();
								}}
								icon={<Save />}
								variant="rounded"
								background={'#d71b87'}
								hoverBg="#000"
								className={clsx(styles.rounded, { ['disabled-button']: !can })}
								disabled={!can && !isDirty}
							/>
						);
					}}
				</Can>
				<Can passThrough I="click" on={`order.${0}.business-offer`}>
					{(can) => {
						return (
							<Button
								variant="rounded"
								background="var(--primary-500)"
								hoverBg="#000"
								onClick={handleMakeBusinessOfferSafe(can)}
								className={clsx(styles.rounded, { ['disabled-button']: !can })}
								icon={<File />}
								disabled={!can}
							/>
						);
					}}
				</Can>
				<CanUser passThrough do="edit" an="orders.order.reserve">
					{(canUser) => {
						return (
							<Can passThrough I="click" on={`order.${0}.reserve`}>
								{(can) => (
									<Button
										background="var(--error-50)"
										onClick={(e) => {
											e.stopPropagation();
											const today = new Date();
											const isOutdated = isAfter(startOfDay(today), startOfDay(new Date(rootOrder.date)));
											const isDirty = getIsOrderDirty();

											if (!isDirty && isOutdated && !isReservedOrder) {
												return dialogue.open('needUpdateOrderDate', {
													data: rootOrder.date,
												});
											}
											if (isDirty && !isReservedOrder) {
												return dialogue.open('needSaveOrderFirstDate');
											}

											handleOrderReservation();
										}}
										icon={<Lock />}
										variant="rounded"
										className={clsx(styles.reserv, styles.rounded, {
											[styles.active]: isReservedOrder,
											['disabled-button']: !canUser || !can,
										})}
										disabled={!canUser || !can}
									/>
								)}
							</Can>
						);
					}}
				</CanUser>

				<Can passThrough I="click" on={`order.${0}.drawer`}>
					{(can) => (
						<Button
							onClick={drawerInterfaceToggle}
							icon={<Plus />}
							variant="rounded"
							background={'var(--primary-500)'}
							hoverBg="#000"
							className={clsx(styles.btn, { ['disabled-button']: !can })}
							disabled={!can}
						/>
					)}
				</Can>

				{isOrderReadonly && <ReadonlyStatusLabel lockedBy={rootOrder?.lockedBy?.name} />}
			</div>

			<Suspense fallback={<Spinner />}>
				<Modal
					open={businessOfferInterface.isOn}
					onClose={businessOfferInterfaceToggle}
					width="640px"
					maxWidth="unset"
					className={styles.labels}
				>
					<ModalHeader title={'Створити комерційну пропозицію'} onXCloseClick={businessOfferInterfaceToggle} />
					<span className={styles.divider}></span>

					<BusinessOffer orderId={orderId} />
				</Modal>
			</Suspense>

			<Suspense fallback={<Spinner />}>
				{drawerInterface.isOn && (
					<Drawer open={drawerInterface.isOn} title={'Додати'} onClose={drawerInterfaceToggle}>
						<OrderDrawerContent />
					</Drawer>
				)}
			</Suspense>

			{createOrderRequest.isLoading && <Spinner />}
			{updateOrderRequest.isLoading && <Spinner />}
			{onReserveRequest.isLoading && <Spinner />}
			{fromReserveRequest.isLoading && <Spinner />}
			{createOrderRequest.isError && <SaveErrorAlertDialogue onClose={createOrderRequest.reset} message={createOrderRequest.error?.message} />}
			{updateOrderRequest.isError && <SaveErrorAlertDialogue onClose={updateOrderRequest.reset} message={updateOrderRequest.error?.message} />}
			{onReserveRequest.isError && <ReservationErrorAlertDialogue message={onReserveRequest.error.message} onClose={onReserveRequest.reset} />}
			{fromReserveRequest.isError && (
				<CancelReservationErrorAlertDialogue message={fromReserveRequest.error.message} onClose={fromReserveRequest.reset} />
			)}
		</>
	);
};

export default OrderTopBar;
