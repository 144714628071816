import {
	CodeFailureErrorView,
	ForbiddenErrorView,
	InternalServerErrorView,
	NotFoundErrorView,
	ResourceLoadFailureErrorView,
	UnknownErrorView,
} from 'components/ErrorViews';
import { isNumber } from 'utils/type-guards';

import { createErrorFactory, ERROR_TYPE } from './errors';

const errorViewByStatusCodeMap = {
	400: NotFoundErrorView,
	401: ForbiddenErrorView,
	403: ForbiddenErrorView,
	500: InternalServerErrorView,
};

export const getErrorViewByException = (err: ReturnType<typeof createErrorFactory>) => {
	const { exception, code, type } = err;

	if (ERROR_TYPE.Custom === type) {
		if (isNumber(code)) {
			return errorViewByStatusCodeMap[code] || UnknownErrorView;
		}

		if (exception.type === 'RESOURCE') {
			return ResourceLoadFailureErrorView;
		}

		if (exception.type === 'CODE') {
			return CodeFailureErrorView;
		}
		if (exception.type === 'ZOD_ERROR') {
			return CodeFailureErrorView;
		}
	}

	if (isNumber(code)) {
		return errorViewByStatusCodeMap[code] || UnknownErrorView;
	}

	if (ERROR_TYPE.Code === type) {
		return CodeFailureErrorView;
	}
	if (ERROR_TYPE.Resource === type) {
		return ResourceLoadFailureErrorView;
	}
	if (ERROR_TYPE.Unknown === type) {
		return UnknownErrorView;
	}

	return UnknownErrorView;
};
