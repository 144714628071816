import BaseError, { BaseErrorParams } from '../base.error';

type ReferenceErrorParams = Pick<BaseErrorParams, 'message' | 'cause'>;

export class ReferenceError extends BaseError {
	constructor(params: ReferenceErrorParams) {
		super({
			cause: 'REFERENCE_ERROR',
			type: 'CODE',
			...params,
		});
	}
}
