import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import React from 'react';

interface OrderCommentAlertDialogueProps {
	onClose: VoidCallback;
}

const OrderCommentAlertDialogue: React.FC<OrderCommentAlertDialogueProps> = ({ onClose }) => {
	return (
		<AlertDialogue onOutsideClick={onClose}>
			<AlertDialogueHeader onClose={onClose}>Додавання коментаря</AlertDialogueHeader>
			<AlertDialogueContent>
				<AlertDialogueMessage>
					Коментар <b>додано</b> до заявки, але ще не збережено на сервері. Це буде зроблено, коли заявку буде <b>записано</b>/
					<b>створено</b>/<b>зарезервовано</b>.
				</AlertDialogueMessage>
			</AlertDialogueContent>
			<AlertDialogueFooter justify="flex-end">
				<AlertDialogueControlButton variant="submit" onClick={onClose}>
					Зрозуміло
				</AlertDialogueControlButton>
			</AlertDialogueFooter>
		</AlertDialogue>
	);
};

export default OrderCommentAlertDialogue;
