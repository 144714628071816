import clsx from 'clsx';
import React from 'react';

import styles from '../styles.module.css';
import type { BaseErrorViewProps } from '../types';

type UnknownErrorViewProps = BaseErrorViewProps;

const UnknownErrorView: React.FC<UnknownErrorViewProps> = ({ className }) => {
	return <div className={clsx(styles.layout, className)}>UnknownErrorView</div>;
};

export default UnknownErrorView;
