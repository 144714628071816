import { format, isAfter, isBefore, isEqual, isValid, startOfDay } from 'date-fns';

export const sinceDateFormatter = (date: Date | string | number): string => {
	const dateString = new Date(date).toLocaleDateString('uk-UA');
	return `з ${dateString}`;
};

export const formatAndExtractDateTime = (date: Date | string | number) => {
	let dt = new Date(date);

	if (!isValid(dt)) {
		dt = new Date();
	}

	const isUTC = typeof date === 'string' && (date.includes('Z') || date.match(/\+|-?\d{2}:\d{2}/));
	const adjustedDate = isUTC ? new Date(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000) : dt;

	const dateOnly = format(adjustedDate, 'dd.MM.yyyy');
	const timeOnly = format(adjustedDate, 'HH:mm');

	return [dateOnly, timeOnly] as const;
};

export const stripTimeFromDate = (date: Date) => startOfDay(date);

export const isDateWithinRange = (date: Date, range: [Date, Date]) => {
	const [start, end] = range;
	return (isEqual(date, start) || isAfter(date, start)) && (isEqual(date, end) || isBefore(date, end));
};
