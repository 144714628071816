import clsx from 'clsx';
import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import AutocompleteServerInput from 'components/AutocompleteServerInput ';
import { AutocompleteFieldWithOnSelectAction } from 'components/FormComponents/Inputs/inputs';
import { useBoolean } from 'hooks/useBoolean';
import { useOrderRouter } from 'hooks/useOrderRouter';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { AnyOption, Option, OptionSchema } from 'models/common/options';
import { standardizeOption } from 'models/common/preprocess';
import React, { useEffect, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from 'static/images/chevron-right.svg';
import { ReactComponent as DotsIcon } from 'static/images/two-line-vertical-columns.svg';
import { useGetClientsOptionsQuery, useGetContractListQuery } from 'store/reducers/clients/clientsSliceApi';
import { useGetEmployeesOptionListQuery, useGetOrganizationsOptionListQuery, useGetWarehousesQuery } from 'store/reducers/users/usersSliceApi';

import { useOrderNotifications } from '../hooks/useOrderNotifications';
import { useOrderOperationMethods } from '../hooks/useOrderOperationMethods';
import { Can } from '../OrderAbility/provider';
import styles from './styles.module.css';

const fallbackList = [];

interface OrderInternalInfoModuleProps {
	suborderIndex: number;
	className?: string;
}

const useGetClients = (queryArg: string | string[]) => {
	const query = Array.isArray(queryArg) ? queryArg[0] : queryArg || '';
	const { data, isFetching, isLoading } = useGetClientsOptionsQuery(query);

	return {
		data,
		isFetching,
		isLoading,
	};
};

const OrderInternalDataControlModule: React.FC<OrderInternalInfoModuleProps> = ({ className, suborderIndex }) => {
	const redirectToClientPageAlert = useBoolean();
	const { setDirtyValue, setIsDirty, getValues, formState, control } = useOrderOperationMethods();
	const client = useWatch({ name: `suborders.${suborderIndex}.data.client`, control });
	const router = useOrderRouter();
	const notify = useOrderNotifications();
	const { id: orderId } = useParams();

	const { data: stocksOptionList = fallbackList, ...stocksRequest } = useGetWarehousesQuery(undefined);
	const { data: managersOptionList = fallbackList, ...managersRequest } = useGetEmployeesOptionListQuery();
	const { data: organizationsOptionList = fallbackList, ...organizationsRequest } = useGetOrganizationsOptionListQuery();
	const { data: rawContractsOptionList = fallbackList, ...contractsRequest } = useGetContractListQuery(client?.value, { skip: !client?.value });

	const contractsOptionList = useMemo(() => {
		const schema = standardizeOption<typeof OptionSchema, AnyOption>(OptionSchema, ({ id, title }) => ({ label: title, value: id }));

		const options = rawContractsOptionList.map((contract) => schema.safeParse(contract).data) as Option[];

		return options;
	}, [rawContractsOptionList, suborderIndex]);

	useEffect(() => {
		if (!client) {
			setDirtyValue(`suborders.${suborderIndex}.data.contract`, null);
		}
	}, [client]);

	useEffect(() => {
		if (contractsOptionList) {
			const [defaultContract = null] = contractsOptionList;
			const existingContract = getValues(`suborders.${suborderIndex}.data.contract`);

			if (existingContract) return;

			setDirtyValue(`suborders.${suborderIndex}.data.contract`, defaultContract);
		}
	}, [contractsOptionList]);

	const isRedirectForbidden = !client?.value;

	const getFieldError = (fieldName: string) => formState.errors?.suborders?.[suborderIndex]?.data?.[fieldName]?.message;
	const onCloseRedirectToClientPageAlert = useStopPropagationCallback<HTMLButtonElement>(redirectToClientPageAlert.close);
	const onOpenRedirectToClientPageAlert = useStopPropagationCallback<HTMLButtonElement>(redirectToClientPageAlert.open);

	const onClientPageRedirect = () => {
		if (!client?.value) {
			return notify.noClientSelected();
		}

		if (!!orderId) {
			let from: undefined | string;

			if (window.location.pathname.includes('split')) {
				from = String(suborderIndex);
			}

			router.toClient({ orderId, clientId: client.value, from });
		} else {
			router.toClient({ clientId: client.value });
		}
	};

	return (
		<form className={clsx(styles.form, className)} style={{ '--columns': 5 } as React.CSSProperties}>
			<Can passThrough I="change" an={`order.${suborderIndex}.client`}>
				{(can) => (
					<Controller
						name={`suborders.${suborderIndex}.data.client`}
						control={control}
						render={({ field }) => {
							return (
								<div className={styles.clientInput}>
									<AutocompleteServerInput
										name={field.name}
										label="Клієнт"
										ref={field.ref}
										placeholder="Виберіть клієнта"
										initialValue={field.value as Option}
										onSelect={(value) => {
											setIsDirty(true);
											field.onChange(value);
										}}
										useLoadAsyncDataQuery={useGetClients}
										className={clsx({ [styles.error]: !!getFieldError('client') })}
										disabled={!can}
									/>

									<button
										type="button"
										onClick={onOpenRedirectToClientPageAlert}
										className={clsx(styles.redirectToClientButton)}
										disabled={isRedirectForbidden}
									>
										<DotsIcon />
									</button>
								</div>
							);
						}}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.organization`}>
				{(can) => (
					<AutocompleteFieldWithOnSelectAction
						name={`suborders.${suborderIndex}.data.organization`}
						label="Організація"
						placeholder="Виберіть організацію"
						onOptionSelect={() => {
							setIsDirty(true);
						}}
						valuesList={organizationsOptionList}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('organization') })}
						disabled={!can || organizationsRequest.isLoading}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.contract`}>
				{(can) => (
					<AutocompleteFieldWithOnSelectAction
						name={`suborders.${suborderIndex}.data.contract`}
						label="Договір"
						placeholder="Виберіть договір"
						valuesList={contractsOptionList}
						onOptionSelect={() => {
							setIsDirty(true);
						}}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('contract') })}
						disabled={!can || contractsRequest.isLoading || contractsRequest.isFetching}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.stock`}>
				{(can) => (
					<AutocompleteFieldWithOnSelectAction
						name={`suborders.${suborderIndex}.data.stock`}
						label="Склад"
						placeholder="Виберіть склад"
						valuesList={stocksOptionList}
						onOptionSelect={() => {
							setIsDirty(true);
						}}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('stock') })}
						disabled={!can || stocksRequest.isLoading}
					/>
				)}
			</Can>
			<Can passThrough I="change" an={`order.${suborderIndex}.responsible`}>
				{(can) => (
					<AutocompleteFieldWithOnSelectAction
						name={`suborders.${suborderIndex}.data.responsible`}
						label="Менеджер"
						placeholder="Виберіть менеджера"
						valuesList={managersOptionList}
						onOptionSelect={() => {
							setIsDirty(true);
						}}
						fieldClassName={clsx(styles.formField, { [styles.error]: !!getFieldError('responsible') })}
						disabled={!can || managersRequest.isLoading}
					/>
				)}
			</Can>

			{redirectToClientPageAlert.isOn && (
				<AlertDialogue onOutsideClick={onCloseRedirectToClientPageAlert}>
					<AlertDialogueHeader onClose={onCloseRedirectToClientPageAlert}>Перехід на сторінку клієнта</AlertDialogueHeader>
					<AlertDialogueContent>
						<AlertDialogueMessage>Впевнені, що бажаєте переглянути картку клієнта?</AlertDialogueMessage>
						<AlertDialogueMessage>
							Обраний клієнт: <b>{client.label}</b>
						</AlertDialogueMessage>
					</AlertDialogueContent>
					<AlertDialogueFooter>
						<AlertDialogueControlButton variant="cancel" onClick={onCloseRedirectToClientPageAlert}>
							Скасувати
						</AlertDialogueControlButton>
						<AlertDialogueControlButton variant="submit" onClick={onClientPageRedirect}>
							<ChevronRightIcon />
							Перейти
						</AlertDialogueControlButton>
					</AlertDialogueFooter>
				</AlertDialogue>
			)}
		</form>
	);
};

export default OrderInternalDataControlModule;
