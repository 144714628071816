import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APP_MODE } from 'const';

import type { IAppSliceState } from './types';

const initialState = {
	mode: APP_MODE.online,
	isNetworkConnection: true,
} as IAppSliceState;

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setAppMode: (state, action: PayloadAction<Pick<IAppSliceState, 'mode' | 'updatedAt'>>) => {
			state.mode = action.payload.mode;

			if (action.payload.mode === 'offline') {
				state.updatedAt = action.payload?.updatedAt || new Date().toString();
			} else {
				if (state.updatedAt) {
					delete state.updatedAt;
				}
			}
		},
		setNetworkConnection: (state, action: PayloadAction<boolean>) => {
			state.isNetworkConnection = action.payload;
		},
	},
});

export default appSlice.reducer;
export const { setAppMode, setNetworkConnection } = appSlice.actions;
