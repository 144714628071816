import { isAxiosError } from 'axios';
import {
	RangeError as CustomRangeError,
	ReferenceError as CustomReferenceError,
	TypeError as CustomTypeError,
	ZodError as CustomZodError,
} from 'models/error';
import * as CustomApiErrors from 'models/error/api';
import * as CustomCodeErrors from 'models/error/code';
import * as CustomNetworkErrors from 'models/error/network';
import { ZodError as ZodOriginalError } from 'zod';

const isNativeError = (err: unknown): err is Error => {
	return err instanceof Error;
};
export const isNativeNetworkError = (err: unknown) => {
	return isNativeError(err) && err.name === 'NetworkError';
};
export const isAxiosNetworkError = (err: unknown) => {
	return isAxiosError(err) && err.code === 'ERR_NETWORK';
};

export const isAxiosErrorWithMessagesArray = (err: unknown) => {
	return isAxiosError(err) && 'errors' in err.response?.data && Array.isArray(err.response.data.errors);
};
export const isNativeChunkLoadError = (err: unknown) => {
	return isNativeError(err) && /ChunkLoadError/.test(err.name);
};

export const isZodError = (err: unknown): err is CustomZodError => {
	return err instanceof CustomZodError;
};
export const isZodOriginalError = (err: unknown): err is ZodOriginalError => {
	return err instanceof ZodOriginalError;
};

const codeRelatedErrorsPairs = [
	{
		native: TypeError,
		custom: CustomTypeError,
	},
	{
		native: ReferenceError,
		custom: CustomReferenceError,
	},
	{
		native: RangeError,
		custom: CustomRangeError,
	},
];

export const getCodeRelatedError = (err: unknown): CustomTypeError | CustomReferenceError | CustomRangeError => {
	const res = codeRelatedErrorsPairs.find((errorsPair) => {
		return err instanceof errorsPair.native;
	});

	if (!res) return null;

	return res.custom as unknown as CustomTypeError | CustomReferenceError | CustomRangeError;
};

const customApiErrors = Object.values(CustomApiErrors);
const customCodeErrors = Object.values(CustomCodeErrors);
const customNetworkErrors = Object.values(CustomNetworkErrors);

const customErrors = [...customApiErrors, ...customCodeErrors, ...customNetworkErrors] as const;

export const isCustomError = (err: unknown): err is InstanceType<typeof customErrors[number]> => {
	return customErrors.some((ErrorEntity) => err instanceof ErrorEntity);
};

export const isApiError = (err: unknown): err is InstanceType<typeof customApiErrors[number]> => {
	return customApiErrors.some((ErrorEntity) => err instanceof ErrorEntity);
};
