import clsx from 'clsx';
import ClickAwayListener from 'components/ClickAwayListener';
import React from 'react';
import { createPortal } from 'react-dom';

import styles from './styles.module.css';
import type { IProps } from './types';

const Modal: React.FC<IProps> = ({
	open,
	className,
	children,
	onClose,
	minWidth = '300px',
	minHeight = '302px',
	maxHeight = 'auto',
	maxWidth = '314px',
	width = '100%',
	noAwayClick = false,
	stackable = false,
}) => {
	if (!open) return null;

	const closeHandler = noAwayClick ? () => {} : onClose;

	const modalComponent = (
		<div
			className={clsx(styles.modalOverlay, className)}
			onClick={(e) => {
				if (noAwayClick) e.stopPropagation();
			}}
		>
			<ClickAwayListener onClickAway={closeHandler}>
				<div className={styles.modal} style={{ maxWidth, minHeight, maxHeight, width, minWidth }}>
					{children}
				</div>
			</ClickAwayListener>
		</div>
	);

	return stackable ? modalComponent : <>{createPortal(modalComponent, document.querySelector('#portal')!)}</>;
};

export default Modal;
