import Button from 'components/Button';
import MainSearch from 'components/MainSearch';
import { MainSearchSwitchButton } from 'components/MainSearch/components/MainSearchSwitchButton';
import { MainSearchSwitchInput } from 'components/MainSearch/components/MainSearchSwitchInput';
import { VoiceSearchButton } from 'components/MainSearch/components/VoiceSearchButton';
import React from 'react';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';
import { ReactComponent as UsersIcon } from 'static/images/users.svg';

import styles from './style.module.css';
import type { IProps } from './types';

const ClientsTopBar: React.FC<IProps> = ({ onCreateClientButtonClick, onFiltersButtonClick }) => {
	const handleCreateClientButtonClick = () => {
		onCreateClientButtonClick?.();
	};

	const handleOpenFilterButtonClick = () => {
		onFiltersButtonClick?.();
	};

	return (
		<div className={styles.topBarWrapper}>
			<MainSearch>
				<VoiceSearchButton />

				<MainSearchSwitchInput defaultQueryKey="search" placeholder="Почніть писати...">
					<MainSearchSwitchButton queryKey="search" title="Клієнт" />
				</MainSearchSwitchInput>
			</MainSearch>

			<div className={styles.buttonsWrapper}>
				<Button
					text="Створити клієнта"
					onClick={handleCreateClientButtonClick}
					icon={<PlusIcon />}
					variant="rounded"
					background="var(--primary-500)"
				/>
				<Button text="Фільтрувати" onClick={handleOpenFilterButtonClick} icon={<UsersIcon />} variant="rounded" background="var(--pink)" />
			</div>
		</div>
	);
};

export default ClientsTopBar;
