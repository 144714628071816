import Counter from 'components/Counter';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import React from 'react';
import { Controller } from 'react-hook-form';
import { formatInputValue } from 'utils/inputs';
import { roundNumber, toFixed } from 'utils/shared';

import styles from './styles.module.css';
import type { ServiceCounterProps } from './types';

const formatValue = formatInputValue({ isInt: true });

const ServiceCounter: React.FC<ServiceCounterProps> = ({ suborderIndex, service, disabled }) => {
	const entityId = service.id;
	const { control, setDirtyValue, getValues } = useOrderOperationMethods();

	const updateValues = (amount: number) => {
		const allSuborders = getValues('suborders');
		const sumInAllEntityOccurrences = allSuborders.reduce((acc, suborder, index) => {
			if (index === 0) return acc;

			if (index !== suborderIndex) {
				return (acc += Number(suborder.data.services[entityId]?.amount || 0) * Number(suborder.data.services[entityId]?.price || 0));
			}

			acc += Number(amount) * Number(suborder.data.services[entityId]?.price || 0);
			return acc;
		}, 0);

		const amountOfAllEntityOccurrences = allSuborders.slice().reduce((acc, suborder, index) => {
			if (index === 0) return acc;

			if (index !== suborderIndex) {
				return (acc += Number(suborder.data.services[entityId]?.amount || 0));
			}

			acc += Number(amount);
			return acc;
		}, 0);

		const price = Number(getValues(`suborders.${suborderIndex}.data.services.${entityId}.price`));

		// @ts-ignore
		setDirtyValue(`suborders.${suborderIndex}.data.services.${entityId}.sum`, toFixed(roundNumber(price * amount, 2)));
		// @ts-ignore
		setDirtyValue(`suborders.${0}.data.services.${entityId}.sum`, toFixed(roundNumber(sumInAllEntityOccurrences, 2)));
		// @ts-ignore
		setDirtyValue(`suborders.${0}.data.services.${entityId}.amount`, toFixed(amountOfAllEntityOccurrences, { isInt: true }));
	};

	return (
		<Controller
			name={`suborders.${suborderIndex}.data.services.${entityId}.amount`}
			control={control}
			render={({ field }) => {
				const numberedValue = Number(field.value);

				return (
					<Counter
						min={1}
						value={isNaN(numberedValue) ? 1 : numberedValue}
						onQuantityChange={(newValue) => {
							const amount = formatValue(newValue);

							updateValues(Number(amount));
							field.onChange(amount);
						}}
						onBlur={() => {
							if (!numberedValue) {
								updateValues(0);

								field.onChange(0);
							} else {
								field.onChange(formatValue(numberedValue));
							}
						}}
						disabled={disabled}
						className={styles.servicesCounter}
					/>
				);
			}}
		/>
	);
};

export default ServiceCounter;
