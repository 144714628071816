import clsx from 'clsx';
import type { RootButtonProps } from 'components/ColorfulSelect/types';
import { SelectOption } from 'components/Select/types';
import React from 'react';
import { ReactComponent as ChevronDownIcon } from 'static/images/chevron-down.svg';

import styles from './styles.module.css';

type ExtendedRootButtonProps = RootButtonProps<SelectOption> & {
	title: string;
	disabled: boolean;
};

const RootButton: React.FC<ExtendedRootButtonProps> = ({ onClick, title, disabled, selectedOption }) => {
	return (
		<button data-service-select disabled={disabled} type="button" onClick={onClick} className={clsx('text-sm-regular', styles.rootButton)}>
			{selectedOption?.label || title}

			<ChevronDownIcon />
		</button>
	);
};

export default RootButton;
