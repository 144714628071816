import { ClientIdSchema, TypePriceIdSchema } from 'models/common/uuid';
import { z } from 'zod';

export const TypePriceSchema = z.object({
	id: TypePriceIdSchema,
	title: z.string().min(1),
});

export const PriceSchema = z.object({
	typePrice: TypePriceSchema,
	price: z.number(),
	dateBegin: z.coerce.date().nullable(),
});
export const InternalPriceSchema = z.object({
	typePrice: TypePriceSchema,
	price: z.string(),
	dateBegin: z.any().nullable(),
});

// TODOType discuss
const PricePreviewSchema = z.object({
	id: z.string(),
	name: z.string(),
	client: z.object({ id: ClientIdSchema, client: z.string(), name: z.string() }),
	date: z.object({
		date: z.string(),
		time: z.string(),
	}),
	typeOfPrices: z.array(z.string()),
	goods: z.array(z.string()),
});

export type PricePreview = z.infer<typeof PricePreviewSchema>;
export type ProductPrice = z.infer<typeof PriceSchema>;
