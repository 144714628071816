import { CLIENT_PHONE_TYPE_TO_HUMAN } from 'const';
import type { Client, LegalClient } from 'models/client';
import { ClientContract } from 'models/client/client-contract';
import type { BaseClientFormValues, IndividualClientFormValues, LegalClientFormValues } from 'models/IClient';
import { getClientStateName } from 'utils/shared';
import { isObject } from 'utils/type-guards';

export const isLegalClient = (client: Client): client is LegalClient => {
	return isObject<Client>(client) && client?.segment?.type === 'legal';
};

export const hydrateClientFormState = (client: Client): LegalClientFormValues | IndividualClientFormValues => {
	const type = client?.segment?.type ?? 'individual';
	const sortedPhones = Array.from(client?.phones ?? []).sort((a, b) => a.priority.localeCompare(b.priority));

	const baseClient: BaseClientFormValues = {
		stock: { label: client?.manager?.stock?.title ?? '', value: client?.manager?.stock?.id ?? '' },
		department: { label: client?.manager?.department?.title ?? '', value: client?.manager?.department?.id ?? '' },
		// @ts-ignore
		name: client?.organizationName || client?.name || '',
		phones: sortedPhones.map((phone) => ({
			priority: phone.priority,
			number: phone.number,
			type: {
				label: CLIENT_PHONE_TYPE_TO_HUMAN[phone.type] || CLIENT_PHONE_TYPE_TO_HUMAN.mobile,
				value: phone?.type || CLIENT_PHONE_TYPE_TO_HUMAN.mobile,
			},
		})),
		email: client.email,
		address: client.address,
		contract: isObject<ClientContract>(client.contracts)
			? [{ label: client.contracts.title, value: client.contracts.id }]
			: Array.isArray(client.contracts)
			? client.contracts.length > 0
				? client.contracts.map((contract) => ({ label: contract.title, value: contract.id }))
				: null
			: null,
		manager: {
			label: client?.manager?.name ?? '',
			value: client?.manager?.['1c_uuid'] ?? '',
		},
		comment: { plainText: client?.comment ?? '', html: client?.comment ?? '' },
		status: { label: getClientStateName(client?.status ?? 'green'), value: client?.status ?? 'green' },
		segment: { title: client?.segment?.title ?? '', id: client?.segment?.id, type },
		activities: client?.activities?.map((activity) => ({ label: activity.title, value: activity.id })),
	};

	if (isLegalClient(client)) {
		return {
			...baseClient,
			ipnCode: client.ipnCode,
			edrpouCode: client.edrpouCode,
			name: client.name,
			organizationName: client.organizationName,
		} as LegalClientFormValues;
	}

	return {
		...baseClient,
		ipnCode: client.ipnCode,
		passport: client.passport,
	} as IndividualClientFormValues;
};

const pluralRules = new Intl.PluralRules('uk-UA');

const defaultSuffixes = new Map([
	['one', 'день'],
	['few', 'дні'],
	['many', 'днів'],
	['other', 'днів'],
]);

export const formatPlural = (n: number, suffixes?: Map<string, string>) => {
	const rule = pluralRules.select(n);
	const suffix = suffixes ? suffixes.get(rule) : defaultSuffixes.get(rule);
	return `${n} ${suffix}`;
};

export const getOrderPreFilterParamsString = ({ clientId, queryValue }: { clientId: string; queryValue: string }) => {
	return `?client${encodeURIComponent('[]')}=` + clientId + '&payments=' + queryValue;
};
