type UseButtonTitleProps = {
	index: number;
	ordersCount: number;
};

export const useButtonTitle = ({ index, ordersCount }: UseButtonTitleProps) => {
	const buttonTitles = [];

	for (let i = 1; i <= ordersCount; i++) {
		if (index !== i) {
			const title = {
				label: `${i}`,
				index: i,
			};
			buttonTitles.push(title);
		}
	}

	return buttonTitles;
};
