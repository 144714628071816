import UserPermissionsLoader from 'components/UserPermissionsLoader';
import { ROUTES_URLS } from 'const';
import ModalStackRouterProvider from 'contexts/ModalStackRouterProvider';
import PageLayout from 'layouts/PageLayout';
import RootAuthLayout from 'layouts/RootAuthLayout';
import Page404 from 'pages/404';
import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import AppErrorBoundary from './components/PageErrorBoundary';
import PrivateRoute from './components/PrivateRoute';
import RestrictedRoute from './components/RestrictedRoute';
import { SharedLayoutRoutes } from './PrivateRoutes';
import { AuthRoutes } from './restrictedRoutes';

const Home = lazy(() => import('pages/Home'));

export const router = createBrowserRouter([
	{
		element: (
			<AppErrorBoundary>
				<UserPermissionsLoader />
			</AppErrorBoundary>
		),
		children: [
			{
				element: <ModalStackRouterProvider />,
				shouldRevalidate: () => false,
				children: [
					{
						path: ROUTES_URLS.HOME,
						children: [
							{
								element: <PrivateRoute />,
								children: [
									{
										element: <Home />,
										index: true,
									},
								],
							},
							{
								element: <PrivateRoute />,
								children: [
									{
										element: <PageLayout />,
										children: [...SharedLayoutRoutes],
									},
								],
							},
						],
					},
				],
			},
		],
	},
	{
		element: <RootAuthLayout />,
		children: [
			{
				element: <RestrictedRoute />,
				children: [...AuthRoutes],
			},
		],
	},
	{
		path: '*',
		element: <Page404 />,
	},
]);
