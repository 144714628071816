import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';

const SpinnerV3: React.FC<{ className?: string }> = ({ className }) => {
	return <div className={clsx(styles.factorySpinner, className)} />;
};

export default SpinnerV3;
