import { useRemoveDOMElement } from 'hooks/useRemoveDOMElement';
import React from 'react';

const PageLayoutSkeleton = () => {
	useRemoveDOMElement({ selector: '#initial-loader', removeOnMount: true });

	return (
		<div style={{ width: '100%', height: '100vh', display: 'flex' }}>
			<div style={{ padding: '16px', display: 'flex', flexDirection: 'column' }}>
				<div style={{ width: '96px', height: '96px', marginBottom: '24px', flexShrink: 0 }} className="skeleton" />
				<div style={{ width: '96px', height: '100%' }} className="skeleton" />
			</div>

			<div style={{ padding: '16px', flexGrow: '2' }}>
				<div style={{ width: '100%', height: '120px', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '80%', height: '100px', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: 'calc(100% - 120px - 100px - 16px * 2 - 8px)' }} className="skeleton" />
			</div>
		</div>
	);
};

export default PageLayoutSkeleton;
