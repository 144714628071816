import { z } from 'zod';

const OriginalResponseMetaDataSchema = z.object({
	meta: z.object({
		current_page: z.number().default(1),
		last_page: z.number().nullable().default(1),
		total: z.number().nullable().default(0),
	}),
});
const TransformedResponseMetaDataSchema = z.object({
	page: z.number().default(1),
	pagesCount: z.number().nullable().default(1),
	total: z.number().nullable().default(0),
});

export const OriginalPaginatedRequestResultSchema = OriginalResponseMetaDataSchema.extend({ data: z.any() });

type OriginalResponseMetaData = z.infer<typeof OriginalResponseMetaDataSchema>;
type TransformedResponseMetaData = z.infer<typeof TransformedResponseMetaDataSchema>;

export interface OriginalPaginatedRequestResult<TData> extends OriginalResponseMetaData {
	data: TData;
}
export interface PaginatedRequestResult<TData> extends TransformedResponseMetaData {
	data: TData;
}
export interface RequestResult<TData> {
	data: TData;
}
