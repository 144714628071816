import { createContextualCan } from '@casl/react';
import { defineAbilitiesFor } from 'abilities/defineAbilities';
import { useAppSelector } from 'hooks/redux';
import React, { createContext, useContext, useMemo } from 'react';
import { authSelectors } from 'store/reducers/auth/selectors';

const AbilityContext = createContext(null);

export const CanUser = createContextualCan(AbilityContext.Consumer);

export const AbilityProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const user = useAppSelector(authSelectors.selectWhoamI);

	const ability = useMemo(() => defineAbilitiesFor(user?.permissions ?? {}), [user?.permissions]);

	return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const useUserAbility = () => useContext(AbilityContext);
