import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import type { ServiceInternalModelState } from 'pages/Order/OrderController';
import React from 'react';
import { Controller } from 'react-hook-form';
import { formatInputValue, replaceComaWithDot } from 'utils/inputs';
import { roundNumber, toFixed } from 'utils/shared';

import styles from './styles.module.css';

interface ServiceDependantInputFieldProps {
	suborderIndex: number;
	disabled?: boolean;
	service: ServiceInternalModelState;
}

const formatValue = formatInputValue({ isInt: false });

const ServiceDependantInputField: React.FC<ServiceDependantInputFieldProps> = ({ service, suborderIndex, disabled }) => {
	const entityId = service.id;
	const { control, setDirtyValue, getValues } = useOrderOperationMethods();

	const priceFieldName = `suborders.${suborderIndex}.data.services.${entityId}.price` as const;

	const updateValues = (price: string) => {
		const allSuborders = getValues('suborders') ?? [];
		const subordersServices = allSuborders.slice(1).map((suborder) => {
			const candidate = suborder.data.services[entityId];

			if (!candidate) return suborder.data.services;

			const amount = Number(candidate?.amount || 1);
			const sum = toFixed(roundNumber(Number(price) * amount, 2));

			return {
				...suborder.data.services,
				[entityId]: {
					...candidate,
					sum,
					price: toFixed(price, { precise: 2 }),
				},
			};
		});

		const source = allSuborders.length > 1 ? subordersServices : [allSuborders[0].data.services];
		const sumInAllEntityOccurrences = source.reduce((acc, services) => {
			const total = Object.values(services).reduce((subtotal, serviceModel) => {
				if (serviceModel.id !== entityId) {
					return subtotal;
				}
				return (subtotal += Number(price) * Number(serviceModel.amount || 1));
			}, 0);

			acc += total;
			return acc;
		}, 0);

		const rootServices = {
			...allSuborders[0].data.services,
			[entityId]: {
				...allSuborders[0].data.services[entityId],
				sum: toFixed(sumInAllEntityOccurrences, { precise: 2 }),
				price: toFixed(price, { precise: 2 }),
			},
		};

		subordersServices.forEach((services, index) => {
			setDirtyValue(`suborders.${index + 1}.data.services`, services);
		});

		setDirtyValue(`suborders.${0}.data.services`, rootServices);
	};

	return (
		<Controller
			name={priceFieldName}
			control={control}
			render={({ field }) => {
				return (
					<input
						disabled={disabled}
						readOnly={disabled}
						className={styles.inputField}
						type="number"
						value={field.value}
						onKeyDown={replaceComaWithDot}
						onChange={(e) => {
							const price = formatValue(e.currentTarget.value);

							updateValues(String(price));
							field.onChange(e);
						}}
						onBlur={() => {
							const numberedValue = Number(field.value);
							if (!numberedValue) {
								field.onChange('0.00');
							} else {
								field.onChange(toFixed(numberedValue));
							}
						}}
					/>
				);
			}}
		/>
	);
};

export default ServiceDependantInputField;
