import { APP_MODE_STORAGE_KEY } from 'const';
import localforage from 'localforage';

function storage(dbName: string) {
	const db = localforage.createInstance({
		name: dbName,
	});

	return {
		db,
		getItem: db.getItem,
		setItem: db.setItem,
		removeItem: db.removeItem,
	};
}

export const persistConfig = {
	key: 'root',
	storage: storage(APP_MODE_STORAGE_KEY),
	whitelist: ['app'],
	serialize: false,
	deserialize: false,
};
