import Button from 'components/Button';
import MainSearch from 'components/MainSearch';
import { MainSearchSwitchButton } from 'components/MainSearch/components/MainSearchSwitchButton';
import { MainSearchSwitchInput } from 'components/MainSearch/components/MainSearchSwitchInput';
import React from 'react';
import { ReactComponent as ArrowIcon } from 'static/images/arrow-block-left.svg';
import { ReactComponent as UsersIcon } from 'static/images/eyeWhite.svg';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';

import styles from './style.module.css';
import type { IProps } from './types';

const CreatePriceTopBar: React.FC<IProps> = ({ tableMode, onChooseButtonClick, onFiltersButtonClick, handleBack, nameOfMainBtn }) => {
	const handleChoosePriceButtonClick = () => {
		onChooseButtonClick?.();
	};

	const handleOpenFilterButtonClick = () => {
		onFiltersButtonClick?.();
	};

	const handleBackButtonClick = () => {
		handleBack?.();
	};

	return (
		<div className={styles.topBarWrapper}>
			<MainSearch>
				<MainSearchSwitchInput defaultQueryKey="search" placeholder="Почніть писати...">
					<MainSearchSwitchButton queryKey="search" title="Пошук" />
				</MainSearchSwitchInput>
			</MainSearch>

			<div className={styles.buttonsWrapper}>
				{tableMode ? (
					<Button text={nameOfMainBtn} onClick={handleBackButtonClick} icon={<ArrowIcon />} variant="rounded" background={'#D71B87'} />
				) : (
					<>
						<Button
							text="Оберіть товар"
							onClick={handleChoosePriceButtonClick}
							icon={<UsersIcon />}
							variant="rounded"
							background={'#D71B87'}
						/>
						<Button
							text="Додати коментар"
							onClick={handleOpenFilterButtonClick}
							icon={<PlusIcon />}
							variant="rounded"
							background={'#2E90FA'}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default CreatePriceTopBar;
