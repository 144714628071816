import BaseError from '../base.error';

export class ChunkLoadError extends BaseError {
	constructor() {
		super({
			cause: 'CHUNK_LOAD_ERROR',
			message: 'Chunk of code was not found. Maybe app was re-build? so chunk with hash is no longer on server',
			type: 'RESOURCE',
		});
	}
}
