import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import ServiceCounter from 'components/OrderServicesPageComponents/ServiceCounter';
import ServiceDeleteButton from 'components/OrderServicesPageComponents/ServiceDeleteButton';
import ServiceDependantCode from 'components/OrderServicesPageComponents/ServiceDependantCode';
import ServiceDependantInputField from 'components/OrderServicesPageComponents/ServiceDependantInput';
import ServiceOptionSelectDependantField from 'components/OrderServicesPageComponents/ServiceSelectField/ServiceSelectDependantField';
import ServiceOptionSelectField from 'components/OrderServicesPageComponents/ServiceSelectField/ServiceSelectMajorField';
import TableCheckbox from 'components/Table/TableComponents/CheckboxColumn/TableCheckbox';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import DetailedEntitySpitData from 'pages/Order/components/DetailedEntitySpitData';
import DisabledEntityTooltip from 'pages/Order/components/DisabledEntityTooltip';
import ServiceSumDisplayField from 'pages/Order/components/ServiceSumDisplayField';
import type { OnEntityDeletePayload } from 'pages/Order/hooks/useOrderOperationMethods';
import { visibilityColumnsIcons } from 'pages/Order/lib/icons';
import { Can } from 'pages/Order/OrderAbility/provider';
import type { ServiceInternalModelState } from 'pages/Order/OrderController';
import { getIsOnSplitViewPage } from 'pages/Order/utils';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const columnHelper = createColumnHelper<ServiceInternalModelState>();

interface UseRegularServicesColumnsParams {
	suborderIndex: number;
	onEntityDelete: (payload: OnEntityDeletePayload) => void;
}

export const useRegularServicesColumns = ({ suborderIndex, onEntityDelete }: UseRegularServicesColumnsParams) => {
	const location = useLocation();

	const columns = useMemo(() => {
		const abilityOrderKey = getIsOnSplitViewPage() ? 'suborder' : 'order';

		const columnDefs = [
			columnHelper.accessor((row) => row.id, {
				id: 'id',
				header: () => {
					return <HeaderCell>{'Id'}</HeaderCell>;
				},
				cell: (cell) => <RegularCell>{`${cell.row.index + 1}.`}</RegularCell>,
				size: 88,
				enableHiding: true,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Id',
					getMobileHeaderCell: () => 'Id',
					icon: <visibilityColumnsIcons.Uuid />,
				},
			}),
			columnHelper.accessor(
				() => {
					return null;
				},
				{
					id: 'select-col',
					header: ({ table }) => {
						return (
							<HeaderCell>
								<Can passThrough I="select" an={`${abilityOrderKey}.${suborderIndex}.services`}>
									{(can) => {
										return (
											<TableCheckbox
												checked={table.getIsAllRowsSelected()}
												indeterminate={table.getIsSomeRowsSelected()}
												onChange={table.getToggleAllRowsSelectedHandler()}
												disabled={!can}
											/>
										);
									}}
								</Can>
							</HeaderCell>
						);
					},
					cell: (cell) => {
						return (
							<RegularCell>
								<Can passThrough I="select" an={`${abilityOrderKey}.${suborderIndex}.services`}>
									{(can) => {
										return (
											<TableCheckbox
												checked={cell.row.getIsSelected()}
												disabled={!can || !cell.row.getCanSelect()}
												onChange={cell.row.getToggleSelectedHandler()}
											/>
										);
									}}
								</Can>
							</RegularCell>
						);
					},
					enableHiding: false,
					size: 88,
					enableSorting: false,
					meta: {
						getCanRenderOnMobile: () => false,
						getMobileHeaderCell: () => '',
						headerCellContentOffset: '19px',
					},
				},
			),
			columnHelper.accessor((row) => row.code, {
				id: 'code',
				header: () => <HeaderCell>Код послуги</HeaderCell>,
				cell: (cell) => {
					return (
						<RegularCell>
							<ServiceDependantCode entityId={cell.row.original.id} suborderIndex={suborderIndex} />
						</RegularCell>
					);
				},
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
				},
			}),
			columnHelper.accessor((row) => row.title, {
				id: 'title',
				header: () => <HeaderCell>Послуги</HeaderCell>,
				cell: (cell) => (
					<RegularCell>
						<span className={clsx({ 'locked-entity': !cell.row.getCanSelect() })}>{cell.getValue()}</span>

						{!cell.row.getCanSelect() && (
							<DisabledEntityTooltip>
								<DetailedEntitySpitData entityId={cell.row.original.id} entityType="services" />
							</DisabledEntityTooltip>
						)}
					</RegularCell>
				),
				size: 250,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
				},
			}),
			columnHelper.accessor((row) => row.service, {
				id: 'service',
				header: () => <HeaderCell>Тип послуги</HeaderCell>,
				cell: (cell) => {
					const isDisabled = !cell.row.original.isMetalCuttingService;

					if (isDisabled) {
						return <RegularCell className={clsx({ disabled: isDisabled })}>Тип відсутній</RegularCell>;
					}

					return (
						<RegularCell className={clsx({ disabled: isDisabled })}>
							<Can passThrough I="change" an={`${abilityOrderKey}.${suborderIndex}.services.table`}>
								{(can) => {
									return (
										<ServiceOptionSelectField
											serviceType={cell.row.original.service?.serviceType}
											suborderIndex={suborderIndex}
											entityId={cell.row.original.id}
											placeholder="Тип відсутній"
											disabled={!can || isDisabled}
										/>
									);
								}}
							</Can>
						</RegularCell>
					);
				},
				size: 300,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
				},
			}),
			columnHelper.accessor((row) => row.category, {
				id: 'category',
				header: () => <HeaderCell>Обʼєкти послуги</HeaderCell>,
				cell: (cell) => {
					const isDisabled = !cell.row.original.isMetalCuttingService;

					if (isDisabled) {
						return <RegularCell className={clsx({ disabled: isDisabled })}>Тип відсутній</RegularCell>;
					}

					return (
						<RegularCell className={clsx({ disabled: isDisabled })}>
							<Can passThrough I="change" an={`${abilityOrderKey}.${suborderIndex}.services.table`}>
								{(can) => {
									return (
										<ServiceOptionSelectDependantField
											suborderIndex={suborderIndex}
											entityId={cell.row.original.id}
											placeholder="Не потрібно вказувати"
											disabled={!can || isDisabled}
										/>
									);
								}}
							</Can>
						</RegularCell>
					);
				},
				size: 350,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
				},
			}),
			columnHelper.accessor((row) => row.price, {
				id: 'price',
				header: () => <HeaderCell>Ціна DIY</HeaderCell>,
				cell: (cell) => {
					const isDisabled = !cell.row.original.canEditManuallyPrice;

					return (
						<RegularCell className={clsx({ disabled: isDisabled })}>
							<Can passThrough I="change" an={`${abilityOrderKey}.${suborderIndex}.services.table`}>
								{(can) => {
									return (
										<ServiceDependantInputField
											suborderIndex={suborderIndex}
											service={cell.row.original}
											disabled={!can || isDisabled}
										/>
									);
								}}
							</Can>
						</RegularCell>
					);
				},
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
				},
			}),
			columnHelper.accessor(
				(row) => {
					return Number(row.amount) || 1;
				},
				{
					id: 'amount',
					header: () => <HeaderCell>Кількість</HeaderCell>,
					cell: (cell) => {
						return (
							<RegularCell>
								<Can passThrough I="change" an={`${abilityOrderKey}.${suborderIndex}.services.table`}>
									{(can) => {
										return <ServiceCounter service={cell.row.original} suborderIndex={suborderIndex} disabled={!can} />;
									}}
								</Can>
							</RegularCell>
						);
					},
					size: 220,
					enableSorting: false,
					meta: {
						getCanRenderOnMobile: () => false,
					},
				},
			),
			columnHelper.accessor(
				(row) => {
					return Number(row.sum ?? 0) || 0;
				},
				{
					id: 'sum',
					header: () => <HeaderCell>Сума</HeaderCell>,
					cell: (cell) => {
						return (
							<RegularCell>
								<ServiceSumDisplayField entityId={cell.row.original.id} suborderIndex={suborderIndex} />
							</RegularCell>
						);
					},
					size: 220,
					enableSorting: false,
					meta: {
						getCanRenderOnMobile: () => false,
					},
				},
			),
			columnHelper.accessor(() => null, {
				id: 'action',
				header: () => <HeaderCell>Прибрати</HeaderCell>,
				cell: (cell) => {
					const onDelete = () => {
						onEntityDelete({ candidates: [cell.row.original.id], entityName: 'services', suborderIndex });
					};

					return (
						<RegularCell justify="center">
							<Can passThrough I="change" an={`${abilityOrderKey}.${suborderIndex}.services.table`}>
								{(can) => {
									return <ServiceDeleteButton onDelete={onDelete} disabled={!can} />;
								}}
							</Can>
						</RegularCell>
					);
				},
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
				},
			}),
		];

		return columnDefs;
	}, [suborderIndex, location.pathname]);

	return columns;
};
// export const usePaintToningServicesColumns = () => {
// 	const {
// 		draftOrder: { onInputChangePaintToning, onSelectChangePaintToning },
// 	} = useOrderViewer();

// 	const columns = useMemo(
// 		() => [
// 			{
// 				Header: () => (
// 					<HeaderCell>
// 						<HeaderCellContent justify="flex-start" value="Послуги тонування" />
// 					</HeaderCell>
// 				),
// 				accessor: 'service',
// 				Cell: ({ row }) => (
// 					<RegularCell>
// 						<RegularCellContent color="var(--gray-900)" fontWeight="500" justify="flex-start">
// 							{row.original.service}
// 						</RegularCellContent>
// 					</RegularCell>
// 				),
// 				meta: {
// 					getCanRenderOnMobile: () => false,
// 				},
// 			},
// 			{
// 				Header: () => (
// 					<HeaderCell>
// 						<HeaderCellContent justify="flex-start" value="Код кольору" />
// 					</HeaderCell>
// 				),
// 				accessor: 'colorCode',
// 				Cell: ({ row }) => {
// 					return (
// 						<RegularCell>
// 							<RegularCellContent justify="flex-start" width="100%">
// 								<input
// 									defaultValue={row.original.colorCode}
// 									placeholder="Введіть код кольору..."
// 									onChange={onInputChangePaintToning(row.original.id, 'colorCode')}
// 								/>
// 							</RegularCellContent>
// 						</RegularCell>
// 					);
// 				},
// 				meta: {
// 					getCanRenderOnMobile: () => false,
// 				},
// 			},
// 			{
// 				Header: () => (
// 					<HeaderCell>
// 						<HeaderCellContent justify="flex-start" value="Колекція" />
// 					</HeaderCell>
// 				),
// 				accessor: 'collection',
// 				Cell: ({ row }) => {
// 					return (
// 						<RegularCell>
// 							<RegularCellContent justify="flex-start" width="100%">
// 								<RegularServiceSelectField
// 									value={row.original.collection}
// 									onSelect={onSelectChangePaintToning(row.original.id, 'collection')}
// 									popupHeaderText="Оберіть колекцію"
// 									placeholder="Оберіть колекцію"
// 									useQuery={useGetPaintCollectionOptionListQuery}
// 								/>
// 							</RegularCellContent>
// 						</RegularCell>
// 					);
// 				},
// 				meta: {
// 					getCanRenderOnMobile: () => false,
// 				},
// 			},
// 			{
// 				Header: ({ column, rows }) => (
// 					<HeaderCell>
// 						<HeaderCellContent sortable withIndeterminate disabled={rows.length < 2} column={column} justify="center" value="База" />
// 					</HeaderCell>
// 				),
// 				accessor: 'baseTone',
// 				Cell: ({ row }) => {
// 					return (
// 						<RegularCell>
// 							<RegularCellContent justify="center" width="100%">
// 								<RegularServiceSelectField
// 									value={row.original.baseTone}
// 									onSelect={onSelectChangePaintToning(row.original.id, 'baseTone')}
// 									popupHeaderText="Оберіть базу"
// 									placeholder="Оберіть базу"
// 									useQuery={useGetPaintBaseToneOptionListQuery}
// 								/>
// 							</RegularCellContent>
// 						</RegularCell>
// 					);
// 				},
// 				meta: {
// 					getCanRenderOnMobile: () => false,
// 				},
// 			},
// 			{
// 				Header: ({ column, rows }) => (
// 					<HeaderCell>
// 						<HeaderCellContent sortable withIndeterminate disabled={rows.length < 2} column={column} justify="center" value="Тара" />
// 					</HeaderCell>
// 				),
// 				width: 'fit-content',
// 				accessor: 'tare',
// 				Cell: ({ row }) => {
// 					return (
// 						<RegularCell>
// 							<RegularCellContent justify="center" width="100%">
// 								{row.original.tare}
// 							</RegularCellContent>
// 						</RegularCell>
// 					);
// 				},
// 				meta: {
// 					getCanRenderOnMobile: () => false,
// 				},
// 			},
// 			{
// 				Header: ({ column, rows }) => (
// 					<HeaderCell>
// 						<HeaderCellContent
// 							sortable
// 							withIndeterminate
// 							disabled={rows.length < 2}
// 							column={column}
// 							justify="center"
// 							value="Ціна тонування"
// 						/>
// 					</HeaderCell>
// 				),
// 				accessor: 'price',
// 				Cell: ({ row }) => {
// 					return (
// 						<RegularCell>
// 							<RegularCellContent justify="center" width="100%">
// 								<ServiceInputField id={row.original.id} value={row.original.price} />
// 							</RegularCellContent>
// 						</RegularCell>
// 					);
// 				},
// 				meta: {
// 					getCanRenderOnMobile: () => false,
// 				},
// 			},
// 			{
// 				Header: () => (
// 					<HeaderCell>
// 						<HeaderCellContent justify="center" value="Кількість одиниць фарби" />
// 					</HeaderCell>
// 				),
// 				accessor: 'quantity',
// 				Cell: ({ row }) => {
// 					return (
// 						<RegularCell>
// 							<RegularCellContent justify="center" width="100%">
// 								<LockedServiceCellCounterGate itemId={row.original.id}>
// 									<ServiceCounter fieldIndex={row.index} value={row.original.amount} />
// 								</LockedServiceCellCounterGate>
// 							</RegularCellContent>
// 						</RegularCell>
// 					);
// 				},
// 				meta: {
// 					getCanRenderOnMobile: () => false,
// 				},
// 			},
// 			{
// 				Header: () => (
// 					<HeaderCell>
// 						<HeaderCellContent justify="center" value="Прибрати" />
// 					</HeaderCell>
// 				),
// 				accessor: 'action',
// 				Cell: ({ row }) => {
// 					return (
// 						<RegularCell justify="center">
// 							<RegularCellContent>
// 								<LockedServiceCellWithDeleteButtonGate itemId={row.original.id}>
// 									<ServiceDeleteButton item={row.original} />
// 								</LockedServiceCellWithDeleteButtonGate>
// 							</RegularCellContent>
// 						</RegularCell>
// 					);
// 				},
// 				meta: {
// 					getCanRenderOnMobile: () => false,
// 				},
// 			},
// 		],
// 		[],
// 	);

// 	return columns;
// };
