import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import clsx from 'clsx';
import { breakPoints } from 'const';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { isObject } from 'utils/type-guards';

import { getCommonPinningStyles } from './lib/table/styles/getCommonPinningStyles';
import styles from './lib/table/styles/style.module.css';
import type { IProps } from './lib/table/types/general';
import Pagination from './Pagination';
import ColumnControlsMenu from './TableComponents/ColumnControlsMenu';
import PinColumnMenu from './TableComponents/PinColumnMenu';
import SortColumnMenu from './TableComponents/SortColumnMenu';

const MobileTableView = lazy(() => import('./TableComponents/MobileTableView'));

const fallbackModelState = {};

const defaultGetRowId = <TData,>(row: TData): string => {
	if (isObject<TData>(row) && 'id' in row) {
		return row.id as string;
	}
};

const Table = <TData,>({ actionsPanelSlot: ActionsPanelSlot, ...props }: IProps<TData>) => {
	const {
		allData,
		columns,
		filledFromSecondCell,
		selectable,
		onClickRow,
		className,
		extendRowProps,
		defineRowDisabledForSelection,
		visibilityModel = fallbackModelState,
		pinningModel = fallbackModelState,
		onPinningModelChange,
		rowSelectionModel = fallbackModelState,
		onRowSelectionModelChange,
		pageCount,
		getRowId = defaultGetRowId,
		getCanSelectRow,
		shouldHighlightDisableRow = true,
	} = props;

	const [data, setData] = useState([...allData]);
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });

	const table = useReactTable<TData>({
		columns,
		data,
		debugTable: true,
		state: {
			columnVisibility: visibilityModel,
			columnPinning: pinningModel,
			rowSelection: rowSelectionModel,
		},
		getRowId,
		enableRowSelection: getCanSelectRow,
		manualPagination: true,
		manualSorting: true,
		getCoreRowModel: getCoreRowModel<TData>(),
		onRowSelectionChange: onRowSelectionModelChange,
		onColumnPinningChange: onPinningModelChange,
		columnResizeMode: 'onChange',
	});

	const { beforeTable, afterTable } = props.childrenLikeSlots ?? {};
	const RenderTableHeader = props.renderTableHeader;
	const RenderTableBody = props.renderTableBody;

	const handleClickOnRow = (row: TData) => {
		onClickRow?.(row);
	};

	useEffect(() => {
		setData(allData);
	}, [allData]);

	const shouldRenderActionSlot = !!ActionsPanelSlot && Object.keys(rowSelectionModel ?? {}).length > 0;

	if (isMobile) {
		return (
			<Suspense fallback={<PageContentSkeleton />}>
				<MobileTableView tableInstance={table} {...props} />

				{pageCount > 1 && <Pagination />}
			</Suspense>
		);
	}

	return (
		<>
			{beforeTable}
			<div className={clsx(styles.container, className)}>
				<div style={{ display: shouldRenderActionSlot ? 'block' : 'none' }}>
					{!!ActionsPanelSlot && (
						<ActionsPanelSlot
							tableInstance={table}
							allData={allData}
							rowSelectionModel={rowSelectionModel}
							onRowSelectionModelChange={onRowSelectionModelChange}
						/>
					)}
				</div>
				<table
					className={styles.table}
					style={{
						width: '100%',
						minWidth: table.getTotalSize(),
					}}
				>
					{!!RenderTableHeader && !shouldRenderActionSlot && <RenderTableHeader table={table} />}

					<thead id="table-head" style={{ display: !RenderTableHeader && !shouldRenderActionSlot ? 'table-header-group' : 'none' }}>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									const pinningStyles = getCommonPinningStyles(header.column);
									return (
										<th
											key={header.id}
											colSpan={header.colSpan}
											className={clsx('text-sx-medium color-grey-600')}
											style={
												{
													width: header.getSize(),
													'--column-controls-menu-offset': header.column.columnDef.meta?.headerCellContentOffset ?? '12px',
													...pinningStyles,
												} as React.CSSProperties
											}
										>
											<span className="cell as-parent-cell">
												{!header.isPlaceholder && flexRender(header.column.columnDef.header, header.getContext())}
												<ColumnControlsMenu>
													{header.column.getCanPin() && <PinColumnMenu headerInstance={header} tableInstance={table} />}
													{header.column.getCanSort() && (
														<SortColumnMenu
															appendQueryKey={header.column.columnDef?.meta?.appendQueryKey}
															sortColumnKey={header.column.columnDef?.meta?.sortQueryKey ?? ''}
														/>
													)}
												</ColumnControlsMenu>
											</span>
										</th>
									);
								})}
							</tr>
						))}
					</thead>

					{!!RenderTableBody && <RenderTableBody table={table} />}
					{!RenderTableBody && (
						<tbody>
							{table.getRowModel().rows.map((row) => {
								const resolvedRow = defineRowDisabledForSelection?.(row) || row;
								const {
									style: extendedStyles,
									className: extendedRowClassName,
									...restExtendedProps
								} = extendRowProps?.(resolvedRow) ?? {};

								return (
									<tr
										data-tbody-table-row
										key={row.id}
										className={clsx(styles.tableRow, 'text-sx-regular', extendedRowClassName, {
											[styles.canNotSelect]: !row.getCanSelect() && shouldHighlightDisableRow,
										})}
										style={{ cursor: onClickRow && 'pointer', ...(isObject(extendedStyles) && extendedStyles) }}
										onClick={(e) => {
											e.stopPropagation();
											handleClickOnRow(row.original);
										}}
										{...restExtendedProps}
									>
										{row.getVisibleCells().map((cell) => {
											return (
												<td
													key={cell.column.id}
													className={clsx(
														{
															[styles.tableCell]: selectable || filledFromSecondCell,
															[styles.tableCellAlternative]: !selectable && !filledFromSecondCell,
															[styles.isPinned]: cell.column.getIsPinned(),
														},
														cell.column.columnDef?.meta?.getTdCssClassName?.(row.original),
													)}
													style={{
														width: cell.column.getSize(),
														...getCommonPinningStyles(cell.column),
													}}
												>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					)}
				</table>
			</div>
			{afterTable}
		</>
	);
};

export default Table;
