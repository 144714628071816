import { ROUTES_URLS, SERVER_ENTITY_ID } from 'const';
import type { ProductPrice } from 'models/price';
import type { OrderProduct, ProductUnit } from 'models/product';
import type { CatalogueProductWithAmount } from 'models/product/catalogue-product';
import { MetalCuttingServiceSchema, OrderService } from 'models/service';
import { roundNumber, uuid } from 'utils/shared';
import { isObject } from 'utils/type-guards';

import { ProductInternalModelState, ServiceInternalModelState, SuborderTab } from './schema';

type EntityType = 'products' | 'services';

export const getEntityDIYPrice = <TData extends { prices?: ProductPrice[] }>(entity: TData, entityType: EntityType) => {
	const priceDIYId = entityType === 'products' ? SERVER_ENTITY_ID.ProductDYIPriceType : SERVER_ENTITY_ID.ServiceDYIPriceType;

	return entity?.prices?.find((price) => price.typePrice.id === priceDIYId)?.price ?? '0.00';
};

export const getEntityDefaultPrice = (entity: CatalogueProductWithAmount | OrderProduct, entityType: EntityType) => {
	if (isObject(entity) && 'price' in entity) {
		return entity;
	}

	return {
		...entity,
		price: getEntityDIYPrice(entity as CatalogueProductWithAmount, entityType),
	};
};

export const calculateTotals = <TEntity extends { unit?: ProductUnit; price?: string | number; amount?: string | number; prices?: ProductPrice[] }>(
	products: TEntity[],
) => {
	return products.reduce(
		(acc, product) => {
			const dyiPrice = product?.price || getEntityDIYPrice(product, 'products');

			acc.sum = roundNumber(acc.sum + Number(dyiPrice) * Number(product.amount), 2);
			acc.weight = roundNumber(acc.weight + Number(product.unit?.weight || 0) * Number(product?.amount || 0), 2);
			acc.volume = roundNumber(acc.volume + Number(product.unit?.volume || 0) * Number(product?.amount || 0), 2);

			return acc;
		},
		{ sum: 0, weight: 0, volume: 0 } as { sum: number; weight: number; volume: number },
	);
};

export const calculateTotalServicesCost = (services: ServiceInternalModelState[]): number => {
	const result = services.reduce((acc, service) => {
		acc += Number(service?.price) * Number(service?.amount);
		return acc;
	}, 0);

	return roundNumber(result, 2);
};

export const getCanSelectProductRowHandler = (activeSuborderIndex: number, suborders: SuborderTab[]) => (product: ProductInternalModelState) => {
	if (activeSuborderIndex === 0) {
		return !suborders.slice(1).some((suborder) => !!suborder.data.products[product.id]);
	}
	return true;
};

export const getCanSelectServiceRowHandler = (activeSuborderIndex: number, suborders: SuborderTab[]) => (service: ServiceInternalModelState) => {
	if (activeSuborderIndex === 0) {
		return !suborders.slice(1).some((suborder) => !!suborder.data.services[service.id]);
	}
	return true;
};

export const isRouteBlacklistedForUnsafeNavigation = (pathname: string) => {
	return (
		!pathname.startsWith('/' + ROUTES_URLS.ORDER_PREVIEW_ROOT + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT) &&
		!pathname.startsWith('/' + ROUTES_URLS.ORDER_NEW_ROOT + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT)
	);
};

export const getBlankProduct = () => {
	return {
		amount: '0',
		brand: { id: uuid(), title: 'empty-product-brand' },
		code: uuid(),
		description: '',
		deviation: '0',
		id: uuid(),
		freeLeftovers: [],
		leftovers: [],
		reserves: [],
		prices: [],
		sum: '0',
		profitability: '0',
		title: 'emptpy-product',
		unit: { coefficient: 0, id: uuid(), title: '', volume: 0, weight: 0 },
		price: '0',
		isBlank: true,
	};
};

type ServiceType = {
	isMetalCuttingService: boolean;
	isTransportationService: boolean;
	isMoversService: boolean;
	isPaintToningService: boolean;
};
export const getServiceType = (service: OrderService): ServiceType => {
	const result: ServiceType = {
		isMetalCuttingService: false,
		isMoversService: false,
		isPaintToningService: false,
		isTransportationService: false,
	};

	switch (service.title) {
		case 'Послуги з тонування штукатурки та фарби':
			result.isPaintToningService = true;
			break;
		case 'Послуги вантажників':
			result.isMoversService = true;
			break;
		case 'Послуги по автоперевезенню вантажним транспортом':
			result.isTransportationService = true;
			break;
		default:
			result.isMetalCuttingService = true;
	}

	return result;
};

export const verifyIsMetalCuttingService = (service: unknown) => MetalCuttingServiceSchema.safeParse(service);
