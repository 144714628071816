import { isPromise } from 'utils/type-guards';

interface BaseServiceResponseOptions {
	status?: number;
	headers?: ResponseInit['headers'];
}

export class BaseService {
	async response<T>(payload: unknown | Promise<T>, options?: BaseServiceResponseOptions) {
		const { status = 200, headers } = options || {};

		const responseInit = {
			status,
			headers: {
				'Content-Type': 'application/json',
				...headers,
			},
		};

		if (isPromise(payload)) {
			const data = await payload;

			return new Response(JSON.stringify(data), responseInit);
		}

		return Promise.resolve(new Response(JSON.stringify(payload), responseInit));
	}
}
