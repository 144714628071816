import TableCellInput from 'components/OrderCreatePageComponents/TableCellInput';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import { usePriceColor } from 'pages/Order/hooks/usePriceColor';
import { getDeviationFromPrice, normalizeCalculatedDeviation } from 'pages/Order/lib/shared';
import type { ProductInternalModelState } from 'pages/Order/OrderController';
import React from 'react';
import { Controller } from 'react-hook-form';
import { replaceComaWithDot } from 'utils/inputs';
import { roundNumber, toFixed } from 'utils/shared';

interface OrderProductPriceInputProps {
	suborderIndex: number;
	product: ProductInternalModelState;
	disabled?: boolean;
}

const OrderProductPriceInput: React.FC<OrderProductPriceInputProps> = ({ suborderIndex, product, disabled }) => {
	const entityId = product.id;
	const isInt = !!product.unit?.isInt;

	const { getPriceColor } = usePriceColor(product);
	const { control, setDirtyValue, getValues } = useOrderOperationMethods();
	const priceFieldName = `suborders.${suborderIndex}.data.products.${entityId}.price` as const;

	const updateValues = (price: string) => {
		const allSuborders = getValues('suborders') ?? [];
		const subordersProducts = allSuborders.slice(1).map((suborder) => {
			const candidate = suborder.data.products[entityId];

			if (!candidate) return suborder.data.products;

			const amount = Number(candidate?.amount || 1);
			const rawDeviation = getDeviationFromPrice(price, product);
			const deviation = normalizeCalculatedDeviation(rawDeviation);
			const sum = toFixed(roundNumber(Number(price) * amount, 2));

			return {
				...suborder.data.products,
				[candidate.id]: {
					...candidate,
					sum,
					price: toFixed(price, { precise: 2 }),
					amount: toFixed(amount, { isInt }),
					deviation: toFixed(deviation, { precise: 2 }),
				},
			};
		});

		const source = allSuborders.length > 1 ? subordersProducts : [allSuborders[0].data.products];
		const sumInAllEntityOccurrences = source.reduce((acc, products) => {
			const total = Object.values(products).reduce((subtotal, productModel) => {
				if (productModel.id !== entityId) {
					return subtotal;
				}
				return (subtotal += Number(price) * Number(productModel.amount || 1));
			}, 0);

			acc += total;
			return acc;
		}, 0);

		const deviation = getDeviationFromPrice(price, product);

		const rootProducts = {
			...allSuborders[0].data.products,
			[entityId]: {
				...allSuborders[0].data.products[entityId],
				sum: toFixed(sumInAllEntityOccurrences, { precise: 2 }),
				deviation: toFixed(deviation, { precise: 2 }),
				price: toFixed(price, { precise: 2 }),
			},
		};

		subordersProducts.forEach((products, index) => {
			setDirtyValue(`suborders.${index + 1}.data.products`, products);
		});

		setDirtyValue(`suborders.${0}.data.products`, rootProducts);
	};

	return (
		<Controller
			name={priceFieldName}
			control={control}
			render={({ field }) => {
				return (
					<TableCellInput
						type="text"
						value={field.value}
						disabled={disabled}
						readOnly={disabled}
						onKeyDown={replaceComaWithDot}
						style={{ color: getPriceColor(field.value) }}
						onChange={(e) => {
							const price = Number(e.currentTarget.value);

							updateValues(String(price));
							field.onChange(e);
						}}
						onBlur={() => {
							const numberedValue = Number(field.value);

							if (!numberedValue) {
								updateValues('0');
								field.onChange('0.00');
							} else {
								field.onChange(toFixed(numberedValue, { precise: 2 }));
							}
						}}
					/>
				);
			}}
		/>
	);
};

export default OrderProductPriceInput;
