import BaseError, { BaseErrorParams } from '../base.error';

type ZodErrorParams = Pick<BaseErrorParams, 'message' | 'cause'>;

export class ZodError extends BaseError {
	constructor(params: ZodErrorParams) {
		super({
			cause: 'DATA_VALIDATION_FAILED',
			type: 'ZOD_ERROR',
			...params,
		});
	}
}
