import clsx from 'clsx';
import Input from 'components/Input';
import type { MainSearchInputProps } from 'components/MainSearch/lib/types';
import React, { ForwardedRef, forwardRef, useState } from 'react';
import Magnify from 'static/images/Magnify.svg';

import { useMainSearch } from '../../Provider';
import styles from './styles.module.css';

const InputComponent = ({ placeholder, className, icon = Magnify, ...props }: MainSearchInputProps, ref: ForwardedRef<HTMLInputElement>) => {
	const { onChange } = useMainSearch();
	const [value, setValue] = useState('');

	const handleChange = (newValue: string) => {
		setValue(newValue);
		onChange(newValue);
	};

	return (
		<Input
			ref={ref}
			type="text"
			icon={icon}
			iconPosition="leading"
			wrapperClassName={clsx(styles.wrapper, className)}
			className={clsx(styles.input, className)}
			placeholder={placeholder}
			setValue={handleChange}
			value={value}
			onClick={(e) => e.stopPropagation()}
			autoComplete="off"
			{...props}
		/>
	);
};

export const MainSearchInput = forwardRef<HTMLInputElement, MainSearchInputProps>(InputComponent);
