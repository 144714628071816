import { z } from 'zod';

const LoginDTOSchema = z.object({
	email: z.string().email(),
	password: z.string(),
	remember: z.boolean(),
});

export const LoginResultSchema = z.object({
	access_token: z.string().min(1),
	refresh_token: z.string().min(1),
	token_type: z.literal('bearer'),
	expires_in: z.number().positive(),
	role: z.string().min(1),
});

export type LoginDTO = z.infer<typeof LoginDTOSchema>;
export type LoginResult = z.infer<typeof LoginResultSchema>;
export type RefreshResult = LoginResult;

export type LoginViaInvitationLinkDTO = {
	email: string;
	token: string;
	password: string;
	password_confirmation: string;
	personal_data_agreement: boolean;
};

export type SendInvitationDTO = { email: string };
export type GetCanProceedDangerousDTO = { password?: string };
export type CanProceedDangerous = { isGranted: boolean };
