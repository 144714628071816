import { z } from 'zod';

// TODOType discuss with BE
const CreatePriceSchema = z.object({
	id: z.string(),
	brand: z.string(),
	title: z.string(),
	quantity: z.number(),
	price: z.number(),
	measureUnit: z.object({
		label: z.string(),
		value: z.string(),
	}),
	inReserve: z.string(),
	inStock: z.string(),
	min: z.string(),
	entry: z.string(),
	bulk: z.string(),
});

export type CreatePrice = z.infer<typeof CreatePriceSchema>;
