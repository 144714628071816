import clsx from 'clsx';
import BreadCrumbs from 'components/BreadCrumbs';
import Button from 'components/Button';
import FastGroupFilters from 'components/ClientsFilter/FastGroupFilters';
import ClientsTopBar from 'components/ClientsTopBar';
import ColumnsSettingsMenu from 'components/ColumnsSettingsMenu';
import FilterWrapper from 'components/FilterWrapper';
import PerPageSizeSelector from 'components/PerPageSizeSelector';
import SearchParamsController from 'components/SearchParamsController';
import SearchParamsControllerAdapter from 'components/SharedFilterDrawer/adapters/SearchParamsControllerAdapter';
import ByClientFilter from 'components/SharedFilterDrawer/filters/ByClientFilter';
import ByResponsibleFilter from 'components/SharedFilterDrawer/filters/ByResponsibleFilter';
import BySegmentFilter from 'components/SharedFilterDrawer/filters/BySegmentFilter';
import Spacer from 'components/Spacer';
import Spinner from 'components/Spinner';
import Table from 'components/Table';
import SegmentDropDownFilter from 'components/Table/Filters/SegmentDropDownFilter';
import SettingButton from 'components/Table/Filters/SettingsButton';
import Pagination from 'components/Table/Pagination';
import EmptyTable from 'components/Table/TableComponents/EmptyTable';
import { breakPoints, CLIENTS_FAST_FILTERS } from 'const';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import React, { lazy, Suspense } from 'react';
import MediaQuery from 'react-responsive';

import { clientsIcons } from './lib/icons';
import { useClientsPage } from './lib/useClientsPage';
import styles from './style.module.css';
import type { IProps } from './types';

const SharedFilterDrawer = lazy(() => import('components/SharedFilterDrawer'));

const Clients: React.FC<IProps> = (props) => {
	const {
		isLoadingClients,
		isFetchingClients,
		isEmpty,
		page,
		pagesCount,
		columns,
		clients,
		pinning,
		filtering,
		visibility,
		breadcrumbs,
		onClientClick,
		totalClientsCount,
		visibilityInterface,
		filtersDrawerInterface,
		toggleVisibilityInterface,
		onRedirectToCreateClientPage,
		toggleFiltersDrawerInterface,
		toggleMobileFiltersInterface,
	} = useClientsPage(props);

	const { managers, segments } = filtering;

	if (isLoadingClients) {
		return <PageContentSkeleton />;
	}

	return (
		<>
			<div className={clsx('container', styles.container)}>
				<BreadCrumbs crumbs={breadcrumbs} onClickFilter={toggleMobileFiltersInterface} />

				<MediaQuery minWidth={breakPoints.MOBILE}>
					<ClientsTopBar onCreateClientButtonClick={onRedirectToCreateClientPage} onFiltersButtonClick={toggleFiltersDrawerInterface} />

					<Spacer height="31px" />

					<FilterWrapper className={styles.filtersWrapper}>
						<div className={styles.filtersGroup}>
							<SegmentDropDownFilter multiple listOfFilters={segments} className={styles.segments} />
							<FastGroupFilters queryKey="segment[]" listOfFilters={CLIENTS_FAST_FILTERS} className={styles.fastFilters} />

							<MediaQuery minWidth={breakPoints.MOBILE}>
								<PerPageSizeSelector label="Показувати по:" />
							</MediaQuery>

							<SettingButton onClick={toggleVisibilityInterface}>
								<ColumnsSettingsMenu
									open={visibilityInterface.isOn}
									onClose={toggleVisibilityInterface}
									columns={columns}
									visibilityModel={visibility.visibilityModel}
									setColumns={visibility.setVisibilityModel}
								/>
							</SettingButton>
						</div>
					</FilterWrapper>
				</MediaQuery>

				{isEmpty && <EmptyTable text="Жодного клієнта не знайдено" />}

				<Suspense>
					{!isEmpty && (
						<div className={styles.tableContainer}>
							<Table
								allData={clients}
								visibilityModel={visibility.visibilityModel}
								pinningModel={pinning.pinningModel}
								onPinningModelChange={pinning.setPinningModel}
								columns={columns}
								onClickRow={onClientClick}
							/>

							{pagesCount > 1 && <Pagination pagesCount={pagesCount} page={page} />}
						</div>
					)}
				</Suspense>

				<SearchParamsController>
					{filtersDrawerInterface.isOn && (
						<SearchParamsControllerAdapter
							render={(adapterProps) => {
								return (
									<SharedFilterDrawer
										openDrawer
										searchResultsCount={totalClientsCount}
										onClose={toggleFiltersDrawerInterface}
										type="clients"
										{...adapterProps}
									>
										<ByClientFilter queryKey="client[]" title="Контрагент" />
										<ByResponsibleFilter queryKey="manager[]" title="Менеджер" options={managers} />
										<BySegmentFilter queryKey="segment[]" options={segments} />
									</SharedFilterDrawer>
								);
							}}
						/>
					)}
				</SearchParamsController>
			</div>

			<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
				<div className="safe-area-bottom" />
				<div className={styles.bottomPanel}>
					<Button variant="default" text="Фільтрувати" icon={<clientsIcons.Funnel />} className={styles.filterBtn} />
					<Button
						variant="default"
						text="Створити клієнта"
						icon={<clientsIcons.Plus />}
						className={styles.addClientBtn}
						onClick={onRedirectToCreateClientPage}
					/>
				</div>
			</MediaQuery>

			{isFetchingClients && <Spinner />}
		</>
	);
};

export default Clients;
