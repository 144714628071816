export const isObject = <TValue>(value: unknown): value is Record<string, unknown> & TValue =>
	typeof value === 'object' && value !== null && !Array.isArray(value);

export const isFunction = (value: unknown): value is (...arg: AnyArg[]) => AnyArg => typeof value === 'function';

export const isNumber = (value: unknown): value is number => typeof value === 'number';
export const isString = (value: unknown): value is string => typeof value === 'string';
export const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean';

interface AbortError {
	name: string;
}
export const isRequestAborted = (error: unknown): error is AbortError => {
	return isObject(error) && 'name' in error && error.name === 'AbortError';
};

export const isURLSearchParams = (value: unknown): value is URLSearchParams => {
	return value instanceof URLSearchParams;
};

export const isPromise = <TData>(p: unknown): p is Promise<TData> => {
	return (p && Object.prototype.toString.call(p) === '[object Promise]') || p instanceof Promise;
};
