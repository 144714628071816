import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { type Whoami, HasUserSchema } from 'models/auth';

import { authSliceApi } from './authSliceApi';
import type { AuthState } from './types';

const initialState = {
	user: null,
} as AuthState;

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setLoggedInUser: (state, action: PayloadAction<{ user: Whoami }>) => {
			state.user = action.payload.user;
		},
		clearLoggedInUser: (state) => {
			state.user = null;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(authSliceApi.endpoints.getMe.matchFulfilled, (state, action) => {
			const validation = HasUserSchema.safeParse(action.payload);

			if (validation.success) {
				state.user = action.payload;
			} else {
				state.user = null;
			}
		});
		builder.addMatcher(isAnyOf(authSliceApi.endpoints.logout.matchFulfilled, authSliceApi.endpoints.logout.matchRejected), (state) => {
			state.user = null;
		});
	},
});

export default authSlice.reducer;
export const authActions = authSlice.actions;
