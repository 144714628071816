import { ServiceIdSchema } from 'models/common/uuid';
import { PriceSchema } from 'models/price';
import { z } from 'zod';

const BaseCatalogueServiceSchema = z.object({
	id: ServiceIdSchema,
	code: z.string().optional(),
	description: z.string().optional().nullable(),
	title: z.string(),
});

const MetalCuttingSubServiceSchema = z.object({
	id: ServiceIdSchema,
	code: z.string().optional(),
	description: z.string().nullable(),
	prices: PriceSchema.array().optional(),
	title: z.string(),
});

const TransportationServiceSchema = BaseCatalogueServiceSchema.extend({
	prices: PriceSchema.array(),
});
const MoversServiceSchema = BaseCatalogueServiceSchema.extend({
	prices: PriceSchema.array(),
});
const PaintToningServiceSchema = BaseCatalogueServiceSchema.extend({
	prices: PriceSchema.array(),
});

const MetalCuttingServiceSubCategorySchema = z.object({
	id: ServiceIdSchema,
	title: z.string(),
	prices: PriceSchema.array().optional(),
	services: MetalCuttingSubServiceSchema.array().optional(),
});

export const MetalCuttingServiceSchema = z.object({
	id: ServiceIdSchema,
	title: z.string(),
	prices: PriceSchema.array().optional(),
	subCategories: MetalCuttingServiceSubCategorySchema.array(),
});

export const CatalogueServiceSchema = z.union([
	TransportationServiceSchema,
	MoversServiceSchema,
	PaintToningServiceSchema,
	MetalCuttingServiceSchema,
]);

export type CatalogueService = z.infer<typeof CatalogueServiceSchema>;
export type TransportationService = z.infer<typeof TransportationServiceSchema>;
export type MoversService = z.infer<typeof MoversServiceSchema>;
export type MetalCuttingService = z.infer<typeof MetalCuttingServiceSchema>;
export type PaintToningService = z.infer<typeof PaintToningServiceSchema>;
export type MetalCuttingSubService = z.infer<typeof MetalCuttingSubServiceSchema>;
