/* eslint-disable max-len */
import clsx from 'clsx';
import React from 'react';

import styles from '../styles.module.css';
import type { BaseErrorViewProps } from '../types';
import viewStyles from './styles.module.css';

type ResourceLoadFailureErrorViewProps = BaseErrorViewProps;

const ResourceLoadFailureErrorView: React.FC<ResourceLoadFailureErrorViewProps> = ({ className, children }) => {
	return (
		<div className={clsx(styles.layout, viewStyles.view, className)}>
			<div className={viewStyles.iconContainer}>
				<svg
					fill="var(--server-bg-color)"
					height="200px"
					width="200px"
					version="1.1"
					id="Capa_1"
					viewBox="-61.2 -61.2 734.40 734.40"
					xmlSpace="preserve"
					stroke="var(--server-bg-color)"
				>
					<g strokeWidth="0" />
					<g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.224" />
					<g>
						<g>
							<g>
								<path d="M562.041,0C391.347,0,220.653,0,49.959,0C22.368,0,0,22.368,0,49.959c0,19.568,0,39.134,0,58.702 c0,27.591,22.368,49.959,49.959,49.959c140.302,0,280.604,0,420.906,0c16.653,0,33.307,0,49.959,0c13.739,0,27.478,0,41.216,0 c27.591,0,49.959-22.368,49.959-49.959c0-19.568,0-39.134,0-58.702C612,22.368,589.632,0,562.041,0z M419.657,96.871 c-8.277,0-14.988-6.711-14.988-14.988c0-8.278,6.711-14.988,14.988-14.988s14.988,6.71,14.988,14.988 C434.645,90.16,427.934,96.871,419.657,96.871z M474.612,96.871c-8.277,0-14.988-6.711-14.988-14.988 c0-8.278,6.711-14.988,14.988-14.988s14.988,6.71,14.988,14.988C489.6,90.16,482.889,96.871,474.612,96.871z M545.804,113.108 c-17.245,0-31.224-13.98-31.224-31.224s13.98-31.224,31.224-31.224s31.224,13.98,31.224,31.224S563.049,113.108,545.804,113.108z" />
								<path d="M562.041,374.694c-170.694,0-341.387,0-512.082,0C22.368,374.694,0,397.062,0,424.653c0,19.568,0,39.134,0,58.702 c0,27.591,22.368,49.959,49.959,49.959c83.266,0,166.53,0,249.796,0v28.727c0,0.273,0.046,0.533,0.08,0.798 c-8.834,2.247-15.771,9.184-18.017,18.016c-0.264-0.034-0.525-0.08-0.798-0.08H98.669c-3.448,0-6.245,2.796-6.245,6.245 s2.796,6.245,6.245,6.245H281.02c0.273,0,0.533-0.046,0.798-0.08C284.568,603.994,294.336,612,306,612 c11.664,0,21.432-8.006,24.181-18.815c0.264,0.034,0.525,0.08,0.798,0.08h182.351c3.448,0,6.245-2.797,6.245-6.245 s-2.797-6.245-6.245-6.245H330.98c-0.274,0-0.533,0.046-0.798,0.08c-2.247-8.834-9.184-15.771-18.017-18.016 c0.034-0.264,0.08-0.525,0.08-0.798v-28.727c52.873,0,105.747,0,158.62,0h49.959c13.739,0,27.478,0,41.216,0 c27.591,0,49.959-22.368,49.959-49.959c0-19.568,0-39.134,0-58.702C612,397.062,589.632,374.694,562.041,374.694z M419.657,471.565c-8.277,0-14.988-6.711-14.988-14.988c0-8.278,6.711-14.988,14.988-14.988s14.988,6.71,14.988,14.988 C434.645,464.854,427.934,471.565,419.657,471.565z M474.612,471.565c-8.277,0-14.988-6.711-14.988-14.988 c0-8.278,6.711-14.988,14.988-14.988s14.988,6.71,14.988,14.988C489.6,464.854,482.889,471.565,474.612,471.565z M545.804,487.801 c-17.245,0-31.224-13.98-31.224-31.224s13.98-31.224,31.224-31.224s31.224,13.98,31.224,31.224S563.049,487.801,545.804,487.801z" />
								<path d="M562.041,187.347c-170.694,0-341.387,0-512.082,0C22.368,187.347,0,209.715,0,237.306c0,19.568,0,39.134,0,58.702 c0,27.591,22.368,49.959,49.959,49.959c140.302,0,280.604,0,420.906,0h49.959c13.739,0,27.478,0,41.216,0 c27.591,0,49.959-22.368,49.959-49.959c0-19.568,0-39.134,0-58.702C612,209.715,589.632,187.347,562.041,187.347z M419.657,284.218c-8.277,0-14.988-6.711-14.988-14.988c0-8.278,6.711-14.988,14.988-14.988s14.988,6.71,14.988,14.988 C434.645,277.507,427.934,284.218,419.657,284.218z M474.612,284.218c-8.277,0-14.988-6.711-14.988-14.988 c0-8.278,6.711-14.988,14.988-14.988s14.988,6.71,14.988,14.988C489.6,277.507,482.889,284.218,474.612,284.218z M545.804,300.455 c-17.245,0-31.224-13.98-31.224-31.224s13.98-31.225,31.224-31.225s31.224,13.98,31.224,31.225S563.049,300.455,545.804,300.455z" />
							</g>
						</g>
					</g>
				</svg>
				<div className={viewStyles.errorCircle} />
			</div>
			<h1>Помилка завантаження файлів додатку з сервера.</h1>
			<h2>Спробуйте перезавантажити сторінку.</h2>
			{children}
		</div>
	);
};

export default ResourceLoadFailureErrorView;
