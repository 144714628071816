import { SERVER_ENTITY_ID } from 'const';
import type { OrderCreate, OrderUpdate } from 'models/order';
import { toFixed } from 'utils/shared';

import type { ProductInternalModelState, ServiceInternalModelState, SuborderSnapshot } from './schema';
import { decodeId } from './transform';

const transformInternalProductStateModelToProductDTO = (product: ProductInternalModelState) => {
	if (product?.isBlank) {
		return null;
	}

	const isInt = product.unit?.isInt;
	const price = toFixed(product.price, { isInt: false, precise: 2, strictPrecision: true });
	const amount = toFixed(product.amount, { isInt, precise: 2, strictPrecision: true });

	return {
		productId: product.id,
		price: Number(price),
		amount: Number(amount),
	};
};
const transformInternalServiceStateModelToProductDTO = (service: ServiceInternalModelState) => {
	if (service.isMetalCuttingService) {
		return {
			serviceId: service.category.id,
			price: Number(service.price),
			amount: Number(service.amount),
		};
	}
	return {
		serviceId: decodeId(service.id),
		price: Number(service.price),
		amount: Number(service.amount),
	};
};

export const transformOrderToCreateOrderDTO = (order: SuborderSnapshot): OrderCreate => {
	return {
		note: order.note ?? '',
		clientId: order.client?.value,
		contractId: order.contract.value,
		responsibleId: order.responsible.value,
		organizationId: order.organization.value,
		stockId: order.stock.value,
		products: Object.values(order.products ?? {})
			.map(transformInternalProductStateModelToProductDTO)
			.filter(Boolean),
		services: Object.values(order.services ?? {}).map(transformInternalServiceStateModelToProductDTO),
		typePriceId: SERVER_ENTITY_ID.ProductDYIPriceType, // !!TEMP
		isPaid: Number(order.isPaid),
		isWithoutPayment: Number(order.isWithoutPayment),
		...(order.parentId && { parentId: order.parentId }),
	};
};

export const transformOrderToUpdateOrderDTO = (order: SuborderSnapshot): OrderUpdate => {
	return {
		id: order.id,
		note: order.note ?? '',
		clientId: order.client?.value,
		contractId: order.contract.value,
		responsibleId: order.responsible.value,
		organizationId: order.organization.value,
		stockId: order.stock.value,
		parentId: order.parentId ?? null,
		products: Object.values(order.products ?? {})
			.map(transformInternalProductStateModelToProductDTO)
			.filter(Boolean),
		services: Object.values(order.services ?? {}).map(transformInternalServiceStateModelToProductDTO),
		typePriceId: SERVER_ENTITY_ID.ProductDYIPriceType, // !!TEMP
		isReserved: Number(order.isReserved),
		isPaid: Number(order.isPaid),
		isWithoutPayment: Number(order.isWithoutPayment),
		...(order.parentId && { parentId: order.parentId }),
	};
};
