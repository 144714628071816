import Dexie from 'dexie';

import { configureDbPrimaryKeys, getDbName } from './config';
import type { DatabaseSchema } from './types';

class Database extends Dexie {
	products: DatabaseSchema['products'];
	productCategories: DatabaseSchema['productCategories'];
	productBrands: DatabaseSchema['productBrands'];
	fullTextSearchInProducts: DatabaseSchema['fullTextSearchInProducts'];

	orders: DatabaseSchema['orders'];
	ordersFiltersData: DatabaseSchema['ordersFiltersData'];

	clients: DatabaseSchema['clients'];
	clientsFiltersData: DatabaseSchema['clientsFiltersData'];
	fullTextSearchInClients: DatabaseSchema['fullTextSearchInClients'];

	contracts: DatabaseSchema['contracts'];
	segments: DatabaseSchema['segments'];
	stocks: DatabaseSchema['stocks'];
	organizations: DatabaseSchema['organizations'];
	createClientColloquialData: DatabaseSchema['createClientColloquialData'];

	filterTemplate: DatabaseSchema['filterTemplate'];
	services: DatabaseSchema['services'];
	auth: DatabaseSchema['auth'];
	postponedRequests: DatabaseSchema['postponedRequests'];
	employees: DatabaseSchema['employees'];
	offlineOrderAppNumber: DatabaseSchema['offlineOrderAppNumber'];

	constructor() {
		super(getDbName());

		this.version(1).stores(configureDbPrimaryKeys());
	}

	// searchByTextTokens = ({ table, tokens, where }: { tokens: string[]; table: 'products' | 'clients'; where: string }) => {
	// 	return db.transaction('readonly', db[table], function* () {
	// 		// Parallel search for all tokens - just select resulting primary keys
	// 		const results = yield Dexie.Promise.all(tokens.map((prefix) => db[table].where(where).equalsIgnoreCase(prefix).primaryKeys()));

	// 		const reduced = db.findIntersections(results as IndexableType[][]);

	// 		return yield db[table].where(':id').anyOf(reduced).toArray();
	// 	});
	// };

	// findIntersections = <TData>(data: TData[][]) => {
	// 	if (!data || !data?.length) return [];

	// 	const result = data.reduce((prevRecords: readonly TData[], candidates: readonly TData[]) => {
	// 		const intersections = new Set(prevRecords);

	// 		return candidates.filter((candidate: TData) => intersections.has(candidate));
	// 	});

	// 	return result;
	// };
}

export const db = new Database();
export type DB = typeof db;
