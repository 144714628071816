import { createListenerMiddleware } from '@reduxjs/toolkit';
import { db } from 'lib/database';
import { createAuthServiceFactory } from 'lib/service-worker/services/auth/auth.factory';
import { DexieSwitchModeRepositoryAdapter } from 'lib/service-worker/services/switch-mode/adapters/dexie-repository.adapter';
import type { RootState } from 'store';
import { authActions } from 'store/reducers/auth';
import { logger } from 'utils/logger';

const switchModeService = new DexieSwitchModeRepositoryAdapter(db);
const authService = createAuthServiceFactory({ db });

const logoutListenerMiddleware = createListenerMiddleware();
const loginListenerMiddleware = createListenerMiddleware();

const isOfflineMode = (store: RootState) => store?.app?.mode === 'offline';

loginListenerMiddleware.startListening({
	actionCreator: authActions.setLoggedInUser,
	effect: async ({ payload }, listenerApi) => {
		if (isOfflineMode(listenerApi.getState() as RootState)) return;

		listenerApi.cancelActiveListeners();

		const prevUser = await authService.whoami();
		const prevUserId = prevUser?.['1c_uuid'];
		const currentUserId = payload.user?.['1c_uuid'];

		if (prevUserId === currentUserId) return;

		try {
			await switchModeService.clear();
			await switchModeService.writeWhoAmI(payload.user);
		} catch (error) {
			logger.info('Помилка запису користувача до офлайн бази даних', error);
		}
	},
});

export { logoutListenerMiddleware, loginListenerMiddleware };
