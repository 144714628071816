import { z } from 'zod';

const SavePriceSchema = z.object({
	id: z.string(),
	code: z.string(),
	name: z.string(),
	units: z.string(),
	price: z.number(),
	img: z.string().nullable(),
});

export type SavePrice = z.infer<typeof SavePriceSchema>;
