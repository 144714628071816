import type { Employee, EmployeePreview, ServerSideEmployeeOption } from 'models/employee';
import type { OfflineEmployeeModel } from 'models/offline';

export const transformEmployeeToOfflineEmployeeModel = (employee: Employee): OfflineEmployeeModel => {
	return {
		...employee,
		ikEmployeeName: employee.name ?? '',
	};
};

export const transformOfflineEmployeeModelToEmployee = (model: OfflineEmployeeModel): Employee => {
	const employee = {} as Employee;

	for (const property in model) {
		if (!Object.hasOwn(model, property)) continue;

		if (property.startsWith('ik')) continue;

		employee[property] = model[property];
	}

	return employee;
};

export const transformEmployeeToPreviewEmployee = (employee: Employee): EmployeePreview => {
	return {
		'1c_uuid': employee?.['1c_uuid'] ?? '',
		avatar: employee.avatar,
		email: employee.email,
		id: employee.id,
		name: employee.name,
		phones: employee.phones ?? [],
		position: employee.position,
		role: employee.role,
	};
};

export const transformOfflineEmployeeModelToEmployeeOption = (model: OfflineEmployeeModel): ServerSideEmployeeOption => {
	return {
		'1c_uuid': model?.['1c_uuid'] ?? '',
		id: model.id,
		name: model.name,
	};
};
