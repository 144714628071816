import { useCallback, useMemo } from 'react';

import type { ProductInternalModelState } from '../OrderController';

const colorPaletteMap = {
	Мінімальна_ОД: '#f79009',
	Мінімальна_ТД: 'var(--gray-500)',
	Оптова: 'var(--gray-500)',
	Роздріб_DIY: 'var(--gray-500)',
	Вхід: '#C01048',
};

export const usePriceColor = (product: ProductInternalModelState) => {
	const types = useMemo(() => {
		if (!product?.prices) return new Map();

		return new Map(
			product.prices.map((priceEntity) => [
				priceEntity?.typePrice?.title,
				{
					colorCode: colorPaletteMap[priceEntity?.typePrice?.title] || 'var(--gray-500)',
					value: priceEntity.price,
				},
			]),
		);
	}, [product]);

	const getPriceColor = useCallback(
		(value: number): string => {
			const entryPrice = types.get('Вхід')?.value;
			const minimumODPrice = types.get('Мінімальна_ОД')?.value;

			if (entryPrice !== undefined && Number(value) < entryPrice) {
				return colorPaletteMap['Вхід'];
			}
			if (minimumODPrice !== undefined && Number(value) < minimumODPrice) {
				return colorPaletteMap['Мінімальна_ОД'];
			}

			return 'var(--gray-500)';
		},
		[types],
	);

	const getDeviationColor = useCallback((value: number) => {
		if (Number(value) >= 0) {
			return 'var(--gray-500)';
		}

		return '#C01048';
	}, []);

	return { getPriceColor, getDeviationColor };
};
