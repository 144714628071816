import { SelectOption } from 'components/Select/types';
import { CLIENT_PHONE_ASSOCIATED_OPTION_LIST, PRICES_FILTERS, PRICES_TYPE_PRICE } from 'const';
import { store } from 'store';
import { clientsSliceApi } from 'store/reducers/clients/clientsSliceApi';
export const loadPhoneAssociatedMessengersOptions = () => CLIENT_PHONE_ASSOCIATED_OPTION_LIST;

export const loadPricesFilterOptions = (): Promise<SelectOption[]> =>
	new Promise((res) => {
		setTimeout(() => {
			res(PRICES_FILTERS);
		}, 200);
	});

export const loadTypeOfPrices = (): Promise<SelectOption[]> =>
	new Promise((res) => {
		setTimeout(() => {
			res(PRICES_TYPE_PRICE);
		}, 200);
	});

export const loadContractTypesOptionList = async (): Promise<SelectOption[]> => {
	const promise = store.dispatch(clientsSliceApi.endpoints.getAllContractList.initiate());
	const { data } = await promise;

	return data;
};
export const loadActivitiesOptionList = async (): Promise<SelectOption[]> => {
	const promise = store.dispatch(clientsSliceApi.endpoints.getCreateClientColloquialData.initiate());
	const { data } = await promise;
	const options = data?.activities?.map((activity) => ({ label: activity.title, value: activity.id })) as SelectOption[];

	return options;
};
