import type { DB } from 'lib/database';
import type { Whoami } from 'models/auth';

import { AuthServiceRepository } from '../usecase/auth.usecase';

export class DexieAuthServiceRepositoryAdapter implements AuthServiceRepository {
	constructor(private db: DB) {}

	async whoami(): Promise<Whoami> {
		const result = await this.db.auth.toArray();
		const [me] = result || [];

		return me;
	}
}
