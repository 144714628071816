import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import Button from 'components/Button';
import FormField from 'components/FormComponents/FormField';
import PasswordInput from 'components/PasswordInput';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { passwordField, passwordMinLength } from 'validation/fields';
import { sharedErrorMessages } from 'validation/messages/common';
import { z } from 'zod';

import styles from './styles.module.css';

const RestorePasswordSchema = z
	.object({
		password: passwordField,
		confirm: passwordField,
	})
	.refine((ctx) => ctx.password === ctx.confirm, {
		message: sharedErrorMessages.password.notMatch,
		path: ['confirm'],
	});

type RestorePasswordFrom = z.infer<typeof RestorePasswordSchema>;

interface NewPasswordFormProps {
	onSubmit: (values: RestorePasswordFrom) => void;
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ onSubmit }) => {
	const form = useForm<RestorePasswordFrom>({
		resolver: zodResolver(RestorePasswordSchema),
		defaultValues: {
			confirm: '',
			password: '',
		},
	});

	const formTitle = 'Відновлення пароля';

	return (
		<FormProvider {...form}>
			<form className={clsx(styles.authLinkInvitationForm)} onSubmit={form.handleSubmit(onSubmit)}>
				<h1 className={styles.caption}>{formTitle}</h1>

				<div className={styles.fields}>
					<FormField
						label="Пароль"
						name="password"
						component={PasswordInput}
						hint={`Довжина паролю має бути не менше ${passwordMinLength}-ти символів`}
					/>

					<FormField label="Підтвердіть пароль" name="confirm" component={PasswordInput} />
				</div>

				<Button variant="default" text="Змінити пароль" type="submit" className={styles.submitButton} />
			</form>
		</FormProvider>
	);
};

export default NewPasswordForm;
