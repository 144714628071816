export const transformInToFormDataObject = (data: Record<string, AnyArg>) => {
	const formData = new FormData();
	for (const key in data) {
		if (Array.isArray(data[key])) {
			data[key].forEach((obj, index) => {
				const keyList = Object.keys(obj);
				keyList.forEach((keyItem) => {
					const keyName = [key, '[', index, ']', '[', keyItem, ']'].join('');
					if (obj[keyItem] instanceof File || obj[keyItem] instanceof Blob) {
						formData.append(keyName, obj[keyItem]);
					} else {
						formData.append(keyName, obj[keyItem]);
					}
				});
			});
		} else if (typeof data[key] === 'object' && !(data[key] instanceof File || data[key] instanceof Blob)) {
			// eslint-disable-next-line guard-for-in
			for (const innerKey in data[key]) {
				formData.append(`${key}.${innerKey}`, data[key][innerKey]);
			}
		} else {
			formData.append(key, data[key]);
		}
	}
	return formData;
};
