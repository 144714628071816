import clsx from 'clsx';
import Button from 'components/Button';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { getErrorViewByException } from 'utils/errors/error-to-view';
import { createErrorFactory } from 'utils/errors/errors';
import { logger } from 'utils/logger';

import styles from './styles.module.css';

const ResetButton = ({ onClick, text, className }: { onClick: VoidCallback; text: string; className?: string }) => {
	return <Button variant="default" onClick={onClick} text={text} className={clsx(styles.resetButton, className)} />;
};

function fallbackRender({ error, resetErrorBoundary }) {
	const exception = createErrorFactory(error);
	const View = getErrorViewByException(exception);

	const handlePageRefresh = () => {
		window.location.reload();
	};

	return (
		<View>
			<div className={styles.buttons}>
				<ResetButton onClick={resetErrorBoundary} text="Скинути помилку" className={styles.resetError} />
				<ResetButton onClick={handlePageRefresh} text="Перезавантажити сторінку" />
			</div>
		</View>
	);
}

const onError = (err: unknown) => {
	const exception = createErrorFactory(err);
	logger.error(exception.exception.toJSON());
};

const AppErrorBoundary = ({ children }: React.PropsWithChildren) => {
	return (
		<ErrorBoundary fallbackRender={fallbackRender} onError={onError}>
			{children}
		</ErrorBoundary>
	);
};

export default AppErrorBoundary;
