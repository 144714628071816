import { HeaderCellContent, RegularCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { visibilityColumnsIcons } from 'pages/Order/lib/icons';
import React, { ReactNode } from 'react';

export type CreatePriceTypeColumnProps = {
	header: ReactNode;
	cell: ReactNode;
	accessor: string;
	color: string;
};

interface PriceInfo {
	typePrice: {
		id: string;
		title: string;
	};
	price: string;
	dateBegin: string;
}

const searchPriceForTable = (prices: PriceInfo[], id: string) => {
	const priceInfo = prices.find((price) => price.typePrice.id === id);
	return priceInfo ? priceInfo.price : '-';
};

export const createPriceTypeColumn = ({ header, accessor, color }: CreatePriceTypeColumnProps) => {
	const pickPriceId = () => {
		if (accessor === 'Вхід') {
			return '95d8c530-6303-11e3-8680-c52dd1806f02';
		} else if (accessor === 'Мін.') {
			return '82dd3937-2316-11ea-80d5-8107dcf40211';
		} else if (accessor === 'Опт.') {
			return '2d8b7e50-6709-11e3-b37c-d8698ef02892';
		}
	};

	const priceColumn = {
		// eslint-disable-next-line react/prop-types
		Header: ({ isAdjustable }) => {
			if (isAdjustable) return header;

			return (
				<HeaderCell>
					<HeaderCellContent color={color}>{header}</HeaderCellContent>
				</HeaderCell>
			);
		},
		accessor,
		width: 56,
		// eslint-disable-next-line react/prop-types
		Cell: ({ row }) => {
			const priceId = pickPriceId();
			// eslint-disable-next-line react/prop-types
			const price = searchPriceForTable(row.original.prices, priceId);

			return (
				<RegularCell justify="center" width="100%">
					<RegularCellContent color={color}>{price}</RegularCellContent>
				</RegularCell>
			);
		},
		mobile: false,
		icon: <visibilityColumnsIcons.Uuid />,
		hidden: false,
	};

	return priceColumn;
};
