import './styles.css';

import clsx from 'clsx';
import Button from 'components/Button';
import Checkbox from 'components/ColorfulSelect/Checkbox';
import Modal from 'components/Modal';
import ModalBody from 'components/Modal/ModalBody';
import ModalControls from 'components/Modal/ModalControls';
import ModalHeader from 'components/Modal/ModalHeader';
import Spinner from 'components/Spinner';
import { useAppDispatch } from 'hooks/redux';
import { useAppMode } from 'hooks/useAppMode';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { useSwitchModeSW } from 'hooks/useSwitchModeSW';
import React, { Suspense, useEffect, useState } from 'react';
import { ReactComponent as OfflineModeIcon } from 'static/images/wifi-off.svg';
import { ReactComponent as OnlineModeIcon } from 'static/images/wifi-on.svg';
import { resetGlobalApiState } from 'store/utils/resetGlobalApiState';

import { ErrorModeChangeStatus, PendingModeChangeStatus, ProcessingModeChangeStatus, SuccessModeChangeStatus } from './components/ModeChangeStatus';

type ClassNameOnlyProps = {
	className?: string;
};

type IconOnlyProps = {
	iconOnly?: boolean;
	className?: string;
};

type AsModalProps = {
	asModal?: boolean;
	open: boolean;
	onClose: VoidCallback;
	className?: string;
};

type ModalSwitchProps = ClassNameOnlyProps | IconOnlyProps | AsModalProps;

function isClassNameOnly(props: ModalSwitchProps): props is ClassNameOnlyProps {
	return !('iconOnly' in props) && !('asModal' in props) && !('open' in props);
}

function isIconOnly(props: ModalSwitchProps): props is IconOnlyProps {
	return 'iconOnly' in props && !('asModal' in props) && !('open' in props);
}

function isAsModal(props: ModalSwitchProps): props is AsModalProps {
	return 'asModal' in props && 'open' in props;
}

const ModeSwitch: React.FC<ModalSwitchProps> = (props) => {
	const dispatch = useAppDispatch();
	const modal = useBoolean();
	const { isNetworkConnection } = useAppMode();
	const [needFetchData, setNeedFetchData] = useState(isNetworkConnection);

	const { mode, isProcessing, isOfflineMode, isIdle, isOnlineMode, isSuccess, isError, switchedAt, switchTo, reset } = useSwitchModeSW();

	useEffect(() => {
		if (isSuccess) {
			resetGlobalApiState(dispatch);
		}
	}, [isSuccess]);

	const handleModeSwitch = () => {
		switchTo(isOfflineMode ? 'online' : 'offline', needFetchData);
	};
	const handleModalOpen = useStopPropagationCallback(modal.open);
	const onClickStopPropagation = useStopPropagationCallback();

	const isAsButtonUsage = isClassNameOnly(props);
	const isAsIconButtonUsage = isIconOnly(props);

	let isModalOpened = modal.isOn;
	let onClose = modal.close;

	const closeModal = () => {
		if (isProcessing) return;

		onClose();
		reset();
	};

	if (isAsModal(props)) {
		isModalOpened = props.open;
		onClose = props.onClose;
	}

	const { className } = props;
	const isDataFetchForbidden = !isNetworkConnection;
	const icon = isOnlineMode ? <OnlineModeIcon /> : <OfflineModeIcon />;

	return (
		<>
			{isAsButtonUsage && (
				<Button
					onClick={handleModalOpen}
					variant="default"
					text={(<>{icon} Змінити режим</>) as unknown as string}
					className={clsx(className)}
				/>
			)}
			{isAsIconButtonUsage && (
				<Button
					disabled={isProcessing}
					variant="default"
					text={icon as unknown as string}
					onClick={handleModalOpen}
					className={clsx(className)}
				/>
			)}

			<Suspense fallback={<Spinner />}>
				{isModalOpened && (
					<Modal open onClose={closeModal} className="mode-switch-modal">
						<ModalHeader title="Зміна режиму" onXCloseClick={closeModal} className={clsx({ 'switch-mode-processing': isProcessing })} />

						<ModalBody className="switch-mode-modal-body">
							{isIdle && <PendingModeChangeStatus date={switchedAt} mode={mode} />}

							{isProcessing && <ProcessingModeChangeStatus />}

							{isSuccess && <SuccessModeChangeStatus mode={mode} />}

							{isError && <ErrorModeChangeStatus />}
						</ModalBody>

						{isSuccess && <Button className="switch-mode-control-button" variant="default" text="Зрозуміло" onClick={closeModal} />}

						{isError && (
							<ModalControls
								onCancel={closeModal}
								onSubmit={handleModeSwitch}
								className={clsx('modal-controls', { 'switch-mode-processing': isProcessing })}
								submitButtonText="Ще раз"
								cancelButtonText="Закрити"
							/>
						)}

						{!isDataFetchForbidden && isOnlineMode && isIdle && (
							<Checkbox
								onClick={onClickStopPropagation}
								label="Завантажити дані"
								checked={needFetchData}
								onChange={() => setNeedFetchData(!needFetchData)}
							/>
						)}

						{(isIdle || isProcessing) && (
							<ModalControls
								onCancel={closeModal}
								onSubmit={handleModeSwitch}
								className={clsx('modal-controls', { 'switch-mode-processing': isProcessing })}
								submitButtonText={isProcessing ? 'Обробка...' : isOfflineMode ? 'В онлайн' : 'В офлайн'}
							/>
						)}
					</Modal>
				)}
			</Suspense>
		</>
	);
};

export default ModeSwitch;
