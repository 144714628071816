import { z } from 'zod';

const UuidSchema = z.string();

export const ServiceIdSchema = UuidSchema;
export const OrderIdSchema = UuidSchema;
export const ClientIdSchema = UuidSchema;
export const ContractIdSchema = UuidSchema;
export const ResponsibleIdSchema = UuidSchema;
export const OrganizationIdSchema = UuidSchema;
export const ProductUnitIdSchema = UuidSchema;
export const ProductBrandIdSchema = UuidSchema;
export const ProductIdSchema = UuidSchema;
export const StockIdSchema = UuidSchema;
export const ServiceBrandIdSchema = UuidSchema;
export const ServiceCategoryIdSchema = UuidSchema;
export const TransactionEntityIdSchema = UuidSchema;
export const TypePriceIdSchema = UuidSchema;
export const SegmentIdSchema = UuidSchema;
export const FileIdSchema = UuidSchema;
export const BusinessOfferIdSchema = UuidSchema;
export const ColorantIdSchema = UuidSchema;
export const ClientManagerIdSchema = z.number();
export const EmployeeIdSchema = z.number();
export const EmployeeOrganizationIdSchema = z.number().or(z.string());

export type EmployeeId = z.infer<typeof EmployeeIdSchema>;
