import { createApi } from '@reduxjs/toolkit/query/react';
import type { SelectOption } from 'components/Select/types';
import { API_ENDPOINT } from 'const';
import { PRICE_CHANGE_OPTIONS } from 'mock/priceChange';
import type { OriginalPaginatedRequestResult, PaginatedRequestResult, RequestResult } from 'models/api';
import { transformToPaginatedRequestResult } from 'models/api/transform';
import type { ClientOrder, ClientOrdersRequestPayload } from 'models/client/client-order';
import { ClientOrderSchema } from 'models/client/client-order';
import type { Option } from 'models/common/options';
import type { PriceChangeDTO } from 'models/IPriceChange';
import { Order, OrderCreate, OrderFilters, OrderFiltersSchema, OrderPreview, OrderPreviewSchema, OrderSchema, OrderUpdate } from 'models/order';
import { NormalizedProductSearchResult, ProductSearchResult } from 'models/product';
import { ProductBrand } from 'models/product/brand';
import { CatalogueProduct, CatalogueProductSchema } from 'models/product/catalogue-product';
import type { ProductCategory } from 'models/product/category';
import { LastPriceRequest, LastPriceResult, LastPriceResultSchema } from 'models/product/last-price';
import { CatalogueService, CatalogueServiceSchema } from 'models/service/catalogue-service';
import { orderQueryKeys } from 'services/queryKeys';
import { baseQuery } from 'store/config/base-query';
import { logger } from 'utils/logger';

export const ordersSliceApi = createApi({
	reducerPath: 'orders',
	baseQuery: baseQuery(),
	tagTypes: [orderQueryKeys.orders(), orderQueryKeys.services()],
	endpoints: (builder) => ({
		getOrders: builder.query<PaginatedRequestResult<OrderPreview[]>, string>({
			query: (queryParams) => ({
				url: API_ENDPOINT.allOrders(),
				method: 'GET',
				params: queryParams,
			}),
			keepUnusedDataFor: 0,
			transformResponse: (data: OriginalPaginatedRequestResult<OrderPreview[]>) => {
				const result = transformToPaginatedRequestResult<OrderPreview[]>(data);
				const validation = OrderPreviewSchema.array().safeParse(result.data);

				const response = {
					data: validation.data,
					page: result.page,
					pagesCount: result.pagesCount,
					total: result.total,
				};

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'ordersSliceApi.getOrders' });
					response.data = result.data;
				}

				return response;
			},
			providesTags: (result) => [orderQueryKeys.ordersOnly(), ...result?.data.map(({ id }) => orderQueryKeys.order(id))],
		}),
		getClientOrders: builder.query<PaginatedRequestResult<ClientOrder[]>, ClientOrdersRequestPayload>({
			query: ({ id, queryParams }) => ({
				url: API_ENDPOINT.clientOrders(id),
				method: 'GET',
				params: queryParams,
			}),
			transformResponse: (data: OriginalPaginatedRequestResult<ClientOrder[]>) => {
				const result = transformToPaginatedRequestResult<ClientOrder[]>(data);
				const validation = ClientOrderSchema.array().safeParse(result.data);

				const response = {
					data: validation.data,
					page: result.page,
					pagesCount: result.pagesCount,
				};

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'ordersSliceApi.getClientOrders' });
					response.data = result.data;
				}

				return response;
			},
			providesTags: (result, _, { id }) =>
				result?.data
					? [orderQueryKeys.orders(), orderQueryKeys.order(id), ...result?.data.map((orders) => orderQueryKeys.order(orders.id))]
					: [],
		}),
		/**
		 * @deprecated - need fetch data from api
		 */
		getPriceChangeOptions: builder.query<Option[], void>({
			queryFn: () => {
				const options = PRICE_CHANGE_OPTIONS.map(({ title, ...option }) => {
					return {
						...option,
						label: title,
						value: title,
					};
				}) as Option[];

				return {
					data: options,
				};
			},
		}),
		/**
		 * @deprecated - need fetch data from api
		 */
		changeProductsPrice: builder.mutation<void, PriceChangeDTO>({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			queryFn: (dto) =>
				new Promise((res) => {
					setTimeout(() => {
						res({ data: null });
					}, 2500);
				}),
			invalidatesTags: (_, __, order) => [orderQueryKeys.ordersOnly(), orderQueryKeys.order(order.orderId)],
		}),
		createOrder: builder.mutation<Order, OrderCreate>({
			query: (dto) => ({ url: API_ENDPOINT.allOrders(), method: 'POST', data: dto }),
			transformResponse: (response: RequestResult<Order>) => {
				const validation = OrderSchema.safeParse(response.data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'ordersSliceApi.createOrder' });
					return response.data;
				}

				return validation.data;
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
		}),
		getOrderById: builder.query<Order, string>({
			query: (id) => ({ url: API_ENDPOINT.orderById(id), method: 'GET' }),
			transformResponse: (response: RequestResult<Order>) => {
				const validation = OrderSchema.safeParse(response.data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'ordersSliceApi.getOrderById' });
				}

				const source = validation.data || response.data;

				source.subOrders.sort((orderA, orderB) => {
					const numA = parseInt(orderA?.number?.match(/\d+/)[0], 10);
					const numB = parseInt(orderB?.number?.match(/\d+/)[0], 10);
					return numA - numB;
				});

				return source;
			},
			providesTags: (result, __, id) => (result ? [orderQueryKeys.order(id)] : []),
		}),
		refreshOrderLockedStatus: builder.query<void, string>({
			query: (id) => ({ url: API_ENDPOINT.orderRefreshLockedStatus(id), method: 'GET' }),
		}),
		unlockOrderManually: builder.query<void, string>({
			query: (id) => ({ url: API_ENDPOINT.orderUnlockManually(id), method: 'GET' }),
		}),
		updateOrder: builder.mutation<Order, Partial<OrderUpdate>>({
			query: (dto) => ({ url: API_ENDPOINT.orderById(dto.id), method: 'PATCH', data: dto }),
			transformResponse: (response: RequestResult<Order>) => {
				const validation = OrderSchema.safeParse(response.data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'ordersSliceApi.updateOrder' });
					return response.data;
				}

				return validation.data;
			},
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
		}),
		updateSubOrder: builder.mutation({
			query: (dto) => {
				const { id, ...order } = dto;

				return { url: API_ENDPOINT.orderById(id), method: 'PATCH', data: order };
			},
			transformResponse: (response: RequestResult<Order>) => {
				const validation = OrderSchema.safeParse(response.data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'ordersSliceApi.updateOrder' });
					return response.data;
				}

				return validation.data;
			},
		}),
		updateOrderDate: builder.mutation<Order, Pick<OrderUpdate, 'id'>>({
			query: (dto) => ({ url: API_ENDPOINT.orderById(dto.id), method: 'PATCH', data: dto }),
			transformResponse: (response: RequestResult<Order>) => response.data,
		}),
		getBrandsFilters: builder.query<ProductBrand[], void>({
			query: () => ({ url: API_ENDPOINT.productsBrands(), method: 'GET' }),
		}),
		getCategoriesFilters: builder.query<ProductCategory[], string>({
			query: (queryParams) => ({ url: API_ENDPOINT.productsCategories(), method: 'GET', params: queryParams }),
		}),
		/**
		 * Due to error below we cannot use generics for builder.query<TData, TAgr>
		 * Type instantiation is excessively deep and possibly infinite.ts(2589)
		 */
		getCatalogueProducts: builder.query({
			query: (queryParams) => ({ url: API_ENDPOINT.allProducts(), method: 'GET', params: queryParams }),
			transformResponse: (data: OriginalPaginatedRequestResult<CatalogueProduct[]>) => {
				const result = transformToPaginatedRequestResult(data);
				const validation = CatalogueProductSchema.array().safeParse(result.data);

				const response = {
					data: validation.data,
					page: result.page,
					pagesCount: result.pagesCount,
				};

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'ordersSliceApi.getCatalogueProducts' });

					response.data = result.data as CatalogueProduct[];
				}

				return response;
			},

			providesTags: (result, __, id) => (result ? [orderQueryKeys.order(id)] : []),
		}),
		searchCatalogueProducts: builder.query<NormalizedProductSearchResult, string>({
			query: (queryParams) => ({ url: API_ENDPOINT.productsSearch(), method: 'GET', params: queryParams }),
			transformResponse: (response: ProductSearchResult) => {
				const result = Object.entries(response ?? {}).map(([rootCategoryName, searchResult]) => ({
					rootCategoryName,
					data: searchResult,
				}));

				return result as NormalizedProductSearchResult;
			},
		}),
		/**
		 * @deprecated remove
		 */
		getPriceTypeList: builder.query({
			query: () => ({ url: '/price-types' }),
			// !TEMP - don't need last price type
			transformResponse: (prices) => {
				if (Array.isArray(prices)) {
					return prices.slice(0, prices.length - 1);
				}

				return prices as [];
			},
		}),
		getServices: builder.query<Record<string, CatalogueService>, void>({
			query: () => ({ url: API_ENDPOINT.services(), method: 'GET' }),
			transformResponse: (data: Record<string, CatalogueService>) => {
				const validation = Object.entries(data).map(([key, service]) => ({
					data: CatalogueServiceSchema.safeParse(service),
					key,
				}));

				const isValid = validation.every((item) => item.data.success);

				if (!isValid) {
					const errors = validation.map((item) => item.data?.error?.errors);
					logger.error(errors, { location: 'ordersSliceApi.getServices' });

					return data;
				}
				const result = validation.reduce((acc, item) => ({ ...acc, [item.key]: item?.data.data }), {}) as Record<string, CatalogueService>;

				return result;
			},
			providesTags: [orderQueryKeys.orders(), orderQueryKeys.services()],
		}),
		getPaintCollectionOptionList: builder.query<SelectOption[] | string[], string>({
			// @ts-ignore
			queryFn: async () => {
				try {
					const options = [];

					return { data: options };
				} catch (error) {
					return { error };
				}
			},
		}),
		getPaintBaseToneOptionList: builder.query<SelectOption[] | string[], string>({
			// @ts-ignore
			queryFn: async () => {
				const options = Promise.resolve([] as SelectOption[]);

				return { data: options };
			},
		}),
		getOrdersFiltersData: builder.query<OrderFilters, string>({
			query: () => ({ url: API_ENDPOINT.orderFilters(), method: 'GET' }),
			transformResponse: (data: OrderFilters) => {
				const validation = OrderFiltersSchema.safeParse(data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'ordersSliceApi.getOrdersFiltersData' });
				}

				return validation.data || data;
			},
		}),
		getProductLastPrices: builder.mutation<Record<string, string>, LastPriceRequest>({
			query: (requestDto) => ({ url: API_ENDPOINT.productsLastPrice(), method: 'POST', data: requestDto }),
			transformResponse: (data: LastPriceResult[]) => {
				const validation = LastPriceResultSchema.array().safeParse(data);

				if (!validation.success) {
					logger.error(validation.error.errors, { location: 'ordersSliceApi.getProductLastPrices' });
				}

				const source = validation.success ? validation.data : data;
				const lastPrices = source.reduce((acc, price) => ({ ...acc, [price.productId]: price.lastPrice }), {}) as Record<string, string>;

				return lastPrices;
			},
		}),
		setOrderOnReserve: builder.mutation<Order, Pick<Order, 'id'>>({
			query: (dto) => ({ url: API_ENDPOINT.orderReserve(dto.id), method: 'POST', data: dto }),
		}),
		setOrderFromReserve: builder.mutation<Order, Pick<Order, 'id'>>({
			query: (dto) => ({ url: API_ENDPOINT.orderUnReserve(dto.id), method: 'POST', data: dto }),
		}),
		copyOrders: builder.mutation<void, string[]>({
			query: (dto) => ({ url: API_ENDPOINT.ordersCopy(), method: 'POST', data: dto }),
			transformResponse: () => undefined,
			invalidatesTags: () => [orderQueryKeys.ordersOnly()],
		}),
	}),
});

export const {
	useGetOrderByIdQuery,
	useCreateOrderMutation,
	useGetOrdersQuery,
	useUpdateOrderMutation,
	useGetCategoriesFiltersQuery,
	useGetBrandsFiltersQuery,
	useGetPriceTypeListQuery,
	useGetPriceChangeOptionsQuery,

	useGetCatalogueProductsQuery,
	useGetOrdersFiltersDataQuery,
	useGetClientOrdersQuery,
	useGetServicesQuery,
	useGetProductLastPricesMutation,

	useSetOrderOnReserveMutation,
	useSetOrderFromReserveMutation,
	useUpdateOrderDateMutation,
	useSearchCatalogueProductsQuery,
	useLazyGetCategoriesFiltersQuery,
	useCopyOrdersMutation,
	useRefreshOrderLockedStatusQuery,

	useLazyUnlockOrderManuallyQuery,
	useUpdateSubOrderMutation,
} = ordersSliceApi;
