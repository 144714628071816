import type { Collection, IndexableType, Table } from 'lib/database/types';
import type { PaginationSearchParams } from 'models/api';
import type { ClientContract } from 'models/client/client-contract';
import type { OfflineProductCategoryNode, OfflineProductCategoryParentNode } from 'models/offline';
import type { CatalogueProduct } from 'models/product/catalogue-product';
import type { Stock } from 'models/stock';
import { isPromise } from 'utils/type-guards';

export const createSuccessResponseWithData = (arg: unknown | Promise<unknown>) => {
	if (isPromise(arg)) {
		return arg.then(
			(data) =>
				new Response(JSON.stringify(data), {
					status: 200,
					headers: {
						'Content-Type': 'application/json',
					},
				}),
		);
	}

	return new Response(JSON.stringify(arg), {
		status: 200,
		headers: {
			'Content-Type': 'application/json',
		},
	});
};

export const calculatePagesCount = ({ total, perPage }: Pick<PaginationSearchParams, 'perPage'> & { total: number }) => {
	let resolvedPerPage = perPage;

	if (perPage <= 0) {
		resolvedPerPage = 1;
	}

	return Math.ceil(total / resolvedPerPage);
};

export const withPagination = ({ perPage, page }: PaginationSearchParams) => {
	const paginator = <TData>(collectionOrTable: Collection<TData, IndexableType> | Table<TData, IndexableType>) => {
		const res = collectionOrTable
			.offset((page - 1) * perPage)
			.limit(perPage)
			.toArray();

		return res;
	};

	return paginator;
};

export const extractProductCategoryIds = (category: OfflineProductCategoryNode): string[] => {
	const ids: string[] = [];

	let currentNode: OfflineProductCategoryParentNode | OfflineProductCategoryNode | null = category;

	while (currentNode) {
		ids.push(currentNode.id);

		if ('parent' in currentNode) {
			currentNode = currentNode.parent;
		} else {
			currentNode = null;
		}
	}

	return ids.reverse();
};

export const extractProductStockId = (stock: Stock) => stock.id;
export const extractStockIdsWhereProductIsAvailable = (product: CatalogueProduct): string[] => {
	return product.leftovers.filter((leftover) => Number(leftover.amount) > 0).map((leftover) => leftover.stock.id);
};

export const extractClientContractId = (contract: ClientContract): string => contract?.id ?? '';
export const extractClientContractIds = (contracts: ClientContract[]): string[] => contracts?.map(extractClientContractId) ?? [];

export const extractSearchParams =
	<TModel extends Record<string, string>>(keys: string[]) =>
	(searchParamsString: string): TModel => {
		const searchParams = new URLSearchParams(searchParamsString);
		const searchParamsEntries = Array.from(searchParams.entries());
		const filtered = searchParamsEntries.filter(([key]) => keys.includes(key));
		const paramsObj = Object.fromEntries(filtered);

		return paramsObj as TModel;
	};
