import { useRemoveDOMElement } from 'hooks/useRemoveDOMElement';
import React from 'react';

const PageContentSkeleton: React.FC = () => {
	useRemoveDOMElement({ selector: '#initial-loader', removeOnMount: true });

	return (
		<div style={{ padding: '16px', height: '100vh', display: 'flex', flexDirection: 'column' }}>
			<div style={{ width: '100%', height: '80px', marginBottom: '24px' }} className="skeleton" />
			<div style={{ width: '100%', height: '200px', marginBottom: '16px' }} className="skeleton" />

			<div style={{ width: '100%', height: 'calc(100vh - 260px - 100px - 16px * 2 - 8px)', marginTop: '12px' }}>
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
				<div style={{ width: '100%', height: '9%', marginBottom: '16px' }} className="skeleton" />
			</div>
		</div>
	);
};

export default PageContentSkeleton;
