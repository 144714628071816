import { biometricPassportIdRegex, regularPassportIdRegex } from 'validation/regex';

export const transformToMaxLength = (max: number) => (value: string) => {
	if (value?.length <= max) return value ?? '';

	return value?.slice(0, max) ?? '';
};

/**
 *
 * details see here: https://1cinfo.com.ua/Articles/Proverka_koda_po_EDRPOU.aspx
 */

export const validateRegularPassportID = (passportID: string): boolean => {
	return regularPassportIdRegex.test(passportID);
};

export const validateBiometricPassportID = (idNumber: string): boolean => {
	return biometricPassportIdRegex.test(idNumber);
};
