import { APP_MODE } from 'const';
import { MODE_SWITCH_MESSAGES } from 'lib/service-worker/messages.const';
import type { AppMode } from 'models/IApp';
import { useCallback, useEffect, useState } from 'react';

import { useAppMode } from './useAppMode';

export type ModeSwitchStatus = 'idle' | 'processing' | 'success' | 'error';

export const useSwitchModeSW = () => {
	const { mode, isOffline, isOnline, updatedAt, switchModeTo } = useAppMode();
	const [switchStatus, setSwitchStatus] = useState<ModeSwitchStatus>('idle');

	useEffect(() => {
		const handleMessage = async (event: MessageEvent) => {
			switch (event.data.type) {
				case MODE_SWITCH_MESSAGES.fromSW.isProcessing:
					setSwitchStatus('processing');
					break;
				case MODE_SWITCH_MESSAGES.fromSW.offlineModeOn:
					switchModeTo(APP_MODE.offline);
					setSwitchStatus('success');
					break;
				case MODE_SWITCH_MESSAGES.fromSW.onlineModeOn:
					switchModeTo(APP_MODE.online);
					setSwitchStatus('success');
					break;
				case MODE_SWITCH_MESSAGES.fromSW.modeSwitchFailed:
					setSwitchStatus('error');
					break;
				default:
					break;
			}
		};

		if ('navigator' in window && 'serviceWorker' in window.navigator) {
			window.navigator.serviceWorker.addEventListener('message', handleMessage);
		}

		return () => {
			if ('navigator' in window && 'serviceWorker' in window.navigator) {
				window.navigator.serviceWorker.removeEventListener('message', handleMessage);
			}
		};
	}, []);

	const reset = () => {
		setSwitchStatus('idle');
	};

	const switchTo = useCallback(
		(requestMode: AppMode, withDataFetch: boolean = true) => {
			if ('navigator' in window && 'serviceWorker' in window.navigator) {
				if (switchStatus === 'error') {
					setSwitchStatus('idle');
				}

				if (mode === APP_MODE.online && requestMode === APP_MODE.offline) {
					if (!withDataFetch) {
						switchModeTo(APP_MODE.offline);
						setSwitchStatus('success');
					} else {
						window.navigator.serviceWorker.controller?.postMessage({ type: MODE_SWITCH_MESSAGES.toSW.triggerOfflineModeSwitch });
					}
				} else if (mode === APP_MODE.offline && requestMode === APP_MODE.online) {
					window.navigator.serviceWorker.controller?.postMessage({ type: MODE_SWITCH_MESSAGES.toSW.triggerOnlineModeSwitch });
				}
			}
		},
		[mode, switchStatus],
	);
	const fetchOfflineDataInBackground = useCallback(() => {
		if ('navigator' in window && 'serviceWorker' in window.navigator) {
			window.navigator.serviceWorker.controller?.postMessage({ type: MODE_SWITCH_MESSAGES.toSW.triggerSyncDataInBackground });
		}
	}, []);

	const isProcessing = switchStatus === 'processing';
	const isSuccess = switchStatus === 'success';
	const isError = switchStatus === 'error';
	const isIdle = switchStatus === 'idle';

	return {
		isOfflineMode: isOffline,
		isOnlineMode: isOnline,
		switchedAt: updatedAt,
		isProcessing,
		isSuccess,
		switchTo,
		isError,
		isIdle,
		reset,
		mode,
		fetchOfflineDataInBackground,
	};
};
