import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from 'const';
import type { CreateFile, CreateFileDTO } from 'models/file';
import { filesQueryKeys } from 'services/queryKeys';
import { baseQuery } from 'store/config/base-query';
import { transformInToFormDataObject } from 'utils/api';

export const filesSliceApi = createApi({
	reducerPath: 'files',
	baseQuery: baseQuery(),
	tagTypes: [filesQueryKeys.files()],
	endpoints: (builder) => ({
		uploadFiles: builder.mutation<CreateFile, CreateFileDTO>({
			query: (dto) => {
				return {
					url: API_ENDPOINT.files(),
					data: transformInToFormDataObject(dto),
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};
			},
		}),
	}),
});

export const { useUploadFilesMutation } = filesSliceApi;
