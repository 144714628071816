import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from 'const';
import type { RequestResult } from 'models/api';
import type { CreateFilterTemplate, FilterTemplate, GetFilterTemplatePayload } from 'models/filter-template';
import { filterTemplatesQueryKeys } from 'services/queryKeys';
import { baseQuery } from 'store/config/base-query';
import { uuid } from 'utils/shared';

export const filterTemplatesSliceApi = createApi({
	reducerPath: 'filterTemplates',
	baseQuery: baseQuery(),
	tagTypes: [filterTemplatesQueryKeys.filterTemplates()],
	endpoints: (builder) => ({
		getFilterTemplate: builder.query<FilterTemplate[], GetFilterTemplatePayload>({
			query: ({ userId, type }) => ({ url: `${API_ENDPOINT.filterTemplates()}?userId=${userId}&type=${type}`, method: 'GET' }),
			providesTags: (result) =>
				result
					? [filterTemplatesQueryKeys.filterTemplates(), ...result.map((template) => filterTemplatesQueryKeys.filterTemplate(template.id))]
					: [],
		}),
		createFilterTemplate: builder.mutation<FilterTemplate, CreateFilterTemplate>({
			query: (dto) => ({ url: API_ENDPOINT.filterTemplates(), method: 'POST', data: dto }),
			transformResponse: (response: RequestResult<FilterTemplate>) => response.data,
			async onQueryStarted(dto, { dispatch, queryFulfilled }) {
				const cacheKey = { userId: dto.userId, type: dto.type };
				const tempId = uuid();

				const patchResult = dispatch(
					filterTemplatesSliceApi.util.updateQueryData('getFilterTemplate', cacheKey, (draft) => {
						draft.unshift({ ...dto, id: tempId });
					}),
				);

				try {
					const { data: createdTemplate } = await queryFulfilled;
					dispatch(
						filterTemplatesSliceApi.util.updateQueryData('getFilterTemplate', cacheKey, (draft) => {
							const index = draft.findIndex((template) => template.id === tempId);

							if (index !== -1) {
								const entity = draft[index];

								const res = Object.assign(entity, createdTemplate);
								draft[index] = res;
							}

							return draft;
						}),
					);
				} catch {
					patchResult.undo();
				}
			},
		}),
		deleteFilterTemplate: builder.mutation<void, GetFilterTemplatePayload & { templateId: string }>({
			query: ({ templateId }) => ({ url: API_ENDPOINT.filterTemplatesById(templateId), method: 'DELETE' }),
			transformResponse: () => undefined,
			async onQueryStarted(dto, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					filterTemplatesSliceApi.util.updateQueryData('getFilterTemplate', { userId: dto.userId, type: dto.type }, (draft) => {
						return draft.filter((template) => template.id !== dto.templateId);
					}),
				);

				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
	}),
});

export const { useGetFilterTemplateQuery, useCreateFilterTemplateMutation, useDeleteFilterTemplateMutation } = filterTemplatesSliceApi;
