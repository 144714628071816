import clsx from 'clsx';
import Modal from 'components/Modal';
import ModalBody from 'components/Modal/ModalBody';
import ModalHeader from 'components/Modal/ModalHeader';
import React, { useCallback, useState } from 'react';

import ColorfulBadge from '../ColorfulBadge';
import Checkbox from './Checkbox';
import styles from './styles.module.css';
import type { IProps } from './types';
import { getColorByIndex } from './utils';

const ColorfulSelect = <TValue extends Record<string, unknown>>({
	options,
	value,
	onSelect,
	className,
	popupHeaderTitle = 'Змінити сегмент',
	popupHeaderClassName,
	closeOnSelect = false,
	placeholder,
	disabled,
	getIsDisabled,
}: IProps<TValue>) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const resolvedSelectedOption = value;

	const index = options.findIndex(({ id }) => id === resolvedSelectedOption?.id);
	const color = getColorByIndex(index + 1);

	const closeModal = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	const handleOptionSelect = (option) => {
		onSelect?.(option);

		if (closeOnSelect) {
			closeModal();
		}
	};

	const openModal = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		setIsModalOpen(true);
	};

	const button =
		options.length === 0 ? (
			<ColorfulBadge {...color} withChevron text={resolvedSelectedOption?.title || placeholder} disabled={disabled} />
		) : (
			<button data-colorful-select type="button" onClick={openModal} className={clsx(styles.colorfulSelect, className)} disabled={disabled}>
				<ColorfulBadge {...color} withChevron text={resolvedSelectedOption?.title || placeholder} />
			</button>
		);

	return (
		<>
			{button}

			<Modal onClose={closeModal} open={isModalOpen} maxWidth="max-content">
				<ModalHeader title={popupHeaderTitle} onXCloseClick={closeModal} className={clsx(popupHeaderClassName)} />

				<ModalBody className={styles.modalBody}>
					<ul className={styles.list}>
						{options.map((option, idx) => {
							return (
								<li key={idx} className={styles.listItem}>
									<Checkbox
										checked={resolvedSelectedOption?.id === option?.id}
										{...(getIsDisabled && { disabled: getIsDisabled(option) })}
										onChange={() => {
											if (getIsDisabled?.(option)) return;

											handleOptionSelect(option);
										}}
									/>
									<ColorfulBadge {...getColorByIndex(idx + 1)} text={option.title} />
								</li>
							);
						})}
					</ul>
				</ModalBody>
			</Modal>
		</>
	);
};

export default ColorfulSelect;
