import { LoaderFunction } from 'react-router-dom';
import { store } from 'store';
import { usersSliceApi } from 'store/reducers/users/usersSliceApi';

export const fetchEmployees: LoaderFunction = async ({ request }) => {
	const url = new URL(request.url);
	const searchParams = url.searchParams;

	store.dispatch(usersSliceApi.endpoints.getEmployees.initiate(searchParams.toString())).unwrap();
	return null;
};

export const fetchEmployee: LoaderFunction = async ({ params }) => {
	const { id } = params;

	const promise = store.dispatch(usersSliceApi.endpoints.getEmployeeById.initiate(id)).unwrap();

	return promise;
};
