import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { ReactComponent as InfoIcon } from 'static/images/info-circle.svg';

import cssStyles from './styles.module.css';

interface DisabledEntityTooltipProps {
	children: React.ReactNode;
}

const DisabledEntityTooltip: React.FC<DisabledEntityTooltipProps> = ({ children }) => {
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'top' });
	const [isVisible, setIsVisible] = useState(false);

	return (
		<>
			<InfoIcon
				data-disabled-entity-info
				className={cssStyles.infoIcon}
				ref={setReferenceElement}
				onMouseEnter={() => setIsVisible(true)}
				onMouseLeave={() => setIsVisible(false)}
			/>

			{isVisible && (
				<>
					{createPortal(
						<div ref={setPopperElement} style={styles.popper} {...attributes.popper} className={cssStyles.tooltip}>
							{children}
						</div>,
						document.querySelector('#portal'),
					)}
				</>
			)}
		</>
	);
};

export default DisabledEntityTooltip;
