import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import { ActionCellContent } from 'components/Table/TableComponents/ContentOrientedCells';
import { HeaderCell, RegularCell } from 'components/Table/TableComponents/SpacingOrientedCells';
import { breakPoints } from 'const';
import type { CreatePrice } from 'models/price';
import { visibilityColumnsIcons } from 'pages/Order/lib/icons';
import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { formatNumberWithSpaces, numberify, toPrecise } from 'utils/shared';

import styles from './styles.module.css';

const columnHelper = createColumnHelper<CreatePrice>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TODOType = any;
export interface UseCreatePriceColumnsProps {
	onProductPick?: (product: TODOType, e?: React.MouseEvent<HTMLElement>) => void;
	onModalOpen?: (product: TODOType, e?: React.MouseEvent<HTMLElement>) => void;
}

export const useCreatePriceColumns = ({ onProductPick, onModalOpen }: UseCreatePriceColumnsProps) => {
	const isMobile = useMediaQuery({ maxWidth: breakPoints.MOBILE - 1 });
	const isTablet = useMediaQuery({ query: `(min-width: ${breakPoints.MOBILE}px) and (max-width: ${breakPoints.DESKTOP - 1}px)` });
	const isDesktop = useMediaQuery({ query: `(min-width: ${breakPoints.DESKTOP}px)` });

	const columns = useMemo(
		() => [
			...(isTablet
				? [
						columnHelper.accessor((row) => row.brand, {
							id: 'brand',
							header: () => <HeaderCell>Товар</HeaderCell>,
							cell: (cell) => (
								<RegularCell onClick={(e) => onProductPick?.(cell.row.original, e)}>
									<span className="tablet-cell">
										<span>{cell.getValue()}</span>
										<span>{cell.row.original.title}</span>
									</span>
								</RegularCell>
							),
							enableHiding: false,
							enableSorting: false,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Товар',
								icon: <visibilityColumnsIcons.Product />,
							},
						}),
				  ]
				: [
						columnHelper.accessor((row) => row.title, {
							id: 'title',
							header: () => <HeaderCell {...(isDesktop && { justify: 'start' })}>Товар</HeaderCell>,
							cell: (cell) => {
								const handleClick = () => {
									if (isMobile) return;
									onModalOpen?.(cell.row.original);
								};
								return (
									<RegularCell {...(isDesktop && { justify: 'start' })} onClick={handleClick}>
										{cell.getValue()}
									</RegularCell>
								);
							},
							enableHiding: false,
							enableSorting: false,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Товар',
								icon: <visibilityColumnsIcons.Product />,
							},
						}),
						columnHelper.accessor((row) => row.brand, {
							id: 'brand',
							header: () => <HeaderCell {...(isDesktop && { justify: 'start' })}>Бренд</HeaderCell>,
							cell: (cell) => (
								<RegularCell {...(isDesktop && { justify: 'start' })} className={clsx(styles.highlightGray, styles.mediumBold)}>
									{cell.getValue()}
								</RegularCell>
							),
							enableHiding: false,
							enableSorting: false,
							meta: {
								getCanRenderOnMobile: () => false,
								getVisibilityToggleTitle: () => 'Бренд',
								icon: <visibilityColumnsIcons.Brand />,
							},
						}),
				  ]),
			columnHelper.accessor((row) => row.quantity, {
				id: 'quantity',
				header: () => <HeaderCell>{!isMobile ? 'К-ть' : 'Кількість'}</HeaderCell>,
				cell: (cell) => {
					const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
						event.stopPropagation();
						onModalOpen?.(cell.row.original, event);
					};
					return (
						<RegularCell>
							{isMobile ? (
								<ActionCellContent onChangeClick={handleClick}>
									<span className={clsx(styles.highlightGray, styles.mediumBold)}>{cell.getValue() || 0}</span>
									<span>{cell.row.original.measureUnit?.label}</span>
								</ActionCellContent>
							) : (
								cell.getValue()
							)}
						</RegularCell>
					);
				},
				enableHiding: false,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Кількість',
					icon: <visibilityColumnsIcons.Quantity />,
				},
			}),
			columnHelper.accessor((row) => row.price, {
				id: 'price',
				header: () => <HeaderCell className={clsx(styles.highlightPrimary600, styles.semibold)}>Ціна</HeaderCell>,
				cell: (cell) => {
					const price = numberify(cell.getValue());
					const displayPrice = formatNumberWithSpaces(toPrecise(price));
					const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
						event.stopPropagation();
						alert('to price change page');
					};
					return (
						<RegularCell>
							{isMobile ? (
								<ActionCellContent onChangeClick={handleClick} className={clsx(styles.highlightPrimary600, styles.mediumBold)}>
									<span>{displayPrice}</span>
								</ActionCellContent>
							) : (
								displayPrice
							)}
						</RegularCell>
					);
				},
				enableHiding: false,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ціна',
					icon: <visibilityColumnsIcons.Price />,
				},
			}),
			columnHelper.accessor(() => '0', {
				id: 'finalPrice',
				header: () => <HeaderCell className={clsx(styles.highlightPink)}>{!isMobile ? 'Ост. ц.' : 'Ост. ціна'}</HeaderCell>,
				cell: (cell) => {
					const price = numberify(cell.getValue());
					const formattedPrice = formatNumberWithSpaces(toPrecise(price));

					return <RegularCell className={clsx(styles.highlightPink, styles.mediumBold)}>{formattedPrice}</RegularCell>;
				},
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Ост. ціна',
					icon: <visibilityColumnsIcons.FinalCost />,
				},
			}),

			columnHelper.accessor((row) => row.inReserve || '—', {
				id: 'inReserve',
				header: () => <HeaderCell>Резерв</HeaderCell>,
				cell: (cell) => <RegularCell className={clsx(styles.highlightGray, styles.mediumBold)}>{cell.getValue()}</RegularCell>,
				enableHiding: false,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Резерв',
					icon: <visibilityColumnsIcons.InReserve />,
				},
			}),

			columnHelper.accessor((row) => row.inStock || '—', {
				id: 'inStock',
				header: () => <HeaderCell className={clsx(styles.highlightPink, styles.mediumBold)}>Залишок</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				enableHiding: false,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Залишок',
					icon: <visibilityColumnsIcons.Remaining />,
				},
			}),
			columnHelper.accessor((row) => row.measureUnit?.label, {
				id: 'measureUnit',
				header: () => <HeaderCell>Од-ці</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}.</RegularCell>,
				enableHiding: false,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Од-ці',
					icon: <visibilityColumnsIcons.MeasureUnit />,
				},
			}),
			columnHelper.accessor((row) => row.min || '—', {
				id: 'min',
				header: () => <HeaderCell>МІН</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				enableHiding: false,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'МІН',
					icon: <visibilityColumnsIcons.Min />,
				},
			}),
			columnHelper.accessor((row) => row.entry || '—', {
				id: 'entry',
				header: () => <HeaderCell>Вхід</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				enableHiding: false,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'Вхід',
					icon: <visibilityColumnsIcons.Entry />,
				},
			}),
			columnHelper.accessor((row) => row.bulk || '—', {
				id: 'bulk',
				header: () => <HeaderCell>ОПТ</HeaderCell>,
				cell: (cell) => <RegularCell>{cell.getValue()}</RegularCell>,
				enableHiding: false,
				enableSorting: false,
				meta: {
					getCanRenderOnMobile: () => false,
					getVisibilityToggleTitle: () => 'ОПТ',
					icon: <visibilityColumnsIcons.Group />,
				},
			}),
		],
		[isMobile, isTablet, isDesktop],
	);

	return columns;
};
