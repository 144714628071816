import { useEffect } from 'react';

export const useRemoveDOMElement = ({ selector, removeOnMount = true }: { selector: string; removeOnMount?: boolean }) => {
	useEffect(() => {
		if (removeOnMount) {
			const el = document.querySelector(selector);
			el?.remove();
		}
	}, [removeOnMount]);
};
