import { SERVER_ENTITY_ID } from 'const';
import { toFixed } from 'utils/shared';

import type { ProductInternalModelState } from '../OrderController';

const manualDeviationEnterLimit = {
	min: -100,
	max: 100,
};
const manualDeviationFromPriceEnterLimit = {
	min: -100,
	max: Number.MAX_SAFE_INTEGER,
};

const getEnterPrice = (product: ProductInternalModelState) => {
	const rawEnterPrice = product?.prices?.find((priceEntity) => priceEntity.typePrice.id === SERVER_ENTITY_ID.ProductEnterPriceType);
	const enterPrice = Number(rawEnterPrice?.price ?? 0);

	return enterPrice;
};

export const normalizeManualDeviationInput = (deviationPercentage: string) => {
	const inputValue = Number(deviationPercentage);
	const maxPossibleDeviation = Math.min(inputValue, manualDeviationEnterLimit.max);
	const deviation = Math.max(manualDeviationEnterLimit.min, maxPossibleDeviation);

	return deviation;
};
export const normalizeCalculatedDeviation = (deviationPercentage: string) => {
	const inputValue = Number(deviationPercentage);
	const maxPossibleDeviation = Math.min(inputValue, manualDeviationFromPriceEnterLimit.max);
	const deviation = Math.max(manualDeviationFromPriceEnterLimit.min, maxPossibleDeviation);

	return deviation;
};

export const getPriceFormDeviation = (deviationPercentage: string, product: ProductInternalModelState) => {
	const enterPrice = getEnterPrice(product);
	const price = (enterPrice * Number(deviationPercentage)) / 100 + enterPrice;

	return toFixed(price, { precise: 2 });
};

export const getDeviationFromPrice = (price: string, product: ProductInternalModelState) => {
	const enterPrice = getEnterPrice(product);

	const deviation = ((Number(price) - enterPrice) * 100) / enterPrice;

	return toFixed(deviation, { precise: 2 });
};
