import clsx from 'clsx';
import React, { RefObject, useEffect, useRef } from 'react';

import { IProps } from './types';

const ClickAwayListener: React.FC<IProps> = ({ children, className, onClickAway }) => {
	const containerRef: RefObject<HTMLDivElement> = useRef();
	const isMounted = useRef(false);

	useEffect(() => {
		isMounted.current = true;

		const handleClick = (event) => {
			if (isMounted.current && containerRef.current && !containerRef.current.contains(event.target)) {
				onClickAway();
			}
		};

		window.addEventListener('click', handleClick);

		return () => {
			isMounted.current = false;
			window.removeEventListener('click', handleClick);
		};
	}, [onClickAway]);

	return (
		<div ref={containerRef} className={clsx(className)}>
			{children}
		</div>
	);
};
export default ClickAwayListener;
