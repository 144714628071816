import React from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { Can } from '../OrderAbility/provider';

interface ProtectedOrderRouteProps {
	children: React.ReactNode;
	I: string;
	an: string;
}

const ProtectedOrderRoute: React.FC<ProtectedOrderRouteProps> = ({ children, an, I }) => {
	const location = useLocation();
	const [searchParams] = useSearchParams();

	if (!an?.includes('.:index.')) {
		return (
			<Can passThrough I={I} an={an}>
				{(can) => {
					if (!can) {
						return <div>Основну зявку, яка була розбита, не можна реудагувати.</div>;
					}

					return children;
				}}
			</Can>
		);
	}
	const [action, entity] = an?.split('.:index.') || [];
	const index = searchParams.get('from') ?? 0;

	const redirectUrl = location.pathname.split('/add-products')[0] + '/split?from=' + index;

	return (
		<Can passThrough I={I} an={`${action}.${index}.${entity}`}>
			{(can) => {
				if (!can) {
					return <Navigate to={redirectUrl} />;
				}

				return children;
			}}
		</Can>
	);
};

export default ProtectedOrderRoute;
