import clsx from 'clsx';
import React from 'react';

import styles from '../styles.module.css';
import type { BaseErrorViewProps } from '../types';
import viewStyles from './styles.module.css';

type CodeFailureErrorViewProps = BaseErrorViewProps;

const CodeFailureErrorView: React.FC<CodeFailureErrorViewProps> = ({ className }) => {
	return (
		<div className={clsx(styles.layout, viewStyles.view, className)}>
			<h1>
				Помилка виконання коду <b>:(</b>
			</h1>
			<h2>Зверніться до технічної підтримки</h2>
			<div className={viewStyles.gears}>
				<div className={clsx(viewStyles.gear, viewStyles.one)}>
					<div className={viewStyles.bar} />
					<div className={viewStyles.bar} />
					<div className={viewStyles.bar} />
				</div>
				<div className={clsx(viewStyles.gear, viewStyles.two)}>
					<div className={viewStyles.bar} />
					<div className={viewStyles.bar} />
					<div className={viewStyles.bar} />
				</div>
				<div className={clsx(viewStyles.gear, viewStyles.three)}>
					<div className={viewStyles.bar} />
					<div className={viewStyles.bar} />
					<div className={viewStyles.bar} />
				</div>
			</div>
		</div>
	);
};

export default CodeFailureErrorView;
