import { ROUTES_URLS } from 'const';
import { LoaderFunction, redirect } from 'react-router-dom';
import { store } from 'store';
import { clientsSliceApi } from 'store/reducers/clients/clientsSliceApi';
import { ordersSliceApi } from 'store/reducers/orders/ordersSliceApi';
import { usersSliceApi } from 'store/reducers/users/usersSliceApi';

const fetchOrderById: LoaderFunction = async ({ params }) => {
	const id = params?.id;

	if (!id) {
		return redirect(ROUTES_URLS.HOME);
	}

	const promise = store.dispatch(ordersSliceApi.endpoints.getOrderById.initiate(id)).unwrap();

	return promise;
};

const fetchBrandsFilters = async () => {
	store.dispatch(ordersSliceApi.endpoints.getBrandsFilters.initiate(undefined));

	return null;
};

const fetchCategoriesFilters: LoaderFunction = async (route) => {
	const url = new URL(route.request.url);
	store.dispatch(ordersSliceApi.endpoints.getCategoriesFilters.initiate(url.searchParams.toString()));

	return null;
};
/** ROUTE HANDLERS */

export const selectProductsHandler: LoaderFunction = async (route) => {
	const promise = Promise.all([fetchOrderById(route), fetchBrandsFilters(), fetchCategoriesFilters(route)]);

	return promise;
};

export const ordersLoader: LoaderFunction = (route) => {
	const url = new URL(route.request.url);

	store.dispatch(ordersSliceApi.endpoints.getOrders.initiate(url.searchParams.toString()));
	return null;
};

export const existingOrderDataLoader: LoaderFunction = async (route) => {
	const url = new URL(route.request.url);
	const searchParams = url.searchParams;

	Promise.all([
		store.dispatch(usersSliceApi.endpoints.getWarehouses.initiate(undefined)).unwrap(),
		store.dispatch(clientsSliceApi.endpoints.getClientsOptions.initiate('')).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getEmployeesOptionList.initiate()).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getOrganizationsOptionList.initiate()).unwrap(),
		store.dispatch(ordersSliceApi.endpoints.getCatalogueProducts.initiate(searchParams.toString())).unwrap(),
		store.dispatch(ordersSliceApi.endpoints.getOrderById.initiate(route.params.id, { forceRefetch: true })).unwrap(),
		store.dispatch(ordersSliceApi.endpoints.getServices.initiate()).unwrap(),
	]);

	return null;
};
export const newOrderDataLoader: LoaderFunction = () => {
	Promise.all([
		store.dispatch(ordersSliceApi.endpoints.getServices.initiate()).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getWarehouses.initiate(undefined)).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getEmployeesOptionList.initiate()).unwrap(),
		store.dispatch(usersSliceApi.endpoints.getOrganizationsOptionList.initiate()).unwrap(),
	]);

	return null;
};
