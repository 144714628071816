import clsx from 'clsx';
import React, { lazy, MouseEvent, Suspense } from 'react';

import styles from './styles.module.css';
import type { IProps } from './types';

const Button = lazy(() => import('components/Button'));

const ModalControls: React.FC<IProps> = ({
	children,
	className,
	onSubmit,
	onCancel,
	accentActionButton = 'submit',
	submitButtonText = 'Зберегти',
	cancelButtonText = 'Скасувати',
	cancelButtonType = 'button',
	submitButtonType = 'button',
	disable = false,
	formId,
	...restProps
}) => {
	const handleClick = (cb: () => void) => (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		cb?.();
	};

	return (
		<div data-modal-controls data-accent-on-action={accentActionButton} className={clsx(styles.modalControlsWrapper, className)} {...restProps}>
			{!!children ? (
				children
			) : (
				<Suspense>
					<Button
						disabled={disable}
						type={cancelButtonType}
						variant="default"
						onClick={handleClick(onCancel)}
						text={cancelButtonText}
						className={clsx({ [styles.disabled]: disable })}
					/>
					<Button
						disabled={disable}
						type={submitButtonType}
						variant="default"
						onClick={handleClick(onSubmit)}
						text={submitButtonText}
						form={formId}
						className={clsx({ [styles.disabled]: disable })}
					/>
				</Suspense>
			)}
		</div>
	);
};

export default ModalControls;
