import clsx from 'clsx';
import ClickAwayListener from 'components/ClickAwayListener';
import Modal from 'components/Modal';
import ModalBody from 'components/Modal/ModalBody';
import ModalControls from 'components/Modal/ModalControls';
import ModalHeader from 'components/Modal/ModalHeader';
import SpinnerV2 from 'components/Spinner-v2';
import { useBoolean } from 'hooks/useBoolean';
import { CatalogueService } from 'models/service';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import { transformCatalogueServiceToInternalServiceStateModel } from 'pages/Order/OrderController/lib/transform';
import React, { lazy, useTransition } from 'react';
import { ReactComponent as PackageCheckIcon } from 'static/images/package-check.svg';
import { ReactComponent as PaintPourIcon } from 'static/images/paint.svg';
import { ReactComponent as ScissorsIcon } from 'static/images/scissors.svg';
import { ReactComponent as TruckIcon } from 'static/images/truck-white.svg';
import { ReactComponent as XCloseIcon } from 'static/images/x-close.svg';
import { useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';

import SelectorButton from './SelectorButton';
import styles from './styles.module.css';
import type { PopupAppearanceProps, ServiceSelectorsProps } from './types';

const AddServiceForm = lazy(() => import('./AddServiceForm'));

const iconMap = [TruckIcon, PackageCheckIcon, ScissorsIcon, PaintPourIcon];

const isMetalCuttingRootService = (serviceName: string) => serviceName === 'Послуги порізка металу';

const ServiceSelectors: React.FC<ServiceSelectorsProps> = ({ appearance, onClick, onAddOrder, disabled = false, ...restProps }) => {
	const [, startTransition] = useTransition();
	const { getAllowedMetalCuttingOptions } = useOrderOperationMethods();
	const { data: servicesMapObj, isLoading, error } = useGetServicesQuery<Record<string, CatalogueService>>();
	const dialogue = useBoolean();

	const onSubmit = (service: ReturnType<typeof transformCatalogueServiceToInternalServiceStateModel>) => {
		onAddOrder(service);
		startTransition(() => {
			onClick?.();
		});
		dialogue.close();
	};

	const handleOnClick = (serviceName: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
		if (isMetalCuttingRootService(serviceName)) {
			event.stopPropagation();

			dialogue.open();
			return;
		}

		const entity = transformCatalogueServiceToInternalServiceStateModel({
			service: servicesMapObj[serviceName],
			serviceName,
		});

		onAddOrder(entity);
		startTransition(() => {
			onClick?.(event);
		});
	};

	const isPopupAppearance = appearance === 'popup';

	if (isLoading) {
		return <SpinnerV2 />;
	}

	if (error) {
		return <p>Error loading services</p>;
	}

	const options = getAllowedMetalCuttingOptions();

	const buttons = Object.keys(servicesMapObj).map((service) => ({
		title: service,
		isDisabled: isMetalCuttingRootService(service) && options.services.length === 0,
	}));

	const buttonList = buttons.map((button, index) => {
		return (
			<li key={button.title}>
				<SelectorButton
					onClick={handleOnClick(button.title)}
					label={button.title}
					icon={iconMap[index % iconMap.length]}
					disabled={disabled || button.isDisabled}
				/>
			</li>
		);
	});

	if (!isPopupAppearance) {
		return (
			<>
				<ul className={clsx(styles.tablistControls)}>{buttonList}</ul>

				{dialogue.isOn && (
					<Modal open onClose={dialogue.close} minWidth="40vw" maxWidth="40vw" minHeight="320px" maxHeight="40vh">
						<ModalHeader onXCloseClick={dialogue.close} title="Оберіть послугу" />
						<ModalBody>
							<AddServiceForm categoryOptions={options.categories} serviceOptions={options.services} onSelect={onSubmit} />
						</ModalBody>
						<ModalControls style={{ marginTop: '30px' }} submitButtonText="Обрати" submitButtonType="submit" formId="select-service" />
					</Modal>
				)}
			</>
		);
	}

	const { onClose } = restProps as PopupAppearanceProps;

	return (
		<div className={styles.backdrop}>
			<ClickAwayListener onClickAway={onClose}>
				<ul className={clsx(styles.tablistControls, styles.appearancePopup)}>
					{isPopupAppearance && (
						<li className={styles.header}>
							<p>Оберіть послугу</p>
							<button type="button" className={styles.xCloseButton} onClick={onClose} disabled={disabled}>
								<XCloseIcon />
							</button>
						</li>
					)}
					{buttonList}
				</ul>
			</ClickAwayListener>
		</div>
	);
};

export default ServiceSelectors;
