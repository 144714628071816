import { extractClientContractIds } from 'lib/utils';
import type { Client, ClientPreview, ClientRawOption } from 'models/client';
import type { OfflineClientModel } from 'models/offline';
import { uuid } from 'utils/shared';

export const transformOfflineClientModelToClient = (offlineClient: OfflineClientModel, config?: { isNew?: boolean }): Client => {
	const client: Client = {};

	for (const property in offlineClient) {
		if (!Object.hasOwn(offlineClient, property)) continue;
		if (property.startsWith('ik')) continue;

		client[property] = offlineClient[property];
	}

	if (config?.isNew) {
		client.isOfflineCreated = true;
		client.id = uuid();
	}

	return client;
};
export const transformClientToOfflineClientModel = (client: Client): OfflineClientModel => {
	const offlineClient: OfflineClientModel = {
		...client,
		// @ts-ignore
		...(client.edrpouCode && { ikCode: client?.edrpouCode ?? '' }),
		ikContracts: extractClientContractIds(client.contracts),
		ikDaysOfDelay: Number(client.daysOfDelay),
		ikLastDeal: new Date(client.lastDeal),
		ikLimit: Number(client.available?.limit),
		ikManagerId: client.manager?.['1c_uuid'] || '',
		// @ts-ignore
		ikName: (client.organizationName?.trim() || client.name?.trim()) ?? '',
		ikSegmentId: client?.segment?.id ?? '',
		ikSortByLimit: client.available?.limit || 0,
		ikSortByManager: client.manager?.['1c_uuid'] ?? '',
		ikSortBySegment: client.segment?.title ?? '',
	};
	return offlineClient;
};

export const transformClientToPreviewClient = (client: Client): ClientPreview => {
	// @ts-ignore
	return {
		id: client?.id ?? '',
		name: client.name ?? '',
		// @ts-ignore
		organizationName: client.organizationName,
		email: client?.email ?? '',
		code: client.code ?? '',
		// @ts-ignore
		edrpouCode: client.edrpouCode ?? '',
		ipnCode: client.ipnCode ?? '',
		comment: client.comment ?? '',
		segment: client.segment as ClientPreview['segment'],
		manager: client.manager ?? null,
		activities: client.activities ?? [],
		avatar: client.avatar,
		createdAt: new Date(client.createdAt).toISOString(),
		limit: client.available,
		lastDeal: client.lastDeal,
		ordersCount: client.ordersCount ?? 0,
	};
};

export const transformOfflineClientModelToClientOption = (model: OfflineClientModel): ClientRawOption => {
	return {
		// @ts-ignore
		name: model.organizationName || model.name,
		id: model.id,
	};
};
