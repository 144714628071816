import { createApi } from '@reduxjs/toolkit/query/react';
import { API_ENDPOINT } from 'const';
import type { RequestResult } from 'models/api';
import {
	MakeBusinessOfferRequestDTO,
	MakeBusinessOfferResult,
	MakeBusinessOfferResultDTO,
	transformToMakeBusinessOfferResult,
} from 'models/business-offer';
import { usersQueryKeys } from 'services/queryKeys';
import { baseQuery } from 'store/config/base-query';

export const businessOfferSliceApi = createApi({
	reducerPath: 'businessOffer',
	baseQuery: baseQuery(),
	tagTypes: [usersQueryKeys.users()],
	endpoints: (builder) => ({
		makeBusinessOffer: builder.mutation<MakeBusinessOfferResult, MakeBusinessOfferRequestDTO>({
			query: (dto) => ({ url: API_ENDPOINT.businessOffer(), method: 'POST', data: dto }),
			transformResponse: (response: RequestResult<MakeBusinessOfferResultDTO>) => {
				const businessOffer = transformToMakeBusinessOfferResult(response.data);

				return businessOffer;
			},
		}),
	}),
});

export const { useMakeBusinessOfferMutation } = businessOfferSliceApi;
