import axios from 'axios';
import { CLIENT_INFO_BY_STATUS, CLIENT_STATUS, FILTER_ITEM_SIZE, GUTTER, MAX_LIST_HEIGHT } from 'const';

import { isNumber, isString } from './type-guards';

export const getProgressBarFillColor = (progress: number): string => {
	let progressBarColor: string;

	if (progress < 30) {
		progressBarColor = 'var(--primary-600)';
	} else if (progress < 50) {
		progressBarColor = 'var(--success-500)';
	} else if (progress <= 80) {
		progressBarColor = 'var(--warning-500)';
	} else {
		progressBarColor = 'var(--error-500)';
	}

	return progressBarColor;
};

type CalculateProgressConfig = { precision: number };

export const calculateProgress = (from: number, to: number, config?: CalculateProgressConfig): number => {
	const { precision = 1 } = config || {};

	if (to === 0) {
		return 0;
	}

	const progress = (from / to) * 100;
	const resolvedProgress = Math.min(progress, 100);
	const roundedProgress = Number(resolvedProgress.toFixed(precision));

	return roundedProgress;
};

export const formatNumberWithSpaces = (number: number | string) => {
	return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const kFormatter = (num: number, precise?: number) => {
	const formattedStringNumber =
		Math.abs(num) > 1000000
			? `${(Math.sign(num) * (Math.abs(num) / 1000000)).toFixed(precise ?? 2)}M`
			: Math.abs(num) > 999
			? `${(Math.sign(num) * (Math.abs(num) / 1000)).toFixed(precise ?? 1)}k`
			: Math.sign(num) * Math.abs(num);

	return formattedStringNumber;
};

export const kWeightFormatter = (num: number, precise?: number): string => {
	const formatter = new Intl.NumberFormat('uk-UA', { maximumFractionDigits: precise ?? 2 });
	return `${formatter.format(num)} кг`;
};

export const kVolumeFormatter = (num: number, precise?: number): string => {
	const formatter = new Intl.NumberFormat('uk-UA', { maximumFractionDigits: precise ?? 2 });
	return `${formatter.format(num)} м³`;
};

export const kFileSizeFormatter = (sizeInBytes: number, precise: number = 2) => {
	const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	let index = 0;

	while (sizeInBytes >= 1024 && index < units.length - 1) {
		sizeInBytes /= 1024;
		index++;
	}

	return `${sizeInBytes.toFixed(precise)} ${units[index]}`;
};

export const uuid = () => {
	return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export const formatNumberToUAH = (price: number) => {
	const intlFormattedPrice = new Intl.NumberFormat('uk-UA', {
		style: 'currency',
		currency: 'UAH',
		currencyDisplay: 'symbol',
		minimumFractionDigits: 2,
	})
		.format(price)
		.replace(',', '.')
		.replace(/грн/, '₴');

	const formattedWithSpacesPrice = formatNumberWithSpaces(intlFormattedPrice);

	return formattedWithSpacesPrice;
};

export const prepareUrl = (url: string, dynamicSegments?: Record<string, string>) => {
	const entries = Object.entries(dynamicSegments || {});

	let path = url;

	if (entries.length) {
		entries.forEach(([key, value]) => {
			const regex = new RegExp(`:${key}`, 'g');
			path = path.replace(regex, value);
		});
	}

	if (path.startsWith('/')) {
		return path;
	}

	return `/${path}`;
};

export const calculateTotalPrice = <TData extends { price?: number; amount?: number }>(data?: TData[] | TData) => {
	if (!data) return 0;

	const calculate = (product: TData) => {
		const sanitizedPrice = numberify(product?.price);
		const sanitizedAmount = numberify(product?.amount || 0);

		return sanitizedPrice * sanitizedAmount;
	};

	if (Array.isArray(data)) {
		if (!data.length) return 0;

		const total = data.reduce((acc, product) => {
			return (acc += calculate(product));
		}, 0);

		return total;
	} else {
		return calculate(data);
	}
};

export const pluralizeProductCount = (value: number, lang?: string) => {
	const pluralRules = new Intl.PluralRules(lang ?? 'ua-Uk');

	const forms = {
		one: (v: number) => `${v} товар`,
		few: (v: number) => `${v} товарів`,
		many: (v: number) => `${v} товарів`,
		other: (v: number) => `${v} товарів`,
	};

	const pluralCategory = pluralRules.select(value);

	// Special cases for numbers ending in 1, 2, 3, or 4, excluding numbers ending in 11, 12, 13, and 14
	if (value % 10 === 1 && value % 100 !== 11) {
		forms.one = (v) => `${v} товар`;
	} else if ([2, 3, 4].includes(value % 10) && ![12, 13, 14].includes(value % 100)) {
		return `${value} товари`;
	}

	const formatFunction = forms[pluralCategory] || forms.other;

	return formatFunction(value);
};

export const numberify = (value: string | number, fallback?: number) => {
	if (isString(value)) {
		const resolvedFallback = fallback ?? 0;

		if (value === '') return resolvedFallback;

		const hasNonNumeric = /[^\d,.]/.test(value);

		if (hasNonNumeric) return resolvedFallback;

		const isDecimal = /^-?\d*([.,]?\d+)?$/.test(value);

		if (isDecimal) {
			const sanitizedValue = value.trim().replace(',', '.').replaceAll(' ', '') || '0';
			return parseFloat(sanitizedValue);
		} else {
			const sanitizedValue = value.trim();

			if (sanitizedValue.endsWith('.') || sanitizedValue.endsWith(',')) {
				return parseFloat(sanitizedValue.slice(0, sanitizedValue.length - 1));
			}

			return parseFloat(value);
		}
	}

	return value;
};

const isInteger = (num) => {
	return num % 1 === 0;
};

export const roundNumber = (num: number, scale: number) => {
	if (!('' + num).includes('e')) {
		return +(Math.round(Number(`${num}e+${scale}`)) + 'e-' + scale);
	} else {
		const arr = ('' + num).split('e');
		let sig = '';
		if (+arr[1] + scale > 0) {
			sig = '+';
		}
		return +(Math.round(Number(+arr[0] + 'e' + sig + (+arr[1] + scale))) + 'e-' + scale);
	}
};

export const toPrecise = (data: string | number, precision?: number) => {
	const precisionValue = precision ?? 2;
	const value = numberify(data);

	return String(roundNumber(value, precisionValue));
};
const calculateScrollPosition = (container: HTMLElement, targetElement: HTMLElement) => {
	const containerRect = container.getBoundingClientRect();
	const targetRect = targetElement.getBoundingClientRect();

	const scrollPosition = targetRect.bottom - containerRect.bottom + container.scrollTop;

	return scrollPosition;
};

export const scrollActiveOptionIntoView = (container: HTMLElement, option: HTMLElement) => {
	if (container && option) {
		const scrollPosition = calculateScrollPosition(container, option);
		container.scrollTo({ top: scrollPosition, behavior: 'smooth' });
	}
};

export const getColorByClientState = <TKey extends keyof typeof CLIENT_STATUS>(status: typeof CLIENT_STATUS[TKey]) => {
	const clientStatus = CLIENT_INFO_BY_STATUS[status];
	return clientStatus.color;
};
export const getClientStateName = <TKey extends keyof typeof CLIENT_STATUS>(status: typeof CLIENT_STATUS[TKey]) => {
	const clientStatus = CLIENT_INFO_BY_STATUS[status];
	return clientStatus.name;
};

export const base64toFile = (base64String: string, fileName: string) => {
	const mimeTypeMatch = base64String.match(/^data:([^;]+);base64,/);

	if (!mimeTypeMatch) {
		throw new Error('Invalid Base64 string');
	}

	const mimeType = mimeTypeMatch[1];
	const base64WithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, '');
	const binaryArray = atob(base64WithoutPrefix);

	const uint8Array = new Uint8Array(binaryArray.length);

	for (let i = 0; i < binaryArray.length; i++) {
		uint8Array[i] = binaryArray.charCodeAt(i);
	}

	const extension = mimeType.split('/')[1] || 'bin';
	const fileWithExtension = `${fileName}.${extension}`;

	const file = new File([uint8Array], fileWithExtension, { type: mimeType });

	return file;
};

export const blobToBase64 = (file: File | Blob): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (typeof reader.result === 'string') {
				resolve(reader.result);
			} else {
				const decoder = new TextDecoder();
				const str = decoder.decode(reader.result);
				resolve(str);
			}
		};
		reader.onerror = (error) => reject(error);
	});

export const checkIfHasExceedFieldsLimit = (fieldsCount: number, limit: number) => {
	return fieldsCount >= limit;
};

export const getInputUniqueId = (params: (string | number)[]) => params.join('') + String(new Date().getTime());

export const getFilterItemSize = () => FILTER_ITEM_SIZE + GUTTER;

export const getVirtualListHeight = (itemsCount: number, config?: { itemHeight?: number; maxHeight?: number }) => {
	const { itemHeight: passedItemHeight, maxHeight } = config ?? {};

	const itemHeight = passedItemHeight ?? getFilterItemSize();
	const currentListHeight = itemsCount * itemHeight;
	const maxListHeight = maxHeight ?? MAX_LIST_HEIGHT;

	return Math.min(currentListHeight, maxListHeight);
};

export const extractDigits = (input: string | number): string => {
	if (isNumber(input)) return String(input);

	const numericOnly = input.replace(/\D/g, '');

	return numericOnly;
};

export const capitalize = (str: string) => {
	if (!str) return '';

	const isAllCapital = str.toUpperCase() === str;

	if (isAllCapital) return str;

	return str[0].toUpperCase() + str.slice(1);
};

export const reThrowError = (error: unknown): never => {
	throw error;
};

export const toFixed = (num: number | string, config?: { precise?: number; isInt?: boolean; strictPrecision?: boolean }): string => {
	let numStr = String(num);

	const separator = numStr.includes(',') ? ',' : '.';

	const [integerPart, decimalPart = ''] = numStr.split(separator);

	if (config?.isInt) {
		const normalizedNum =
			isNumber(num) || !isNaN(Number(num)) ? num : isString(num) && (numStr.includes('.') || numStr.includes(',')) ? integerPart : '1';

		return String(roundNumber(Number(normalizedNum), 0));
	}

	if (!config?.strictPrecision && decimalPart.length < 2) {
		return `${integerPart}${separator}${decimalPart.padEnd(2, '0')}`;
	}

	if (isNumber(config?.precise)) {
		const value = roundNumber(Number(num), config.precise);
		numStr = isInteger(value) ? String(value) + '.00' : String(value).padEnd(2, '0');
	}

	return numStr;
};

export const createInitialLettersFallback = (name: string = '') => {
	const [firstName, lastName] = name.split(' ') || [];

	if (!firstName && !lastName) return 'AA';
	if (!firstName) return 'A';

	if (!lastName) {
		return firstName[0].trim().toUpperCase();
	}

	const firstNameCapitalLetter = firstName[0].trim().toUpperCase();
	const lastNameCapitalLetter = lastName[0].trim().toUpperCase();
	const initials = `${firstNameCapitalLetter}${lastNameCapitalLetter}`;

	return initials;
};

export const hashString = (str: string, seed?: number): string => {
	const resolvedSeed = seed ?? 2;

	let h1 = 0xdeadbeef ^ resolvedSeed;
	let h2 = 0x41c6ce57 ^ resolvedSeed;

	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}

	h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
	h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
	h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
	h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

	return String(4294967296 * (2097151 & h2) + (h1 >>> 0));
};

export const highlightOfflineEntity = <TEntity extends { isOfflineCreated?: boolean }>(entity: TEntity) => {
	return entity?.isOfflineCreated ? 'offline-entity' : '';
};

export const checkOnlineStatus = async () => {
	try {
		const response = await axios.get('https://placehold.co/1x1', {
			headers: {
				'Content-Type': 'image/svg+xml; charset=utf-8',
			},
		});

		if (response.data === 'TypeError: Failed to fetch') {
			return false;
		}

		return true;
	} catch (e) {
		return false;
	}
};
