/* eslint-disable max-len */
import type { DatabaseSchema } from './types';

const DB_NAME = 'UnitB' as const;

const DB_PRIMARY_KEYS: Record<keyof DatabaseSchema, string> = {
	products: 'id, title, code, ikBrand, *ikParentIds, *ikStock',
	orders: 'id, parentId, ikClientId, ikClientName, ikManagerId, ikStatus, ikPaymentStatus, ikSum, ikStock, ikDate, *ikPaymentDate, *ikRealizationDate, ikContract, *ikPayments, ikNumber, ikSortByStock',
	clients:
		'id, ikManagerId, ikSegmentId, *ikContracts, ikLimit, ikDaysOfDelay, ikLastDeal, ikName, ikCode, ikSortByLimit, ikSortByManager, ikSortBySegment',
	fullTextSearchInProducts: '++id',
	productCategories: 'id, *brands',
	productBrands: 'id',
	ordersFiltersData: '++id',
	clientsFiltersData: '++id',
	fullTextSearchInClients: '++id',
	createClientColloquialData: '++id',
	filterTemplate: 'id, type',
	services: '++id',
	auth: 'id',

	contracts: 'id',
	segments: 'id',
	stocks: 'id',
	organizations: 'id',
	postponedRequests: '[id+method]',
	employees: 'id, ikEmployeeName',
	offlineOrderAppNumber: 'id',
};

export const getDbName = () => DB_NAME;

export const configureDbPrimaryKeys = () => {
	return DB_PRIMARY_KEYS;
};
