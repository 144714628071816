import { authEmail, minmaxNumberField, username } from 'validation/fields';
import { sharedErrorMessages } from 'validation/messages/common';
import { z } from 'zod';

const loginValidationSchema = z.object({
	username,
	email: authEmail,
});

export const inviteEmployeeValidationSchema = loginValidationSchema.omit({ username: true });

export const withdrawReservationSchema = (max: number) =>
	z.object({
		withdrawQuantity: minmaxNumberField(1, max),
	});

export const commercialProposalValidationSchema = z.object({
	name: z.string().min(1, sharedErrorMessages.commercialProposal.required),
});
