import { SKIP_WAITING_MESSAGE } from 'lib/service-worker/messages.const';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { register as registerServiceWorker } from 'serviceWorkerRegistration';

import { useBoolean } from './useBoolean';

export const useServiceWorker = () => {
	const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
	const showReload = useBoolean(false);
	const activating = useBoolean(false);

	const onUpdate = useCallback((registration: ServiceWorkerRegistration) => {
		showReload.open();
		setWaitingWorker(registration.waiting);
	}, []);

	const onRefresh = useCallback(() => {
		showReload.close();
		activating.close();

		window.location.reload();

		toast.success('Отримано нову версію додатку.');
	}, []);

	const onActivating = useCallback(() => {
		activating.open();
	}, []);

	const reloadPage = () => {
		waitingWorker?.postMessage({ type: SKIP_WAITING_MESSAGE });
	};

	const hideReload = showReload.close;

	useEffect(() => {
		registerServiceWorker({
			onUpdate,
			onRefresh,
			onActivating,
		});
	}, [onRefresh, onUpdate, onActivating]);

	return { showReload, waitingWorker, isActivating: activating.isOn, reloadPage, hideReload };
};
