import BaseError, { BaseErrorParams } from '../base.error';

type RangeErrorParams = Pick<BaseErrorParams, 'message' | 'cause'>;

export class RangeError extends BaseError {
	constructor(params: RangeErrorParams) {
		super({
			cause: 'RANGE_ERROR',
			type: 'CODE',
			...params,
		});
	}
}
