import ColorfulSelect from 'components/ColorfulSelect';
import { breakPoints } from 'const';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import React from 'react';
import { Controller } from 'react-hook-form';
import MediaQuery from 'react-responsive';

import RootButton from './RootButton';
import styles from './styles.module.css';
import { useBackdrop } from './useBackdrop';

interface ServiceOptionSelectMajorFieldProps {
	entityId: string;
	suborderIndex: number;
	placeholder?: string;
	serviceType: string;
	disabled?: boolean;
}

const ServiceOptionSelectField: React.FC<ServiceOptionSelectMajorFieldProps> = ({ placeholder, entityId, suborderIndex, serviceType, disabled }) => {
	const { control, setDirtyValue, getAllowedMetalCuttingOptions } = useOrderOperationMethods();
	const { ref } = useBackdrop();

	const fieldName = `suborders.${suborderIndex}.data.services.${entityId}.service` as const;
	const options = getAllowedMetalCuttingOptions();
	const serviceOptions = options.services.map((option) => ({ title: option.label, id: option.value }));

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<Controller
					name={fieldName}
					control={control}
					render={({ field }) => {
						const newValue = field.value as AnyArg;

						return (
							<ColorfulSelect
								closeOnSelect
								popupHeaderTitle="Виберіть тип послуги"
								popupHeaderClassName={styles.popupHeader}
								value={newValue}
								placeholder={placeholder}
								// @ts-ignore
								rootButton={(props) => <RootButton {...props} disabled={disabled} title={placeholder} />}
								options={serviceOptions ?? []}
								getIsDisabled={(option) => option.id !== newValue.id}
								onSelect={(v) => {
									const categoryOption = options.categories[0];
									// @ts-ignore
									setDirtyValue(`suborders.${suborderIndex}.data.services.${entityId}.category`, categoryOption);
									field.onChange({ ...v, serviceType });
								}}
							/>
						);
					}}
				/>
			</MediaQuery>

			<div ref={ref} className={styles.backdrop} />
		</>
	);
};

export default ServiceOptionSelectField;
