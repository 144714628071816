import NotificationModal from 'components/Modals/NotificationModal';
import { useServiceWorker } from 'hooks/useServiceWorker';
import React from 'react';

const NewVersionAvailable: React.FC = () => {
	const { waitingWorker, showReload, isActivating, reloadPage, hideReload } = useServiceWorker();

	const hasNewVersion = !!waitingWorker && !!showReload;

	return (
		hasNewVersion && (
			<NotificationModal
				headerTitle="Доступне оновлення"
				submitButtonText="Оновити зараз"
				notificationMessage="Оновлена версія додатку може містити новий функціонал та/або стилі"
				onClose={hideReload}
				onSubmit={reloadPage}
				isLoading={isActivating}
			/>
		)
	);
};

export default NewVersionAvailable;
