import type { DB } from 'lib/database';

import { DexieAuthServiceRepositoryAdapter } from './adapter/dexie-repository.adapter';
import { AuthService } from './auth.service';

export interface AuthServiceFactoryParams {
	db: DB;
}

export const createAuthServiceFactory = ({ db }: AuthServiceFactoryParams) => {
	const repository = new DexieAuthServiceRepositoryAdapter(db);

	return new AuthService(repository);
};
