import ColorfulSelect from 'components/ColorfulSelect';
import { breakPoints } from 'const';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import { getEntityDIYPrice } from 'pages/Order/OrderController/lib/utils';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import MediaQuery from 'react-responsive';

import RootButton from './RootButton';
import styles from './styles.module.css';
import { useBackdrop } from './useBackdrop';

interface ServiceOptionSelectDependantFieldProps {
	entityId: string;
	suborderIndex: number;
	placeholder?: string;
	disabled?: boolean;
}

const ServiceOptionSelectDependantField: React.FC<ServiceOptionSelectDependantFieldProps> = ({ placeholder, entityId, suborderIndex, disabled }) => {
	const { control, setDirtyValue } = useOrderOperationMethods();

	const dependantFieldName = `suborders.${suborderIndex}.data.services.${entityId}.category` as const;
	const priceField = `suborders.${suborderIndex}.data.services.${entityId}.price` as const;
	const codeField = `suborders.${suborderIndex}.data.services.${entityId}.code` as const;

	const { ref } = useBackdrop();

	return (
		<>
			<MediaQuery minWidth={breakPoints.MOBILE}>
				<Controller
					name={`suborders.${suborderIndex}.data.services.${entityId}.service`}
					render={(service) => {
						return (
							<Controller
								name={dependantFieldName}
								control={control}
								render={({ field }) => {
									const options =
										service.field?.value?.services?.map((option) => ({
											title: option.title,
											id: option.id,
										})) ?? [];

									useEffect(() => {
										if (service.field?.value?.id && !field.value && options?.length) {
											const [firstCategory] = options;
											field.onChange(firstCategory);
											// @ts-ignore
											setDirtyValue(codeField, firstCategory?.code);
										}
									}, [service.field?.value?.id, field.value]);

									return (
										<ColorfulSelect
											closeOnSelect
											popupHeaderTitle="Виберіть за параметрами"
											onSelect={(newValue) => {
												// @ts-ignore
												setDirtyValue(priceField, getEntityDIYPrice(newValue as AnyArg, 'services'));
												field.onChange(newValue);
											}}
											popupHeaderClassName={styles.popupHeader}
											value={field.value || options[0]}
											placeholder={placeholder}
											// @ts-ignore
											rootButton={(props) => <RootButton {...props} disabled={disabled} title={placeholder} />}
											options={options ?? []}
										/>
									);
								}}
							/>
						);
					}}
				/>
			</MediaQuery>
			<div ref={ref} className={styles.backdrop} />
		</>
	);
};

export default ServiceOptionSelectDependantField;
