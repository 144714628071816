import clsx from 'clsx';
import React from 'react';

import styles from '../styles.module.css';
import type { BaseErrorViewProps } from '../types';
import viewStyles from './styles.module.css';

type ForbiddenErrorViewProps = BaseErrorViewProps & {
	title?: React.ReactNode;
	message?: React.ReactNode;
};

const ForbiddenErrorView: React.FC<ForbiddenErrorViewProps> = ({ className, title = <DefaultTitle />, message = <DefaultMessage /> }) => {
	return (
		<div className={clsx(styles.layout, viewStyles.view, className)}>
			<div className={viewStyles.lock} />
			<div className={viewStyles.message}>
				{title}
				{message}
			</div>
		</div>
	);
};

export default ForbiddenErrorView;

function DefaultTitle() {
	return (
		<h1>
			<span>Доступ до сторінки або ресурсу</span>
			<br />
			<span>заборонений</span>
		</h1>
	);
}
function DefaultMessage() {
	return <p>Будь ласка, зверніться до технічної підтримки, якщо ви впевнені, що це помилково.</p>;
}
