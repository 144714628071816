import clsx from 'clsx';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalHeader from 'components/Modal/ModalHeader';
import type { IProps as ModalProps } from 'components/Modal/types';
import ProductItem from 'components/OrderCreatePageComponents/BulkProductSelection/Item';
import TipCard from 'components/TipCard';
import { ProductInternalModelState } from 'pages/Order/OrderController';
import React from 'react';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';

import styles from './styles.module.css';

type TransferProductsModalProps = Pick<ModalProps, 'onClose'> & {
	onProductClick(product: ProductInternalModelState): void;
	onSubmit(products: ProductInternalModelState[]): void;
	products: ProductInternalModelState[];
	targetSuborderIndex: number;
	onQuantityChange: (id: string, newQuantity: number) => void;
};

const TransferProductsModal: React.FC<TransferProductsModalProps> = ({
	onClose,
	onProductClick,
	onQuantityChange,
	onSubmit,
	products,
	targetSuborderIndex,
}) => {
	return (
		<Modal noAwayClick open stackable onClose={onClose} className={styles.modal} maxWidth="800px" maxHeight="518px">
			<ModalHeader title={<span className={styles.headerTitle}>Перенесення товарів</span>} onXCloseClick={onClose} className={styles.header} />

			<ul className={clsx(styles.list)}>
				{products.map((product) => (
					<ProductItem
						key={product?.id}
						onClick={onProductClick.bind(null, product)}
						onQuantityChange={onQuantityChange.bind(null, product.id)}
						initialValue={product.amount}
						price={Number(product.price)}
						brand={product.brand}
						title={product.title}
						unit={product.unit}
					/>
				))}
			</ul>

			<TipCard message="Інформацію про наявність можна переглянути в картці продукту" />

			<div className={styles.controls}>
				<Button variant="default" onClick={onClose} className={styles.cancel} text="Скасувати" />
				<Button
					variant="rounded"
					background="var(--success-500)"
					onClick={onSubmit}
					text={`Перенести в заявку ${targetSuborderIndex}`}
					icon={<PlusIcon />}
				/>
			</div>
		</Modal>
	);
};

export default TransferProductsModal;
