import { ServerSideOptionSchema } from 'models/common/options';
import { BusinessOfferIdSchema } from 'models/common/uuid';
import { z } from 'zod';

const MakeBusinessOfferRequestDTOSchema = z.object({
	name: z.string(),
	offer_text: z.string(),
	order_id: z.string(),
});
export const MakeBusinessOfferModelSchema = z.object({
	name: z.string(),
	offerText: z.string(),
	orderId: z.string(),
});
const MakeBusinessOfferResultSchema = MakeBusinessOfferModelSchema.pick({ orderId: true, name: true }).extend({
	id: z.string(),
	pdfUrl: z.string(),
});
export const MakeBusinessOfferFormSchema = z.object({
	name: z.string().min(1, 'Назва обовʼзякова').max(255, 'Назва має бути не більше 255 символів'),
	offerText: z.string().min(1, 'Текст пропозиції обовʼзяковий').max(300, 'Текст пропозиції має бути не більше 300 символів'),
	orderId: z.string(),
});
export const MakeBusinessOfferResultDTOSchema = MakeBusinessOfferRequestDTOSchema.pick({ name: true, order_id: true }).extend({
	id: z.string(),
	pdf_url: z.string(),
});

const BusinessOfferSchema = z.object({
	id: BusinessOfferIdSchema,
	code: z.string(),
	brand: z.string(),
	title: z.string(),
	pickedAmount: z.number(),
	unit: ServerSideOptionSchema,
	priceWithTax: z.number(),
	totalPriceWithTax: z.number(),
});

export type MakeBusinessOfferModel = z.infer<typeof MakeBusinessOfferModelSchema>;
export type MakeBusinessOfferRequestDTO = z.infer<typeof MakeBusinessOfferRequestDTOSchema>;
export type MakeBusinessOfferResultDTO = z.infer<typeof MakeBusinessOfferResultDTOSchema>;
export type MakeBusinessOfferResult = z.infer<typeof MakeBusinessOfferResultSchema>;
export type MakeBusinessOfferForm = z.infer<typeof MakeBusinessOfferFormSchema>;
export type BusinessOffer = z.infer<typeof BusinessOfferSchema>;
