import { SegmentIdSchema } from 'models/common/uuid';
import { z } from 'zod';

const SegmentKeyType = z.union([z.literal('individual'), z.literal('legal')]).nullable();

export const SegmentSchema = z.object({
	id: SegmentIdSchema,
	title: z.string().min(1),
	type: SegmentKeyType,
});

export const LegalSegmentSchema = z.object({
	id: SegmentIdSchema,
	title: z.string().min(1),
	type: z.literal('legal').nullable(),
});
export const IndividualSegmentSchema = z.object({
	id: SegmentIdSchema,
	title: z.string().min(1),
	type: z.literal('individual').nullable(),
});

export type Segment = z.infer<typeof SegmentSchema>;
