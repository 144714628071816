import { useRemoveDOMElement } from 'hooks/useRemoveDOMElement';
import React from 'react';
import { Outlet } from 'react-router-dom';

const RootAuthLayout: React.FC = () => {
	useRemoveDOMElement({ selector: '#initial-loader', removeOnMount: true });

	return <Outlet />;
};

export default RootAuthLayout;
