import type { Breadcrumb } from 'components/BreadCrumbs/types';
import { ROUTES_URLS } from 'const';
import { useBoolean } from 'hooks/useBoolean';
import { usePinnedColumns } from 'hooks/usePinnedColumns';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { useVisibilityColumns } from 'hooks/useVisibilityColumns';
import type { ClientPreview } from 'models/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetClientsFiltersDataQuery, useGetClientsQuery } from 'store/reducers/clients/clientsSliceApi';
import { prepareUrl } from 'utils/shared';

import type { IProps } from '../types';
import { useClientsColumns } from './columns';

const fallbackArray = [];

export const useClientsPage = ({ breadcrumbs: externalBreadcrumbs, onRowClick }: IProps) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const { data: { segments = fallbackArray, managers = fallbackArray } = {} } = useGetClientsFiltersDataQuery();
	const {
		data: { data: clients = fallbackArray, page = 1, pagesCount = 0, total = 0 } = {},
		isFetching: isFetchingClients,
		isLoading: isLoadingClients,
		// @ts-ignore
	} = useGetClientsQuery(searchParams.toString());

	const mobileFiltersInterface = useBoolean();
	const filtersDrawerInterface = useBoolean();
	const visibilityInterface = useBoolean();

	const toggleMobileFiltersInterface = useStopPropagationCallback(mobileFiltersInterface.toggle);
	const toggleFiltersDrawerInterface = useStopPropagationCallback(filtersDrawerInterface.toggle);
	const toggleVisibilityInterface = useStopPropagationCallback(visibilityInterface.toggle);

	const breadcrumbs: Breadcrumb[] = externalBreadcrumbs || [{ label: 'Клієнти', href: prepareUrl(ROUTES_URLS.CLIENTS) }];
	const isEmpty = Array.isArray(clients) && clients.length === 0;

	const onClientClick = (client: ClientPreview) => {
		if (onRowClick) {
			return onRowClick(client);
		}

		const url = prepareUrl(ROUTES_URLS.CLIENT_PAGE, { id: client.id });
		navigate(url);
	};

	const onRedirectToCreateClientPage = () => {
		const url = prepareUrl(ROUTES_URLS.CLIENT_CREATE);

		navigate(url);
	};

	const handleRowClick = (row: unknown) => {
		onRowClick?.(row);
	};

	const columns = useClientsColumns(handleRowClick);
	const visibility = useVisibilityColumns({ saveConfigKey: '/clients' });
	const pinning = usePinnedColumns({ saveConfigKey: '/clients' });

	const onPageSizeChange = (newPageSize: string) => {
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set('perPage', newPageSize);

		setSearchParams(newSearchParams);
	};

	return {
		columns,
		visibility,
		pinning,
		breadcrumbs,
		clients,
		page,
		pagesCount,
		isEmpty,
		totalClientsCount: total,
		filtering: {
			managers,
			segments,
		},
		isFetchingClients,
		isLoadingClients,
		visibilityInterface,
		filtersDrawerInterface,
		onClientClick,
		onPageSizeChange,
		onRedirectToCreateClientPage,
		toggleFiltersDrawerInterface,
		toggleMobileFiltersInterface,
		toggleVisibilityInterface,
	};
};
