import { z } from 'zod';

const RestorePasswordSchema = z.object({
	token: z.string(),
	email: z.string().email(),
	password: z.string(),
	password_confirmation: z.string(),
});

export type RestorePasswordDTO = z.infer<typeof RestorePasswordSchema>;
