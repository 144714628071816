import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

interface ToastProps {
	message: string;
}

const handleToastClick = (event: React.MouseEvent<Element>) => {
	event.stopPropagation();
};

export const ErrorToast: React.FC<ToastProps> = ({ message }) => {
	useEffect(() => {
		toast.error(message, { onClick: handleToastClick });
	}, []);

	return null;
};
export const SuccessToast: React.FC<ToastProps> = ({ message }) => {
	useEffect(() => {
		toast.success(message, { onClick: handleToastClick });
	}, []);

	return null;
};
export const InfoToast: React.FC<ToastProps> = ({ message }) => {
	useEffect(() => {
		toast.info(message, { onClick: handleToastClick });
	}, []);

	return null;
};
export const WarningToast: React.FC<ToastProps> = ({ message }) => {
	useEffect(() => {
		toast.warning(message, { onClick: handleToastClick });
	}, []);

	return null;
};
