type CellsStylesSchema = {
	header: string;
	cell: string;
};

type TableStylesConfig = {
	mobile: CellsStylesSchema;
	tablet: CellsStylesSchema;
	desktop: CellsStylesSchema;
};

const config: TableStylesConfig = {
	mobile: {
		header: 'text-sx-medium color-grey-500',
		cell: 'text-sx-medium color-grey-900',
	},
	tablet: {
		header: 'text-sx-medium color-grey-600',
		cell: 'text-sm-regular color-grey-600',
	},
	desktop: {
		header: 'text-sx-medium color-grey-600',
		cell: 'text-sm-regular color-grey-500',
	},
};

export const getTableStyles = () => config;
