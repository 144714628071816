import axios from 'axios';
import { baseApiUrl } from 'const';

const apiClient = axios.create({
	baseURL: baseApiUrl,
	headers: {
		'Content-Type': 'application/json',
	},
});

export default apiClient;
