export interface BaseErrorParams {
	type: string;
	message: string;
	cause: unknown;
}

class BaseError extends Error {
	public readonly type: string;
	public readonly date: Date;

	constructor({ message, type, cause }: BaseErrorParams) {
		super(message, { cause });

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, BaseError);
		}

		this.type = type;
		this.message = message;
		this.date = new Date();
	}

	toJSON(options?: Record<string, unknown>) {
		try {
			return JSON.stringify(
				{
					name: this.name,
					type: this.type,
					message: this.message,
					cause: this.cause,
					date: this.date.toISOString(),
					stack: this.stack,
					...(options ?? {}),
				},
				null,
				4,
			);
		} catch {
			return JSON.stringify(
				{
					name: this.name,
					type: this.type,
					message: this.message,
					date: this.date.toISOString(),
					...(options ?? {}),
				},
				null,
				4,
			);
		}
	}
}

export default BaseError;
