import { EntityName } from 'pages/Order/hooks/useOrderOperationMethods';
import { useTypedOrderControllerFromContext } from 'pages/Order/OrderController';
import React from 'react';
import { isObject } from 'utils/type-guards';

import styles from './styles.module.css';

interface DetailedEntitySpitData {
	entityType: EntityName;
	entityId: string;
}

const DetailedEntitySpitData: React.FC<DetailedEntitySpitData> = ({ entityId, entityType }) => {
	const { watch } = useTypedOrderControllerFromContext();
	const suborders = watch('suborders');
	const pureSuborders = suborders.slice(1) ?? [];

	const info = [];

	pureSuborders.forEach(({ data }, index) => {
		const entityMap = data[entityType];
		const entity = entityMap[entityId];

		if (!entity) return;

		if (isObject(entity) && 'unit' in entity) {
			info.push(`Заявка ${index + 1} - ${entity.amount} ${entity.unit.title}`);
		} else {
			info.push(`Заявка ${index + 1} - ${entity.amount} шт.`);
		}
	});

	return (
		<ul className={styles.list}>
			<li>Розбито на замовлення:</li>
			{info.map((label, idx) => (
				<li key={idx}>{label}</li>
			))}
		</ul>
	);
};

export default DetailedEntitySpitData;
