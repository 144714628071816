import type { Whoami } from 'models/auth';
import { reThrowError } from 'utils/shared';

import { BaseService } from '../domain/base-service';
import { AuthServiceRepository } from './usecase/auth.usecase';

export class AuthService extends BaseService {
	constructor(private db: AuthServiceRepository) {
		super();
	}

	async whoami(): Promise<Whoami> {
		try {
			const me = await this.db.whoami();

			if (!me) {
				return null;
			}

			return me;
		} catch (error) {
			reThrowError(error);
		}
	}
}
