import { useTypedOrderControllerFromContext } from 'pages/Order/OrderController';
import React from 'react';
import { toFixed } from 'utils/shared';

interface ServiceSumDisplayFieldProps {
	suborderIndex: number;
	entityId: string;
}

const ServiceSumDisplayField: React.FC<ServiceSumDisplayFieldProps> = ({ suborderIndex, entityId }) => {
	const { watch } = useTypedOrderControllerFromContext();
	const sumField = `suborders.${suborderIndex}.data.services.${entityId}.sum` as const;

	const sum = watch(sumField);

	return <span>{toFixed(sum, { precise: 2 })}</span>;
};

export default ServiceSumDisplayField;
