import { baseApiUrl } from 'const';
import apiClient from 'store/reducers/auth/apiClient';

const { log, warn } = console;

const loggerAuthCreds = {
	username: 'unitB',
	password: 'unitB.alterego',
};

const loggerService = {
	log: (...payload: AnyArg) => {
		apiClient
			.post(`${baseApiUrl}/logs-front`, payload, {
				auth: loggerAuthCreds,
			})
			.catch(log);
	},
};

export const logger = {
	info: log,
	error: (...errors: AnyArg) => {
		log(...errors);
		loggerService.log(errors);
	},
	warn,
	debugOrder: (errors: Record<string, AnyArg>) => {
		const hasErrors = Object.keys(errors || {}).length > 0;

		const message = hasErrors ? 'Has internal validation errors' : 'No internal validation errors';
		const styles = hasErrors ? 'background: red; color: white;' : 'background: green; color: white;';

		log(`%c ${message}`, styles, errors);
	},
	logPolling: (msg: string) => {
		const styles = 'color: rebeccapurple;';

		log(`%c ${msg}`, styles);
	},
};
