import { useOrderColumns } from 'columns/order';
import SaveErrorAlertDialogue from 'components/OrderPageComponents/(AlertDialogues)/SaveErrorAlertDialogue';
import Spinner from 'components/Spinner';
import type {
	ColumnDefinition,
	OnPinningModelChange,
	OnRowSelectionModelChange,
	OnVisibilityModelChange,
	PinningModel,
	RowSelectionModel,
	VisibilityModel,
} from 'components/Table/lib/table/types/table';
import { useOrderAlertDialogue } from 'contexts/OrderAlertDialoguesProvider';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { Order, Suborder } from 'models/order';
import type { CatalogueService } from 'models/service';
import { useOrderNotifications } from 'pages/Order/hooks/useOrderNotifications';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import { useOrderAbility } from 'pages/Order/OrderAbility/provider';
import type { OrderControllerState } from 'pages/Order/OrderController';
import { transformOrderToCreateOrderDTO, transformOrderToUpdateOrderDTO } from 'pages/Order/OrderController/lib/dto';
import { transformOrderToRootOrderTab, transformSuborderToSuborderTab } from 'pages/Order/OrderController/lib/transform';
import { deleteEntityFromSelectionModel } from 'pages/Order/OrderModule/utils';
import { getIsOnSplitViewPage } from 'pages/Order/utils';
import React from 'react';
import { useCreateOrderMutation, useGetServicesQuery, useUpdateSubOrderMutation } from 'store/reducers/orders/ordersSliceApi';
import { createErrorFactory, transformError } from 'utils/errors/errors';

interface ChildrenProps<TData> {
	productColumns: ColumnDefinition<TData>;
	visibilityModel: VisibilityModel;
	pinningModel: PinningModel;
	onPinningModelChange: OnPinningModelChange;
	onVisibilityModelChange: OnVisibilityModelChange;
	visibilityModelSaveConfigKey: string;
	onOrderSaveSafe: VoidCallback;
	onAddBlankProduct: (selectionModel: RowSelectionModel, onRowSelectionModelChange: OnRowSelectionModelChange) => () => void;
}

interface SuborderPanelProps<TData> {
	children: (props: ChildrenProps<TData>) => JSX.Element;
	suborderIndex: number;
	blankProductId: string | null;
	onTabSwitch: (index: number) => void;
	onSelectionModelChange: OnRowSelectionModelChange;
}

type SplitRequestResult = { index: number; order: Order | Suborder; success: boolean; original: null | AnyArg };
type SplitRequestError = { index: number; error: { message: string } };

const SuborderPanel = <TData,>({ children, suborderIndex, blankProductId, onTabSwitch, onSelectionModelChange }: SuborderPanelProps<TData>) => {
	const { handleSubmit, resetRollbackSnapshot, removeBlankProduct, addBlankProduct, resetState } = useOrderOperationMethods();
	const { columns, pinningModel, setPinningModel, setVisibilityModel, visibilityModel, visibilityModelSaveConfigKey } = useOrderColumns({
		suborderIndex: suborderIndex,
		adjustSaveKey: '/split/products/' + suborderIndex,
	});

	const ability = useOrderAbility();
	const dialogue = useOrderAlertDialogue();
	const notify = useOrderNotifications();

	const [updateSuborderAsync, updateOrderRequest] = useUpdateSubOrderMutation();
	const [createOrderAsync, createOrderRequest] = useCreateOrderMutation();
	const { data: services } = useGetServicesQuery<{ data: Record<string, CatalogueService> }>();

	const entityKey = getIsOnSplitViewPage() ? 'suborder' : 'order';
	const canChangeService = ability.can('change', `${entityKey}.${0}.services.table`);
	const canChangeProduct = ability.can('change', `${entityKey}.${0}.products.table`);
	const canUpdateParentOrderOnServer = canChangeProduct && canChangeService;

	const saveOrders = async (values: OrderControllerState) => {
		const orders = values.suborders.map((order) => {
			const hasProducts = Object.values(order.data.products ?? {}).length > 0;

			if (!hasProducts) return null;

			const isNew = !order.data.id;
			const dto = isNew ? transformOrderToCreateOrderDTO(order.data) : transformOrderToUpdateOrderDTO(order.data);

			return { isNew, dto, original: order };
		});

		const errors = [];
		const result = [];

		for await (const [index, order] of Array.from(orders.entries())) {
			if (!order) continue;

			const { isNew, dto, original } = order;

			const promise = isNew ? createOrderAsync(dto).unwrap() : updateSuborderAsync(dto).unwrap();

			try {
				const response = await promise;
				result.push({ index, order: response, success: true, original: null });
			} catch (error) {
				result.push({ index, order: null, success: false, original });
				errors.push({ index, error: transformError(createErrorFactory(error)) });
			}
		}

		return [result, errors] as [SplitRequestResult[], SplitRequestError[]];
	};

	const onSubmit = async (values: OrderControllerState) => {
		if (canUpdateParentOrderOnServer) {
			const [result, errors] = await saveOrders(values).finally(() => resetState(values.suborders));
			const [rootResult, ...restSuborders] = result;
			const rootOrderTab = transformOrderToRootOrderTab({ order: rootResult.order as Order, services });

			const restSubordersTabs = restSuborders.map((subResult, index) => {
				if (!subResult.success) {
					return subResult.original;
				}
				const transformedSuborder = transformSuborderToSuborderTab({
					suborder: subResult.order as Suborder,
					services,
					index,
				});

				return transformedSuborder;
			});

			const resetPayload = [rootOrderTab, ...restSubordersTabs];

			resetRollbackSnapshot();
			resetState(resetPayload);

			if (resetPayload.length - 1 < suborderIndex) {
				onTabSwitch(resetPayload.length - 1);
			}

			notify.successOrderSaveSplitting();
			onSelectionModelChange({});

			errors.forEach((err) => {
				notify.errorOrderSplitting(err.index, err.error.message);
			});
		} else {
			const suborders = values.suborders.slice(1).map((order) => {
				const hasProducts = Object.values(order.data.products ?? {}).length > 0;

				if (!hasProducts) return null;

				const isNew = !order.data.id;
				const dto = isNew ? transformOrderToCreateOrderDTO(order.data) : transformOrderToUpdateOrderDTO(order.data);

				return { isNew, dto, original: order };
			});

			const errors = [] as SplitRequestError[];
			const result = [] as SplitRequestResult[];

			for await (const [index, suborder] of suborders.entries()) {
				if (!suborder) continue;

				const { isNew, dto, original } = suborder;

				const promise = isNew ? createOrderAsync(dto).unwrap() : updateSuborderAsync(dto).unwrap();

				try {
					const response = await promise;
					result.push({ index, order: response, success: true, original: null });
				} catch (error) {
					result.push({ index, order: null, success: false, original });
					errors.push({ index, error: transformError(createErrorFactory(error)) });
				}
			}

			resetState(values.suborders);

			const restSubordersTabs = result.map((subResult, index) => {
				if (!subResult.success) {
					return subResult.original;
				}
				return transformSuborderToSuborderTab({ suborder: subResult.order as Suborder, services, index });
			});

			const resetPayload = [values.suborders[0], ...restSubordersTabs];

			resetRollbackSnapshot();
			resetState([values.suborders[0], ...restSubordersTabs]);

			if (resetPayload.length - 1 < suborderIndex) {
				onTabSwitch(resetPayload.length - 1);
			}

			notify.successOrderSaveSplitting();
			onSelectionModelChange({});

			errors.forEach((err) => {
				notify.errorOrderSplitting(err.index, err.error.message);
			});
		}
	};

	const handleOrderSave = handleSubmit(async (values: OrderControllerState) => {
		const hasEmptySuborder = values.suborders.slice(1)?.some((suborder) => Object.values(suborder.data.products ?? {}).length === 0);
		const hasEmptyRoot = Object.values(values.suborders[0].data.products).length === 0;

		if (hasEmptyRoot) {
			dialogue.open('emptySave', {
				onSubmit: dialogue.close,
				data: { isSplitting: false },
			});
			return;
		}
		if (hasEmptySuborder) {
			dialogue.open('emptySave', {
				onSubmit: () => onSubmit(values),
				data: { isSplitting: true },
			});
			return;
		}

		dialogue.open('saveSplitting', {
			onSubmit: () => onSubmit(values),
		});
	});

	const onOrderSaveSafe = useStopPropagationCallback(handleOrderSave);

	// add row to table with blank product
	const onAddBlankProduct = (selectionModel: RowSelectionModel, onRowSelectionModelChange: OnRowSelectionModelChange) => () => {
		if (blankProductId) {
			removeBlankProduct({ suborderIndex, targetId: blankProductId });

			const newSelectionModel = deleteEntityFromSelectionModel({
				selectionModel: selectionModel,
				candidates: [blankProductId],
			});

			onRowSelectionModelChange(newSelectionModel);
		} else {
			addBlankProduct({ suborderIndex });
		}
	};

	return (
		<>
			{children({
				productColumns: columns,
				onPinningModelChange: setPinningModel,
				onVisibilityModelChange: setVisibilityModel,
				pinningModel,
				visibilityModel,
				visibilityModelSaveConfigKey,
				onOrderSaveSafe,
				onAddBlankProduct,
			})}

			{createOrderRequest.isLoading && <Spinner />}
			{updateOrderRequest.isLoading && <Spinner />}

			{createOrderRequest.isError && <SaveErrorAlertDialogue message={createOrderRequest.error?.message} onClose={createOrderRequest.reset} />}
			{updateOrderRequest.isError && <SaveErrorAlertDialogue message={updateOrderRequest.error?.message} onClose={updateOrderRequest.reset} />}
		</>
	);
};

export default SuborderPanel;
