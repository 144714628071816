import clsx from 'clsx';
import React from 'react';

import styles from '../styles.module.css';
import type { BaseErrorViewProps } from '../types';
import viewStyles from './styles.module.css';

type InternalServerErrorViewProps = BaseErrorViewProps;

const InternalServerErrorView: React.FC<InternalServerErrorViewProps> = ({ className }) => {
	return (
		<div className={clsx(styles.layout, viewStyles.view, className)}>
			<div className={viewStyles.error}>
				<div className={viewStyles.box} />
				<h3>ERROR 500</h3>
				<p>
					Серверу трошки <span>погано</span>, тому
				</p>
				<p>ми пропонуємо повернутися пізніше</p>
			</div>
		</div>
	);
};

export default InternalServerErrorView;
