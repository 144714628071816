import { isString } from 'utils/type-guards';
import { validateBiometricPassportID, validateRegularPassportID } from 'validation/functions';
import { clientErrorMessages } from 'validation/messages/client';
import { sharedErrorMessages } from 'validation/messages/common';
import { employeeErrorMessages } from 'validation/messages/employee';
import { emailRegex, nameRegex, phoneRegex } from 'validation/regex';
import { z } from 'zod';

// === SHARED
export const optionShapeSchema = (msg?: string) =>
	z.object(
		{
			label: z.string(),
			value: z.string(),
		},
		{ required_error: msg, invalid_type_error: sharedErrorMessages.optionShape.required },
	);

export const username = z.string({
	required_error: sharedErrorMessages.name.required,
});
export const passwordMinLength = 8;
export const passwordField = z.string().min(passwordMinLength, sharedErrorMessages.password.min(passwordMinLength));

export const name = z
	.string({
		required_error: sharedErrorMessages.name.required,
	})
	.regex(nameRegex, sharedErrorMessages.name.notValid);

export const authEmail = z
	.string({
		required_error: sharedErrorMessages.email.required,
	})
	.regex(emailRegex, sharedErrorMessages.email.notValid)
	.min(1, 'Введіть email');

const phoneNumber = z
	.string({
		required_error: sharedErrorMessages.phone.required,
	})
	.regex(phoneRegex, sharedErrorMessages.phone.notValid);

const phone = z.object(
	{
		number: phoneNumber,
		type: z.object({ label: z.string(), value: z.string() }, { required_error: sharedErrorMessages.phone.required }),
		priority: z.string().nullish(),
	},
	{
		required_error: sharedErrorMessages.phone.required,
	},
);

export const phonesArray = phone.array();

export const address = z.string().optional();

export const comment = z.object({ plainText: z.string(), html: z.string() }).nullish();
export const image = z
	.string({
		required_error: sharedErrorMessages.image.required,
	})
	.nullish();

// === BASE CLIENT
export const clientEmail = z
	.string({
		required_error: sharedErrorMessages.email.required,
	})
	.refine((value) => value === '' || emailRegex.test(value), {
		message: sharedErrorMessages.email.notValid,
	});

export const contract = optionShapeSchema(clientErrorMessages.contract.required).array();
export const segment = z.object(
	{
		title: z.string(),
		id: z.string(),
		type: z.string(),
	},
	{ required_error: clientErrorMessages.contract.required },
);

export const clientStatus = optionShapeSchema(clientErrorMessages.status.required);

// === INDIVIDUAL CLIENT
export const passport = z
	.string()
	.optional()
	.refine((value) => !value || validateRegularPassportID(value) || validateBiometricPassportID(value), {
		message: clientErrorMessages.passport.notValid,
	});

export const InternalAttachmentModelSchema = z
	.object({
		id: z.string(),
		file: z.instanceof(File).or(z.instanceof(Blob)),
		isActive: z.boolean().default(false),
	})
	.nullable();

export const attachments = InternalAttachmentModelSchema.nullish().array();

// === EMPLOYEE
const socialMedia = z.object({
	messenger: z.string(),
	nickname: z.string().nullish(),
});

export const socialMediaArray = socialMedia.array();
export const department = optionShapeSchema(employeeErrorMessages.department.required);
export const position = optionShapeSchema(employeeErrorMessages.position.required);
export const description = comment;
export const headManager = optionShapeSchema(employeeErrorMessages.headManager.required).nullable();
export const stock = optionShapeSchema(employeeErrorMessages.stock.required);
export const employeeEmail = z
	.string({
		required_error: sharedErrorMessages.email.required,
	})
	.regex(emailRegex, sharedErrorMessages.email.notValid)
	.min(1, 'Введіть email');

// === min max number input
export const minmaxNumberField = (min: number = 1, max: number) => {
	return z.preprocess(
		(value) => (isString(value) ? parseInt(value) : value),
		z
			.number({ invalid_type_error: sharedErrorMessages.numberType.errorType })
			.min(min, sharedErrorMessages.numberType.min(min))
			.max(max, sharedErrorMessages.numberType.max(max)),
	);
};

export const ipnCode = z
	.string({ required_error: clientErrorMessages.ipnCode.required })
	.optional()
	.refine((value) => !value || /^\d{1,10}$/.test(value), {
		message: clientErrorMessages.ipnCode.notValid,
	});
// === LEGAL CLIENT
export const edrpouCode = z
	.string({
		required_error: clientErrorMessages.edrpouCode.required,
	})
	.length(8, clientErrorMessages.edrpouCode.minmax)
	.regex(/^\d+$/, clientErrorMessages.edrpouCode.type);
// .refine(checkEdrpou, clientErrorMessages.edrpouCode.notValid);

export const organizationName = z.string({
	required_error: clientErrorMessages.organizationName.required,
});
