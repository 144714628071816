import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.css';

const MediumPlusSizeDevicesOnly: React.FC = () => {
	return (
		<p className={clsx(styles.message)}>
			Мобільна версія тимчасово не підтримується... Будь ласка, скористайтеся пристроєм з більшим розміром екрану або настільним компьютером.
		</p>
	);
};

export default MediumPlusSizeDevicesOnly;
