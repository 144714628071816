import React from 'react';
import { kFileSizeFormatter } from 'utils/shared';

import styles from './styles.module.css';
import type { AcceptedFileTypes } from './types';

export function extractFileExtensions(files: AcceptedFileTypes) {
	const fileExtensions = Object.keys(files).map((fileType) => {
		const extension = fileType.split('/')[1];

		if (extension?.includes('+')) {
			return extension.split('+')[0];
		}

		return extension;
	});

	return fileExtensions;
}

export function createBadgeTextFromFileExtensions(arr: string[], joiner?: string) {
	if (arr.length === 0) {
		return null;
	}

	const firstPart = arr.slice(0, -1).join(', ');
	const lastPart = arr.slice(-1);

	if (firstPart.length === 1) {
		return <span>{firstPart.replace(',', '')}</span>;
	}

	const joined = (
		<span>
			{firstPart} <span className={styles.joiner}>{joiner ?? 'або'}</span>
			&nbsp;{lastPart}
		</span>
	);

	return joined;
}

export function createMaxFileSizeBadgeText(max?: number, prefix?: string) {
	if (!max) {
		return null;
	}

	const maxFileSize = `(${prefix ?? 'макс.'} ${kFileSizeFormatter(max, 0)})`;

	return <span className={styles.maxSize}>&nbsp;{maxFileSize}</span>;
}
