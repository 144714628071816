import { ROUTES_URLS } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import localforage from 'localforage';
import type { LoginDTO, StoreToken } from 'models/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authActions } from 'store/reducers/auth';
import { useLazyGetMeQuery, useLoginMutation, useLogoutMutation } from 'store/reducers/auth/authSliceApi';
import { authSelectors } from 'store/reducers/auth/selectors';
import { resetGlobalApiState } from 'store/utils/resetGlobalApiState';
import { capitalize, prepareUrl } from 'utils/shared';

export const useAuth = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const user = useAppSelector(authSelectors.selectWhoamI);

	const [login, { isLoading: isLoginLoading }] = useLoginMutation();
	const [logout, { isLoading: isLogoutLoading }] = useLogoutMutation();
	const [getMe, { isLoading: isGetMeLoading }] = useLazyGetMeQuery();

	const handleLogin = async (dto: LoginDTO) => {
		await login(dto)
			.unwrap()
			.then(async (loginResult) => {
				resetGlobalApiState(dispatch);

				await localforage.removeItem('auth-tokens');
				await localforage.setItem<StoreToken>('auth-tokens', {
					accessToken: loginResult.access_token,
					refreshToken: loginResult.refresh_token,
					tokenType: capitalize(loginResult.token_type),
				});

				const meResponse = await getMe().unwrap();

				dispatch(authActions.setLoggedInUser({ user: meResponse }));
				navigate(ROUTES_URLS.HOME);
			})
			.catch((error) => {
				toast.error(error.message);
			});
	};

	const handleLogout = async () => {
		try {
			await logout().unwrap();
			await localforage.removeItem('auth-tokens');

			resetGlobalApiState(dispatch);
			dispatch(authActions.clearLoggedInUser());
			navigate(prepareUrl(ROUTES_URLS.SIGN_IN));
		} catch {
			await localforage.removeItem('auth-tokens');
			resetGlobalApiState(dispatch);
			dispatch(authActions.clearLoggedInUser());
			navigate(prepareUrl(ROUTES_URLS.SIGN_IN));
		}
	};

	const handleWhoami = async () => {
		const meResponse = await getMe().unwrap();

		dispatch(authActions.setLoggedInUser({ user: meResponse }));
	};

	return {
		user,
		login: handleLogin,
		logout: handleLogout,
		whoami: handleWhoami,
		isLoading: isLoginLoading || isLogoutLoading || isGetMeLoading,
	};
};
