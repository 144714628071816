import clsx from 'clsx';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React from 'react';
import { ReactComponent as TrashIcon } from 'static/images/trash.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const ServiceDeleteButton: React.FC<IProps> = ({ onDelete, className, disabled }) => {
	const handleServiceDelete = useStopPropagationCallback(onDelete);

	return (
		<button type="button" disabled={disabled} onClick={handleServiceDelete} className={clsx(styles.button, className)}>
			<TrashIcon className={styles.icon} />
		</button>
	);
};

export default ServiceDeleteButton;
