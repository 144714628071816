import clsx from 'clsx';
import { Suggestion } from 'components/AutocompleteInput/types';
import { AutocompleteField } from 'components/FormComponents/Inputs/inputs';
import { breakPoints } from 'const';
import type { IndividualClientFormValues, LegalClientFormValues } from 'models/IClient';
import React, { lazy, Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as EyeIcon } from 'static/images/eye.svg';

import styles from './styles.module.css';
import type { IProps } from './types';

const Button = lazy(() => import('components/Button'));

const ClientProfileTopBar: React.FC<IProps> = ({ actionsSlot, className, ...restProps }) => {
	const { control, getValues } = useFormContext<IndividualClientFormValues | LegalClientFormValues>();

	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.MOBILE - 1}px)` });

	return (
		<div className={clsx(styles.topBarWrapper, className)} {...restProps}>
			<form className={styles.form} data-actions-slot={!!actionsSlot}>
				<div className={styles.fieldsWrapper}>
					<AutocompleteField
						disabled
						name="manager"
						valuesList={[getValues('manager') as Suggestion]}
						label="Менеджер"
						placeholder="Наприклад, Кличко"
						fieldClassName={styles.field}
						control={control}
					/>
					<AutocompleteField
						disabled
						name="department"
						valuesList={[getValues('department') as Suggestion]}
						label="Відділ"
						placeholder="Наприклад, Борщагівка"
						fieldClassName={styles.field}
						control={control}
					/>
					<AutocompleteField
						disabled
						name="stock"
						valuesList={[getValues('stock') as Suggestion]}
						placeholder="Склад"
						label="Склад"
						fieldClassName={clsx(styles.field, styles.selectField)}
						control={control}
					/>
				</div>

				<Suspense>
					{!!actionsSlot
						? actionsSlot
						: !isMobile && (
								<Button
									id="clients-top-bar-see-all-button"
									text="Переглянути рахунки"
									onClick={null}
									icon={<EyeIcon />}
									variant="rounded"
									background="var(--pink)"
									hoverBg="#000"
								/>
						  )}
				</Suspense>
			</form>
		</div>
	);
};

export default ClientProfileTopBar;
