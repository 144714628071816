import OrderAlertDialoguesProvider from 'contexts/OrderAlertDialoguesProvider';
import { useAuth } from 'hooks/useAuth';
import PageContentSkeleton from 'layouts/PageLayout/PageContentSkeleton';
import { Whoami } from 'models/auth';
import { Order } from 'models/order';
import { CatalogueService } from 'models/service';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrderByIdQuery, useGetServicesQuery } from 'store/reducers/orders/ordersSliceApi';
import { getErrorViewByException } from 'utils/errors/error-to-view';
import { createErrorFactory } from 'utils/errors/errors';

type AwaitedExistingOrderRouteProps = {
	children: (props: { data: [Order, Record<string, CatalogueService>, Whoami] }) => JSX.Element;
};

interface UseGetServicesQueryReturn {
	data: Record<string, CatalogueService>;
	isFetching: boolean;
}

const AwaitedExistingOrderRoute = ({ children }: AwaitedExistingOrderRouteProps) => {
	const { id } = useParams();
	const { user } = useAuth();
	const { data, isFetching, isError, error } = useGetOrderByIdQuery(id);
	const { data: services, ...serviceReq } = useGetServicesQuery<UseGetServicesQueryReturn>();

	if (isFetching || serviceReq.isFetching) return <PageContentSkeleton />;

	if (isError) {
		const resolvedError = 'error' in error ? error.error : error;
		const exception = createErrorFactory(resolvedError);
		const View = getErrorViewByException(exception);

		return <View />;
	}
	const payload = {
		data: [data, services, user] as [Order, Record<string, CatalogueService>, Whoami],
	};

	return <OrderAlertDialoguesProvider>{children(payload)}</OrderAlertDialoguesProvider>;
};

export default AwaitedExistingOrderRoute;
