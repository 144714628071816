import { isObject } from 'utils/type-guards';
import { z, ZodSchema } from 'zod';

export const withStandardizedName = <T extends ZodSchema>(schema: T) =>
	z.preprocess((input) => {
		if (isObject<z.AnyZodObject>(input)) {
			const data = { ...input } as { name?: unknown } & Record<string, unknown>;

			const { organizationName, name, ...restData } = data;

			return {
				...restData,
				name: organizationName || name,
			};
		}

		return input;
	}, schema);
