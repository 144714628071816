import clsx from 'clsx';
import { FilterSearchParamsKey } from 'components/OrderCreatePageComponents/ProductFilter/lib/const';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as AscIcon } from 'static/images/asc.svg';
import { ReactComponent as DescIcon } from 'static/images/desc.svg';
import { ReactComponent as ShuffleIcon } from 'static/images/shuffle.svg';

import styles from './styles.module.css';
import type { SortColumnMenuProps } from './types';

const SortColumnMenu = ({ onClick, sortColumnKey, appendQueryKey }: SortColumnMenuProps) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const handleSortClick = (sortDirection: 'asc' | 'desc' | '') => (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		let newSearchParams = new URLSearchParams(searchParams);

		if (sortDirection === '') {
			newSearchParams.delete(FilterSearchParamsKey.SortDirection);
			newSearchParams.delete(FilterSearchParamsKey.SortBy);
		} else {
			newSearchParams.set(FilterSearchParamsKey.SortDirection, sortDirection);
			newSearchParams.set(FilterSearchParamsKey.SortBy, sortColumnKey);
		}

		if (appendQueryKey) {
			newSearchParams = appendQueryKey(newSearchParams);
		}

		setSearchParams(newSearchParams.toString());

		onClick?.();
	};

	const isActiveQuery = searchParams.get(FilterSearchParamsKey.SortBy) === sortColumnKey;
	const isAscOrder = isActiveQuery && searchParams.get(FilterSearchParamsKey.SortDirection) === 'asc';
	const isDescOrder = isActiveQuery && searchParams.get(FilterSearchParamsKey.SortDirection) === 'desc';
	const isDefaultOrder = !searchParams.get(FilterSearchParamsKey.SortDirection) || !isActiveQuery;

	return (
		<span className={clsx(styles.sortMenu)}>
			<button type="button" onClick={handleSortClick('')} className={clsx(styles.button, { [styles.isActive]: isDefaultOrder })}>
				<ShuffleIcon />
				<span>Звичайне сортування</span>
			</button>

			<button type="button" onClick={handleSortClick('asc')} className={clsx(styles.button, { [styles.isActive]: isAscOrder })}>
				<AscIcon />
				<span>Сортувати за збільшенням</span>
			</button>

			<button type="button" onClick={handleSortClick('desc')} className={clsx(styles.button, { [styles.isActive]: isDescOrder })}>
				<DescIcon />
				<span>Сортувати за зменшенням</span>
			</button>
		</span>
	);
};

export default SortColumnMenu;
