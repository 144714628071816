import { APP_MODE } from 'const';
import { AppMode } from 'models/IApp';
import { useCallback } from 'react';
import { setAppMode } from 'store/reducers/app';
import { selectAppModeDetails } from 'store/reducers/app/selectors';

import { useAppDispatch, useAppSelector } from './redux';

export const useAppMode = () => {
	const { mode, updatedAt, isNetworkConnection } = useAppSelector(selectAppModeDetails);
	const dispatch = useAppDispatch();

	const isOnline = mode === APP_MODE.online;
	const isOffline = mode === APP_MODE.offline;

	const switchModeTo = useCallback((newMode: AppMode) => {
		dispatch(setAppMode({ mode: newMode }));
	}, []);

	return {
		mode,
		isOnline,
		updatedAt,
		isOffline,
		isNetworkConnection,
		switchModeTo,
	};
};
