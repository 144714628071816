import clsx from 'clsx';
import Select from 'components/Select';
import { PER_PAGE_SIZE } from 'const';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

interface PerPageSizeSelectorProps {
	className?: string;
	label?: string;
}

const [defaultPageSize] = PER_PAGE_SIZE;

const PerPageSizeSelector: React.FC<PerPageSizeSelectorProps> = ({ className, label }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const perPage = searchParams.get('perPage') ?? defaultPageSize;

	const isPageSizeInRange = perPage === null || PER_PAGE_SIZE.includes(perPage);

	if (!isPageSizeInRange) {
		searchParams.set('perPage', defaultPageSize);
		const searchParamsString = (searchParams.size > 0 ? '?' : '') + searchParams.toString();

		const url = window.location.pathname + searchParamsString;

		return <Navigate replace to={url.toString()} />;
	}

	const onPageSizeChange = (newPageSize: string) => {
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set('perPage', newPageSize);

		setSearchParams(newSearchParams);
	};

	return <Select className={clsx(className)} valuesList={PER_PAGE_SIZE} value={perPage} setValue={onPageSizeChange} label={label} />;
};

export default PerPageSizeSelector;
