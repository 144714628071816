import MainSearch from 'components/MainSearch';
import { MainSearchAutocomplete } from 'components/MainSearch/components/MainSearchAutocomplete';
import { VoiceSearchButton } from 'components/MainSearch/components/VoiceSearchButton';
import Spinner from 'components/Spinner';
import { breakPoints } from 'const';
import { useAppDispatch } from 'hooks/redux';
import { useAuth } from 'hooks/useAuth';
import { useOrderRouter } from 'hooks/useOrderRouter';
import type { Client } from 'models/client';
import type { Option } from 'models/common/options';
import { createOrderLikeEntity } from 'pages/Order/OrderController/lib/entity-creators';
import React, { lazy, Suspense, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { Outlet } from 'react-router-dom';
import AppErrorBoundary from 'routes/components/PageErrorBoundary';
import { orderQueryKeys } from 'services/queryKeys';
import { useGetClientsOptionsQuery, useLazyGetClientByIdQuery } from 'store/reducers/clients/clientsSliceApi';
import { ordersSliceApi } from 'store/reducers/orders/ordersSliceApi';
import { useGetOrganizationsOptionListQuery } from 'store/reducers/users/usersSliceApi';

import PageContentSkeleton from './PageContentSkeleton';
import styles from './styles.module.css';

const SideNavBar = lazy(() => import('components/SideNavBar'));
const NavBarMain = lazy(() => import('components/NavBarMain'));

const useLoadAsyncDataQuery = (queryArg: string[]) => {
	const [query = ''] = queryArg;

	const { data, isFetching, isLoading } = useGetClientsOptionsQuery(query);

	return {
		data,
		isFetching,
		isLoading,
	};
};

const PageLayout: React.FC = () => {
	const router = useOrderRouter();
	const { user } = useAuth();
	const dispatch = useAppDispatch();
	const [getClientById, { isFetching: isClientFetching }] = useLazyGetClientByIdQuery<Client & { isFetching: boolean }>();
	const { data: organizations } = useGetOrganizationsOptionListQuery();

	useEffect(() => {
		document.documentElement.classList.add('page-layout');

		return () => {
			document.documentElement.classList.remove('page-layout');
		};
	}, []);

	const onClientSelect = async ({ value: id }: Option) => {
		const client = await getClientById(id).unwrap();

		await dispatch(
			ordersSliceApi.util.upsertQueryData(
				'getOrderById',
				orderQueryKeys.createOrderEntity(),
				createOrderLikeEntity({ client, manager: user, organization: organizations[0] }),
			),
		);

		router.toAddProducts({ isNew: true });
	};

	const searchSlot = (
		<MainSearch>
			<VoiceSearchButton />
			<MainSearchAutocomplete useLoadAsyncDataQuery={useLoadAsyncDataQuery} placeholder="Шукати клієнтів" onSelect={onClientSelect} />
		</MainSearch>
	);

	return (
		<div className={styles.mainContainer}>
			<Suspense>
				<MediaQuery minWidth={breakPoints.MOBILE}>
					<SideNavBar className={styles.sidebar} />
				</MediaQuery>
			</Suspense>
			<Suspense>
				<MediaQuery maxWidth={breakPoints.MOBILE - 1}>
					<NavBarMain searchBarSlot={searchSlot} />
				</MediaQuery>
			</Suspense>

			<div className={styles.pageContent}>
				<Suspense fallback={<PageContentSkeleton />}>
					<AppErrorBoundary>
						<Outlet />
					</AppErrorBoundary>
				</Suspense>
			</div>

			{isClientFetching && <Spinner />}
		</div>
	);
};

export default PageLayout;
