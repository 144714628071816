import { EmployeeIdSchema } from 'models/common/uuid';
import { EmployeeDepartmentSchema, EmployeePositionSchema, EmployeeStockSchema } from 'models/employee';
import { z } from 'zod';

export const PermissionsSchema = z.object({
	create: z.string().array().nullable(),
	view: z.string().array().nullable(),
	edit: z.string().array().nullable(),
	offline: z.string().array().nullable(),
});

export const WhoamiSchema = z.object({
	id: EmployeeIdSchema,
	name: z.string().nullish(),
	email: z.string().email().nullish(),
	'1c_uuid': z.string().nullish(),
	department: EmployeeDepartmentSchema.nullish(),
	position: EmployeePositionSchema.nullish(),
	stock: EmployeeStockSchema.nullish(),
	role: z.string(),
	permissions: PermissionsSchema,
	avatar: z.string().nullish(),
});

export const HasUserSchema = WhoamiSchema.pick({ id: true, name: true, email: true, '1c_uuid': true });

export type Whoami = z.infer<typeof WhoamiSchema>;
