import AvatarWithOnlineStatus from 'components/AvatarWithOnlineStatus';
import { Breadcrumb } from 'components/BreadCrumbs/types';
import ClientComponent from 'components/Table/TableComponents/ClientComponent';
import { breakPoints, ROUTES_URLS } from 'const';
import { Client } from 'models/client';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useGetClientByIdQuery, useGetSegmentsQuery } from 'store/reducers/clients/clientsSliceApi';
import { getColorByClientState, prepareUrl } from 'utils/shared';

import styles from '../styles.module.css';
import { getOrderPreFilterParamsString, hydrateClientFormState, isLegalClient } from './utils';

const fallbackArray = [];
const fallbackObj = {} as Client;

export const useClientCard = ({ id }: { id: string }) => {
	const navigate = useNavigate();
	const isMobile = useMediaQuery({ query: `(max-width: ${breakPoints.LAPTOP_SM}px)` });

	const { data: segments = fallbackArray, ...segmentsRequest } = useGetSegmentsQuery(undefined);
	const { data: client = fallbackObj, ...clientRequest } = useGetClientByIdQuery<{ data: Client; isLoading: boolean }>(id);

	const type = client?.segment?.type ?? null;
	const form = useForm({
		defaultValues: {
			...hydrateClientFormState(client),
			segment: segments?.find((option) => option.id === client?.segment?.id) ?? { title: '', id: '' },
		},
	});

	const onRedirectToDocuments = () => {
		navigate(`/documents/${client.id}`);
	};

	const onClientBillsPreview = () => {
		const url = prepareUrl(ROUTES_URLS.ORDERS);
		const searchParams = window.location.search;
		const urlWithSearchParams = url + searchParams + (searchParams ? '&' : '?') + `client${encodeURIComponent('[]')}=${client.id}`;

		navigate(urlWithSearchParams);
	};

	const onPaymentInfoClick = (queryValue: string) => () => {
		const params = getOrderPreFilterParamsString({ clientId: client.id, queryValue });
		navigate(prepareUrl(ROUTES_URLS.ORDERS) + params);
	};
	const clientName = !client ? '' : isLegalClient(client) ? client.organizationName ?? '' : client.name ?? '';

	const breadcrumbs: Breadcrumb[] = useMemo(
		() => [
			{ label: 'Клієнти', href: prepareUrl(ROUTES_URLS.CLIENTS) },
			{ label: clientName },
			{
				label: (
					<div className={styles.clientBreadCrumb}>
						<AvatarWithOnlineStatus isOnline fallback name={clientName} size="32px" />
						<ClientComponent cursor="pointer" client={clientName} />
					</div>
				),
				asBackLinkCrumbOnly: true,
			},
		],
		[clientName],
	);

	const spacerSize = isMobile ? '20px' : '31px';
	const clientStateBadgeColor = getColorByClientState(client?.status ?? 'white');
	const isLoading = segmentsRequest.isLoading || clientRequest.isLoading;

	return {
		form,
		spacerSize,
		segments,
		client,
		isLoading,
		breadcrumbs,
		clientType: type,
		onPaymentInfoClick,
		onClientBillsPreview,
		clientStateBadgeColor,
		onRedirectToDocuments,
	};
};
