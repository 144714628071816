import type { BaseErrorParams } from './base.error';
import BaseError from './base.error';

type BaseErrorEssentialParams = Omit<BaseErrorParams, 'type'>;

export interface BaseApiErrorParams extends BaseErrorEssentialParams {
	code: number;
}

class BaseApiError extends BaseError {
	static readonly type = 'API_ERROR';
	public readonly code: number;

	constructor({ code, ...params }: BaseApiErrorParams) {
		super({ ...params, type: BaseApiError.type });

		this.code = code;
	}

	toJSON() {
		return super.toJSON({ code: this.code });
	}
}

export default BaseApiError;
