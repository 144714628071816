import React from 'react';
import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from 'store';

const persistor = persistStore(store);

const ProviderStore: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				{children}

				<Outlet />
			</PersistGate>
		</Provider>
	);
};

export default ProviderStore;
