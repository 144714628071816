import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import FormField from 'components/FormComponents/FormField';
import PasswordInput from 'components/PasswordInput';
import Spinner from 'components/Spinner';
import { useBoolean } from 'hooks/useBoolean';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetCanProceedDangerousMutation } from 'store/reducers/auth/authSliceApi';
import { z } from 'zod';

import styles from './styles.module.css';

interface DangerousActionsContext {
	requestCanProceedDangerous: () => Promise<boolean>;
}

const Context = createContext<DangerousActionsContext>(null);

export const useDangerousActions = () => useContext(Context);

const ConfirmDangerousActionWithPasswordSchema = z.object({
	password: z.string().min(1, 'Обовʼязкове поле'),
});

type ConfirmDangerousActionWithPassword = z.infer<typeof ConfirmDangerousActionWithPasswordSchema>;

const DangerousActionsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const modal = useBoolean();
	const [promise, setPromise] = useState<AnyArg>(createExternalPromise());
	const [checkPermissionAsync, checkPermissionRequest] = useGetCanProceedDangerousMutation();

	const form = useForm<ConfirmDangerousActionWithPassword>({
		resolver: zodResolver(ConfirmDangerousActionWithPasswordSchema),
		defaultValues: {
			password: '',
		},
	});

	const modalClose = useStopPropagationCallback<HTMLButtonElement>(modal.close);
	const modalOpen = useStopPropagationCallback<HTMLButtonElement>(modal.open);
	const onModalClick = useStopPropagationCallback<HTMLElement>();

	const handleCheckPermissionAsync = useStopPropagationCallback(async () => {
		const validation = ConfirmDangerousActionWithPasswordSchema.safeParse(form.getValues());

		if (!validation.success) {
			return validation.error.issues.forEach((issue) => {
				// @ts-ignore
				form.setError(issue.path.join('.'), {
					type: 'manual',
					message: issue.message,
				});
			});
		}

		const dto = validation.data;
		const { isGranted } = await checkPermissionAsync(dto).unwrap();

		promise.resolve(isGranted);
	});

	const ctx: DangerousActionsContext = useMemo(() => {
		return {
			requestCanProceedDangerous: async () => {
				modalOpen();

				return await promise.promise.then((res) => {
					modal.close();
					form.reset({ password: '' });
					setPromise(createExternalPromise());
					return res;
				});
			},
		};
	}, [promise]);

	return (
		<Context.Provider value={ctx}>
			{children}

			<FormProvider {...form}>
				{modal.isOn && (
					<>
						{createPortal(
							<AlertDialogue onClick={onModalClick} onOutsideClick={modalClose} className={styles.alert}>
								<AlertDialogueHeader onClose={modalClose}>Підтвердження дії</AlertDialogueHeader>
								<AlertDialogueContent>
									<div className={styles.group}>
										<div className={styles.message}>
											<p>
												Ви збираєтесь виконати <b className={styles.underline}>небезпечну</b> дію.
											</p>
											<p>
												Для того, щоб переконатись, що у вас дійсно є на це права, будь ласка, введіть свій{' '}
												<b className={styles.underline}>пароль</b>.
											</p>
										</div>

										<FormField
											name="password"
											fieldClassName={clsx(styles.field)}
											className={styles.employeeInput}
											component={PasswordInput}
											label="Пароль"
											placeholder="Введіть пароль"
										/>
									</div>
								</AlertDialogueContent>
								<AlertDialogueFooter>
									<AlertDialogueControlButton variant="cancel" className={styles.cancel} onClick={modalClose}>
										Скасувати
									</AlertDialogueControlButton>
									<AlertDialogueControlButton variant="submit" onClick={handleCheckPermissionAsync}>
										Підтвердити
									</AlertDialogueControlButton>
								</AlertDialogueFooter>
							</AlertDialogue>,
							document.querySelector('#portal'),
						)}
					</>
				)}
			</FormProvider>

			{checkPermissionRequest.isLoading && <Spinner />}
		</Context.Provider>
	);
};

export default DangerousActionsProvider;

function createExternalPromise<T>() {
	let resolve: (value: T | PromiseLike<T>) => void;
	let reject: (reason?: AnyArg) => void;

	const promise = new Promise<T>((res, rej) => {
		resolve = res;
		reject = rej;
	});

	return { promise, resolve, reject };
}
