import { toast } from 'react-toastify';

const toasts = {
	info: toast.info,
	warn: toast.warn,
	error: toast.error,
	success: toast.success,
};

const EntityToHumanLang = {
	products: 'Товар',
	services: 'Послугу',
} as const;

type EntityKey = keyof typeof EntityToHumanLang;

const messages = {
	entityDeleteSuccess: (entity: EntityKey) => `${EntityToHumanLang[entity]} видалено!`,
	entityTransferSuccess: (entity: EntityKey) => `${EntityToHumanLang[entity]} перенесено!`,
	entityAddedToOrderSuccess: (entity: EntityKey) => `${EntityToHumanLang[entity]} додано до заявки!`,
	orderSaveSuccess: () => 'Заявку збережено!',
	orderCreateSuccess: () => 'Заявку створено!',
	orderSaveSplittingSuccess: () => 'Заявку розбито та збережено!',
	orderSaveError: () => 'Заявку не вдалося зберегти!',
	orderReservationSuccess: () => 'Заявку поставлено на резерв!',
	orderReservationCancelSuccess: () => 'Заявку знято на резерва!',
	orderReservationError: () => 'Не вдалося поставити заявку резерв!',
	orderSplittingError: (index: number, reason?: string) => `Не вдалося зберегти підзаявку ${index}! ${reason}`,
	orderCancelReservationSuccess: () => 'Заявку знято з резерву!',
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	suborderTabCloseSuccess: (_: number) => 'Заявку видалено!',
	entityInvalidAmountError: () => 'Неправильно обрана кількість!',
	orderDateUpdateSuccess: () => 'Дату в заявці оновлено',
	orderDateUpdateError: () => 'Дату в заявці не оновлено',
	noClientSelected: () => 'Спочатку оберіть клієнта',
};

export const useOrderNotifications = () => {
	return {
		...toasts,
		successEntityDelete: (entity: EntityKey) => toasts.success(messages.entityDeleteSuccess(entity)),
		successTransfer: (entity: EntityKey) => toasts.success(messages.entityTransferSuccess(entity)),
		successOrderSave: () => toasts.success(messages.orderSaveSuccess()),
		successOrderCreate: () => toasts.success(messages.orderCreateSuccess()),
		successOrderSaveSplitting: () => toasts.success(messages.orderSaveSplittingSuccess()),
		successOrderReservation: () => toasts.success(messages.orderReservationSuccess()),
		successOrderCancelReservation: () => toasts.success(messages.orderReservationCancelSuccess()),
		successCancelOrderReservation: () => toasts.success(messages.orderCancelReservationSuccess()),
		errorOrderSave: () => toasts.error(messages.orderSaveError()),
		errorOrderReservation: () => toasts.error(messages.orderReservationError()),
		successEntityAddedToOrder: (entity: EntityKey) => toasts.success(messages.entityAddedToOrderSuccess(entity)),
		successSuborderTabClose: (index: number) => toasts.success(messages.suborderTabCloseSuccess(index)),
		successOrderDateUpdate: () => toasts.success(messages.orderDateUpdateSuccess),
		errorOrderDateUpdate: () => toasts.success(messages.orderDateUpdateError),
		errorEntityInvalidAmount: () => toast.error(messages.entityInvalidAmountError()),
		noClientSelected: () => toast.error(messages.noClientSelected()),
		errorOrderSplitting: (index: number, reason?: string) => toast.error(messages.orderSplittingError(index, reason)),
	};
};
