import clsx from 'clsx';
import React, { Children } from 'react';

import styles from './styles.module.css';
import Tab from './Tab';
import type { TablistChild, TablistProps } from './types';

export const TablistInMemory: React.FC<Omit<TablistProps, 'rootPath'> & { activeIndex: number }> = ({
	children,
	activeIndex,
	onTabChange,
	tab,
	slot,
	className,
}) => {
	const handleTabChange = (idx: number) => {
		onTabChange?.(idx);
	};

	const childrenArr = Children.toArray(children) as TablistChild[];
	return (
		<>
			<div className={clsx(styles.slotContainer, className)}>
				<ul className={clsx(styles.tablistControls)}>
					{childrenArr.map((item, idx) => {
						const isActive = idx === activeIndex;

						if (tab) {
							const TabComponent = tab;

							return (
								<TabComponent
									key={idx}
									href={item.props.href}
									title={item.props.title}
									isActive={isActive}
									tabIndex={idx}
									onTabChange={handleTabChange}
								/>
							);
						}

						return (
							<Tab
								active={isActive}
								key={idx}
								label={item.props.title}
								count={item.props.itemsCount}
								onClick={() => handleTabChange(idx)}
							/>
						);
					})}
				</ul>
				{slot}
			</div>

			{children[activeIndex]}
		</>
	);
};
