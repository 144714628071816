import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import React from 'react';

import EmptySuborder from '../EmptySuborder';

interface EmptyServicesGateProps extends PropsWithChildren {
	isEmpty: boolean;
	className?: string;
}

const EmptyServicesGate: React.FC<EmptyServicesGateProps> = ({ isEmpty, children, className }) => {
	return isEmpty ? <EmptySuborder className={clsx(className)} /> : children;
};

export default EmptyServicesGate;
