import clsx from 'clsx';
import Button from 'components/Button';
import Input from 'components/Input';
import Modal from 'components/Modal';
import ModalBody from 'components/Modal/ModalBody';
import ModalControls from 'components/Modal/ModalControls';
import ModalHeader from 'components/Modal/ModalHeader';
import SpinnerV2 from 'components/Spinner-v2';
import { useBoolean } from 'hooks/useBoolean';
import { useDebouncedCallback } from 'hooks/useDebouncedCallback';
import { useStopPropagationCallback } from 'hooks/useStopPropagationCallback';
import { useOrderOperationMethods } from 'pages/Order/hooks/useOrderOperationMethods';
import React, { useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { ReactComponent as SearchIcon } from 'static/images/Magnify.svg';
import { useGetCatalogueProductsQuery } from 'store/reducers/orders/ordersSliceApi';

import SearchProductAvailabilityTable from '../SearchProductAvailabilityTable';
import styles from './styles.module.css';

interface SearchProductCellProps {
	id: string;
	suborderIndex: number;
}

const SearchProductCell: React.FC<SearchProductCellProps> = ({ id, suborderIndex }) => {
	const searchMode = useBoolean();
	const [search, setSearch] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const { hydrateBlankProductWithData, control } = useOrderOperationMethods();
	const stock = useWatch({ control, name: `suborders.${suborderIndex}.data.stock` });

	const { data: { data: products = [] } = {}, ...getProductsRequest } = useGetCatalogueProductsQuery(`search=${searchQuery}`, {
		skip: !searchQuery,
	});
	const searchModeToggle = useStopPropagationCallback(searchMode.toggle);

	const onSearchQueryChange = useCallback((value: string) => {
		setSearchQuery(value);
	}, []);

	const onSearchQueryChangeDebounced = useDebouncedCallback(onSearchQueryChange, 400);
	const onChange = (value: string) => {
		setSearch(value);
		onSearchQueryChangeDebounced(value);
	};
	const onClose = () => {
		setSearch('');
		setSearchQuery('');
		searchMode.close();
	};

	const onProductClick = (product: AnyArg) => () => {
		const amount = product?.unit?.isInt ? '1' : '1.00';
		hydrateBlankProductWithData({ suborderIndex, candidate: { ...product, amount, isBlank: false }, targetId: id });
	};

	return (
		<>
			<span className={clsx(styles.searchCell)} onClick={searchModeToggle}>
				<span className={styles.cellInput}>
					<input readOnly type="text" placeholder="Шукати товар..." />
					<SearchIcon />
				</span>
			</span>

			{searchMode.isOn && (
				<Modal open onClose={onClose} width="80vw" maxWidth="80vw" minHeight="600px" className={styles.modal}>
					<ModalHeader onXCloseClick={onClose} title="Пошук товарів" />
					<ModalBody className={styles.modalBody}>
						<Input type="text" value={search} setValue={onChange} placeholder="Почніть писати..." wrapperClassName={styles.input} />

						<div className={styles.content}>
							{!getProductsRequest.isLoading && !getProductsRequest.isUninitialized && !products?.length && !!search && (
								<div className={styles.empty}>
									<p>Нічого не знайдено... Спробуйте інший запит.</p>
								</div>
							)}
							{getProductsRequest.isUninitialized && (
								<div className={styles.empty}>
									<p>Напишіть щось, щоб почати пошук...</p>
								</div>
							)}

							{(getProductsRequest.isLoading || getProductsRequest.isFetching) && (
								<div className={clsx(styles.searchResult, styles.loading)}>
									<SpinnerV2 />
								</div>
							)}

							{!getProductsRequest.isLoading && products && products.length > 0 && (
								<ul className={clsx(styles.searchResult, styles.list)}>
									{products.map((product) => (
										<li key={product.id} className={clsx(styles.item)}>
											<button type="button" onClick={onProductClick(product)} className={clsx(styles.itemButton)}>
												<span className={styles.brand}>{product.brand?.title}</span>
												<span className={styles.product}>{product.title}</span>
											</button>

											<SearchProductAvailabilityTable product={product} stockId={stock?.value || ''} />
										</li>
									))}
								</ul>
							)}
						</div>
					</ModalBody>
					<ModalControls className={styles.modalControls}>
						<Button variant="inverse" text="Закрити" onClick={onClose} />
					</ModalControls>
				</Modal>
			)}
		</>
	);
};

export default SearchProductCell;
