import { ClientEditProhibitedFieldNames } from 'models/IClient';
import FacebookIcon from 'static/images/facebook-02.svg';
import InstagramIcon from 'static/images/instagram.svg';
import TelegramIcon from 'static/images/telegram.svg';
import ViberIcon from 'static/images/viber.svg';
import { prepareUrl } from 'utils/shared';

export const baseApiUrl = process.env.REACT_APP_API_URL || 'https://unitb-appback.alterego.biz.ua/api/v1';

export const APP_MODE = {
	online: 'online',
	offline: 'offline',
} as const;

export const APP_MODE_STORAGE_KEY = 'unit-b::app-mode';
export const UA_PHONE_CODE = '38';

export const ROUTES_URLS = {
	HOME: '/',
	SIGN_IN: 'sign-in',
	FORGOT_PASSWORD: 'forgot-password',
	RESET_PASSWORD: 'password/reset/:token',
	// SIGN_UP: '/sign-up',
	ORDER_SELECT_CLIENT: 'order/select-client',
	ORDER_SELECT_PRODUCT: 'order/:id/select-product',
	ORDER_SERVICES: 'services',
	CLIENT_PAGE: 'client/:id',
	ORDER_NEW: 'order/new',
	NEW_ORDER: 'order/create/new-order',

	EMPLOYEE_EDIT: 'employees/:id/edit',
	AUTH_VIA_INVITATION_LINK: 'sign-in/link/:token',
	COMMERCIAL_PROPOSAL: 'commercial-proposal',
	CREATE_ORDER_SELECT_PRODUCT: 'order/select-product',

	CLIENTS: 'clients',
	CLIENT_PREVIEW: 'client/:id',
	CLIENT_CREATE: 'client/create',
	CLIENT_ORDERS: 'clients/:id/orders',

	DOCUMENTS: 'documents/:id',
	PRICES: 'prices',
	PRICES_CREATE: 'prices/new',
	PRICES_SAVE: 'prices/new/save',
	PAINT_TONING: 'paint-toning',

	ORDERS: 'orders',

	ORDER_ROOT_SEGMENT: 'order',

	ORDER_PREVIEW_ROOT: 'preview',
	ORDER_PREVIEW_SEGMENT: ':id',
	ORDER_PREVIEW_PRODUCTS_SEGMENT: 'products',
	ORDER_PREVIEW_SERVICES_SEGMENT: 'services',
	ORDER_PREVIEW_ADD_PRODUCTS_SEGMENT: 'add-products',
	ORDER_PREVIEW_SPLIT_ORDER_SEGMENT: 'split',
	ORDER_PREVIEW_CLIENT_CARD_SEGMENT: 'client-card',

	ORDER_NEW_ROOT: 'new',
	ORDER_NEW_PRODUCTS_SEGMENT: 'products',
	ORDER_NEW_SERVICES_SEGMENT: 'services',
	ORDER_NEW_SET_CLIENT_SEGMENT: 'set-client',
	ORDER_NEW_ADD_PRODUCTS_SEGMENT: 'add-products',
	ORDER_NEW_SPLIT_ORDER_SEGMENT: 'split',
	ORDER_NEW_CLIENT_CARD_SEGMENT: 'client-card',

	ORDER_SPLIT: 'order/:id/split',
	EMPLOYEES: 'employees',
	EMPLOYEE: 'employees/:id',
	EMPLOYEE_CREATE: 'employees/new',
};

export const orderUrlUtil = {
	getPreviewRoot() {
		return ROUTES_URLS.ORDER_PREVIEW_ROOT;
	},
	getNewOrderRoot() {
		return ROUTES_URLS.ORDER_NEW_ROOT;
	},
	getFullPreviewUrl() {
		return prepareUrl(this.getPreviewRoot() + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT + '/' + ROUTES_URLS.ORDER_PREVIEW_SEGMENT);
	},
	getFullPreviewProductsUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_PRODUCTS_SEGMENT;
	},
	getFullPreviewServicesUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_SERVICES_SEGMENT;
	},
	getFullPreviewSetProductsUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_ADD_PRODUCTS_SEGMENT;
	},
	getFullPreviewSplitOrderUrl() {
		return this.getFullPreviewUrl() + '/' + ROUTES_URLS.ORDER_PREVIEW_SPLIT_ORDER_SEGMENT;
	},
	getFullPreviewClientCardUrl() {
		return (
			this.getPreviewRoot() +
			'/' +
			ROUTES_URLS.ORDER_ROOT_SEGMENT +
			'/' +
			ROUTES_URLS.ORDER_PREVIEW_SEGMENT +
			'/' +
			ROUTES_URLS.ORDER_PREVIEW_CLIENT_CARD_SEGMENT +
			'/:clientId'
		);
	},
	getFullNewOrderUrl() {
		return this.getNewOrderRoot() + '/' + ROUTES_URLS.ORDER_ROOT_SEGMENT;
	},
	getFullNewOrderProductsUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_PRODUCTS_SEGMENT;
	},
	getFullNewOrderServicesUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_SERVICES_SEGMENT;
	},
	getFullNewOrderSetProductsUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_ADD_PRODUCTS_SEGMENT;
	},
	getFullNewOrderSetClientUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_SET_CLIENT_SEGMENT;
	},
	getFullNewOrderSplitOrderUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_SPLIT_ORDER_SEGMENT;
	},
	getFullNewOrderClientCardUrl() {
		return this.getFullNewOrderUrl() + '/' + ROUTES_URLS.ORDER_NEW_CLIENT_CARD_SEGMENT + '/:clientId';
	},
};

export const breakPoints = {
	MOBILE: 768,
	TABLET: 1024,
	LAPTOP_SM: 1200,
	LAPTOP_MD: 1366,
	DESKTOP: 1920,
};

export const MAX_CHARS = 425;
export const DEFAULT_ITEMS_PER_PAGE = 12;

export const ACCEPTED_FILE_TYPES = {
	'application/pdf': [],
	'image/png': [],
	'image/jpg': [],
	'image/jpeg': [],
	'image/gif': [],
};

export const MAX_FILE_SIZE_IN_BYTES = 10 * 1024 * 1024; // equals 10 MB

const ORDER_AGREEMENT_STATUS = '1';
export const ORDER_NEWLY_CREATED_STATUS = ORDER_AGREEMENT_STATUS;

export const CLIENTS_FILTER_QUERY_KEYS = {
	client: 'client[]',
	segment: 'segment[]',
	manager: 'manager[]',
} as const;

export const ORDERS_FILTER_QUERY_KEYS = {
	sum: 'sum',
	payment: 'paymentStatus[]',
	shipment: 'status[]',
	client: 'client[]',
	// manager: 'manager',
	number: 'number',
	stock: 'stock[]',
	date: 'date',
	contract: 'contract[]',
	responsible: 'responsible[]',
} as const;

export const PHONE_TYPES_OPTION_LIST = [
	{ label: 'Робочий', value: 'phone_number_work' },
	{ label: 'Особистий', value: 'phone_number_personal_main' },
	{ label: 'Додатковий', value: 'phone_number_personal_additional' },
];
export const EMPLOYEE_PHONE_TYPE_DICT = {
	phone_number_work: { label: 'Робочий', value: 'phone_number_work' },
	phone_number_personal_main: { label: 'Особистий', value: 'phone_number_personal_main' },
	phone_number_personal_additional: { label: 'Додатковий', value: 'phone_number_personal_additional' },
};
export const PHONE_PRIORITY_LIST = ['phone_number', 'phone_number_additional_1', 'phone_number_additional_2'];

export const MESSENGERS_LIST = [
	{ icon: FacebookIcon, messenger: 'facebook', nickNameExample: '@username' },
	{ icon: InstagramIcon, messenger: 'instagram', nickNameExample: '@username' },
	{ icon: TelegramIcon, messenger: 'telegram', nickNameExample: '@username' },
	{ icon: ViberIcon, messenger: 'viber', nickNameExample: '@username' },
];

export const EMPLOYEE_PROFILE_DYNAMIC_FIELDS_LIMIT = {
	phone: 3,
	messengers: MESSENGERS_LIST.length,
};

export const PRICES_FILTERS = [
	{
		label: 'Клієнт',
		value: 'client',
	},
	{
		label: 'Назва',
		value: 'name',
	},
	{
		label: 'Дата',
		value: 'date',
	},
];

export const PRICES_TYPE_PRICE = [
	{
		label: 'Оптова',
		value: 'wholesale',
	},
	{
		label: 'Роздрібна',
		value: 'retail',
	},
	{
		label: 'Мінімальна',
		value: 'min',
	},
	{
		label: 'Мінімальна +2%',
		value: 'min+2%',
	},
];

export const CLIENT_PHONE_ASSOCIATED_OPTION_LIST = [{ label: 'Мобільний', value: 'mobile' } as const, { label: 'Міський', value: 'city' } as const];
export const CLIENT_PHONE_TYPE_TO_HUMAN = {
	city: 'Міський',
	mobile: 'Мобільний',
};

export const SEGMENTS_WITH_SPECIALIZATION = ['Будівельна організація спеціалізована', 'Виконроб'];

export const EDRPOU_CODE_LENGTH = 8;

// ==== VIRTUAL LIST CONFIG
export const FILTER_ITEM_SIZE = 20;
export const GUTTER = 20;
export const MAX_LIST_HEIGHT = 485;

export const CLIENT_DISABLED_FIELDS_NAMES_LIST: ClientEditProhibitedFieldNames[] = ['contract', 'status'];
export const SUBORDERS_LIMIT = 5;

// === CLIENT SEARCH PARAMS CONFIG
export const CLIENT_PAGE_FILTER_KEYS = {
	waiting: 'waiting',
	overdue: 'overdue',
	prepaid: 'prepaid',
};

export const SERVER_ENTITY_ID = {
	ServiceDYIPriceType: 'ef741b50-b356-11e3-af42-8b1ea7c04b02',
	ProductDYIPriceType: 'ef741b50-b356-11e3-af42-8b1ea7c04b02',
	ProductMinimalPriceType: '82dd3937-2316-11ea-80d5-8107dcf40211',
	ProductEnterPriceType: '95d8c530-6303-11e3-8680-c52dd1806f02',
	ProductWholeSalePriceType: '2d8b7e50-6709-11e3-b37c-d8698ef02892',
	ServicesNotAllowedManuallyEditPrice: ['2cf3b1b3-5e6c-11e3-8adb-e3aa534060cd', '1ec61b3f-9146-11e6-80d5-3497f6001881'],
};
// === CLIENTS FAST FILTERS GROUP
export const CLIENTS_FAST_FILTERS = [
	{
		Будівельники: [
			'2dd4239c-339a-41ac-8824-780c09e2fd24', // Виконроб
			'c0381d4a-9ad5-4429-9597-32a2afd9d0a4', // Майстер
			'8fbaa767-dda0-4da4-8047-c32cb8f4900c', // Дизайнер,
			'bfeb1c82-43c7-484b-824b-6d77164eaa88', // Забудовник
		],
	},
	{
		'Будівельні організації': [
			'f2ff446f-86ec-47c9-a470-ca9a5dec30aa', // Будівельна організація
			'1dede545-c191-4425-acf8-1d9b3cb778b9', // Будівельна організація спеціалізована
		],
	},
	{
		Підприємства: [
			'e02a765c-3971-4205-ad46-58e9ece1d0ca', // Підприємство
		],
	},
	{
		'Кінцевий споживач': [
			'6b284624-59b6-45dc-81e6-33816e1ddf6b', // Кінцевий споживач
		],
	},
];

export const CLIENT_STATUS = {
	Green: 'green',
	White: 'white',
	Yellow: 'yellow',
	Red: 'red',
} as const;

export const CLIENT_INFO_BY_STATUS = {
	[CLIENT_STATUS.White]: {
		name: 'Білий контрагент',
		color: 'var(--base-light)',
	},
	[CLIENT_STATUS.Green]: {
		name: 'Зелений контрагент',
		color: 'var(--success-500)',
	},
	[CLIENT_STATUS.Yellow]: {
		name: 'Жовтий контрагент',
		color: 'var(--warning-200)',
	},
	[CLIENT_STATUS.Red]: {
		name: 'Червоний контрагент',
		color: 'var(--error-500)',
	},
};
export const CLIENT_STATE_NAMES_LIST = Object.entries(CLIENT_INFO_BY_STATUS).map(([statusKey, value]) => ({ label: value.name, value: statusKey }));

export const PRE_FILTER_CLIENT_ROLES = ['back_manager', 'head_manager'];

export const USER_ROLE_TO_TITLE = {
	manager: 'Менеджер',
	none: 'Не зазначено',
	head_manager: 'Керівник',
	admin: 'Адміністратор',
	back_manager: 'Бек менеджер',
};

export const PER_PAGE_SIZE = ['12', '24', '48'];

export const PAGINATION_QUERY_KEYS = {
	offset: 'offset',
	page: 'page',
} as const;

export const PRODUCTS_FILTER_QUERY_KEYS = {
	inStockOnly: 'inStock',
	brand: 'brand',
	product: 'product',
	preselect: 'preselect',
	query: 'query',
} as const;

export const EMPLOYEES_QUERY_PARAMS = {
	status: 'status',
} as const;

export const API_ENDPOINT = {
	allClients: () => '/clients',
	createClientData: () => '/clients/create',
	clientOptions: () => '/clients/all',
	clientById: (id: string) => `/clients/${id}`,
	clientContracts: (id: string) => `/contracts/client/${id}`,
	clientOrders: (id: string) => `/clients/${id}/orders`,
	clientFilters: () => '/clients/filters',

	segments: () => '/segments',
	stocks: () => '/stocks',
	services: () => '/services',
	organizations: () => '/organizations',
	contracts: () => '/contracts',

	allEmployees: () => '/users',
	employeeById: (id: string) => `/users/${id}`,
	employeeOptions: () => '/users/all',
	employeesFilters: () => '/users/filters',
	createEmployeeData: () => '/users/create',
	changeEmployeePassword: () => '/users/set-password',
	suspendEmployee: (id: string | number) => `/users/${id}/set-active`,

	allOrders: () => '/orders',
	orderById: (id: string) => `/orders/${id}`,
	orderRefreshLockedStatus: (id: string) => `/orders/${id}/lock-refresh`,
	orderUnlockManually: (id: string) => `/orders/${id}/lock-remove`,
	orderFilters: () => '/orders/filters',
	orderReserve: (id: string) => `/orders/${id}/on-reserve`,
	orderUnReserve: (id: string) => `/orders/${id}/from-reserve`,
	ordersCopy: () => '/orders/copy',

	allProducts: () => '/goods',
	productsSearch: () => '/goods/search',
	productsBrands: () => '/goods/brands',
	productsCategories: () => '/goods/categories',
	productsLastPrice: () => '/goods/last-prices',

	filterTemplates: () => '/filter-templates',
	filterTemplatesById: (id: string) => `/filter-templates/${id}`,

	files: () => '/files',

	signIn: () => '/auth/sign_in',
	signOut: () => '/auth/logout',
	whoami: () => '/auth/me',
	resetPassword: () => '/auth/password/email',
	restorePassword: () => '/auth/password/reset',
	refreshToken: () => '/auth/refresh',

	businessOffer: () => '/business-offers',
};
