export const employeeErrorMessages = {
	department: {
		required: 'Відділ є обовʼязковим',
	},
	position: {
		required: 'Посада є обовʼязковою',
	},
	headManager: {
		required: 'Керівник є обовʼязковим',
	},
	stock: {
		required: 'Склад є обовʼязковим',
	},
} as const;
