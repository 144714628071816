import clsx from 'clsx';
import React from 'react';

import styles from '../styles.module.css';
import type { BaseErrorViewProps } from '../types';

type NotFoundErrorViewProps = BaseErrorViewProps;

const NotFoundErrorView: React.FC<NotFoundErrorViewProps> = ({ className }) => {
	return <div className={clsx(styles.layout, className)}>NotFoundErrorView</div>;
};

export default NotFoundErrorView;
