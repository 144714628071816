import { ROUTES_URLS } from 'const';
import { CanUser } from 'contexts/AbilityContext';
import React from 'react';
import { Navigate } from 'react-router-dom';

type ProtectedRouteProps = React.ComponentProps<typeof CanUser> & {
	children: React.ReactNode;
	fallback?: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, fallback, ...props }) => {
	return (
		<CanUser passThrough {...props}>
			{(can) => {
				if (!can) {
					if (!fallback) {
						return <Navigate replace to={ROUTES_URLS.HOME} />;
					}

					return fallback;
				}

				return <>{children}</>;
			}}
		</CanUser>
	);
};

export default ProtectedRoute;
