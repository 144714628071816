import clsx from 'clsx';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalHeader from 'components/Modal/ModalHeader';
import type { IProps as ModalProps } from 'components/Modal/types';
import ProductItem from 'components/OrderCreatePageComponents/BulkProductSelection/Item';
import TipCard from 'components/TipCard';
import { ProductUnit } from 'models/product';
import { ServiceInternalModelState } from 'pages/Order/OrderController';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as PlusIcon } from 'static/images/plus.svg';
import { formatInputValue } from 'utils/inputs';
import { uuid } from 'utils/shared';

import styles from './styles.module.css';

type TransferServicesModalProps = Pick<ModalProps, 'onClose'> & {
	targetSuborderIndex: number;
	onSubmit: (services: ServiceInternalModelState[]) => void;
	data?: ServiceInternalModelState[];
};

const serviceUnit: ProductUnit = {
	coefficient: 0,
	id: uuid(),
	isInt: true,
	title: 'шт',
	volume: 0,
	weight: 0,
};

const TransferServicesModal: React.FC<TransferServicesModalProps> = ({ onClose, onSubmit, targetSuborderIndex, data }) => {
	const [writableServices, setWritableServices] = useState<ServiceInternalModelState[]>([...(data ?? [])]);
	const handleSubmit = () => {
		const areAllTransferItemsHaveValidAmount = writableServices.every((candidate) => Number(candidate.amount) > 0);

		if (!areAllTransferItemsHaveValidAmount) {
			return toast.error('Неправильна кількість');
		}

		onSubmit(writableServices);
		onClose?.();
	};

	const formatValue = (value: number) => {
		const valueFormatter = formatInputValue({ isInt: true });

		return String(valueFormatter(value));
	};

	const handleItemQuantityChange = (id: string, newQuantity: number) => {
		setWritableServices(
			writableServices.map((service) => {
				if (service.id !== id) return service;
				const amount = formatValue(newQuantity);

				return {
					...service,
					amount,
				};
			}),
		);
	};

	return (
		<Modal noAwayClick open stackable onClose={onClose} className={styles.modal} maxWidth="800px" maxHeight="518px">
			<ModalHeader title={<span className={styles.headerTitle}>Перенесення послуг</span>} onXCloseClick={onClose} className={styles.header} />

			<ul className={clsx(styles.list)}>
				{writableServices.map((service) => (
					<ProductItem
						key={service.id}
						onQuantityChange={handleItemQuantityChange.bind(null, String(service.id))}
						initialValue={service.amount}
						price={Number(service.price)}
						brand={{ id: '', title: service?.title }}
						title={service?.service?.title}
						category={service?.category?.title}
						unit={serviceUnit}
						className={clsx({ [styles.metalCuttingServiceTemplate]: !!service?.isMetalCuttingService })}
					/>
				))}
			</ul>

			<TipCard message="Послуга не містить додаткової інформації" />

			<div className={styles.controls}>
				<Button variant="default" onClick={onClose} className={styles.cancel} text="Скасувати" />
				<Button
					variant="rounded"
					background="var(--success-500)"
					onClick={handleSubmit}
					text={`Перенести в заявку ${targetSuborderIndex}`}
					icon={<PlusIcon />}
				/>
			</div>
		</Modal>
	);
};

export default TransferServicesModal;
