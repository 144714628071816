import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiUrl } from 'const';
import type { RequestMethod } from 'models/api';
import { baseQuery } from 'store/config/base-query';

export interface ProcessRequestParams<TData = AnyArg> {
	body?: TData;
	url: string;
	method: RequestMethod;
	headers?: AnyArg;
}

export const postponedRequestsSliceApi = createApi({
	reducerPath: 'postponedRequests',
	baseQuery: baseQuery(),
	tagTypes: ['Postponed'],
	endpoints: (builder) => ({
		processRequest: builder.mutation<AnyArg, ProcessRequestParams<AnyArg>>({
			query: ({ method, body, headers, url }) => {
				const apiEndpoint = url.replace(baseApiUrl, '');

				return {
					url: apiEndpoint,
					method,
					headers: {
						...(headers ?? {}),
					},
					data: body,
				};
			},
		}),
	}),
});

export const { useProcessRequestMutation } = postponedRequestsSliceApi;
